import { ColumnDef } from '@tanstack/react-table'

import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { MachineryProps } from 'app/core/types/system'
import { numberWithThousandDotSeparatorFormat } from 'app/core/utils/number'

const machineryListColumns = (): ColumnDef<MachineryProps, unknown>[] => [
  {
    header: 'Nome',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Código',
    accessorKey: 'code',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Nº da Frota',
    accessorKey: 'fleet_number',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Km atual',
    accessorKey: 'current_mileage',
    cell: ({ getValue }): JSX.Element => {
      const value = getValue()
      return (
        <span>
          {value ? numberWithThousandDotSeparatorFormat(value as number) : ''}
        </span>
      )
    },
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (): JSX.Element => <></>,
  },
]

export { machineryListColumns }
