import { useEffect, useState } from 'react'

import classNames from 'classnames'
import { debounce } from 'lodash-es'

import { Icon, IconNames } from '../icon'
import styles from '../input-text/styles.module.scss'

export type InputDebouncedProps = {
  autoFocus?: boolean
  initialValue: string | number
  handleReset?: () => void
  onChange: (value: string | number) => void
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'onChange'>

const InputDebounced: React.FC<InputDebouncedProps> = ({
  autoFocus,
  initialValue,
  handleReset,
  onChange,
  ...props
}) => {
  const [value, setValue] = useState<string | number>(initialValue)

  useEffect(() => {
    setValue(initialValue)
  }, [initialValue])

  const debouncedOnChange = debounce((val: string | number) => {
    onChange(val)
  }, 500)

  useEffect(() => {
    debouncedOnChange(value)

    return (): void => {
      debouncedOnChange.cancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className={classNames(styles.inputContainer, styles.searchInput)}>
      <Icon
        testId="iconHandleReset"
        color="var(--color-neutral-500)"
        name={IconNames['close-circle']}
        onClick={(): void => {
          setValue('')
          handleReset && handleReset()
        }}
        title="Limpar busca"
      />
      <input
        data-testid="inputDebounced"
        autoFocus={autoFocus}
        className={styles.input}
        value={value}
        onChange={(e): void => setValue(e.target.value)}
        placeholder="Buscar"
        {...props}
      />
    </div>
  )
}

export { InputDebounced }
