import {
  LocationCreateRequestData,
  LocationProps,
  LocationUpdateRequestData,
} from 'app/core/types/system'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/location/'

const getAllLocations = async (farmId?: string): Promise<LocationProps[]> => {
  const params = farmId ? { farm_id: farmId } : null
  const response = await http.get(baseUrl, { params })
  return response.data
}

const postCreateLocation = async (
  request: LocationCreateRequestData
): Promise<LocationProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const patchUpdateLocation = async (
  locationId: number,
  request: LocationUpdateRequestData
): Promise<LocationProps> => {
  const response = await http.patch(`${baseUrl}${locationId}/`, request)
  return response.data
}

const deleteLocation = async (locationId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}${locationId}/`)
  return response.data
}

export {
  deleteLocation,
  getAllLocations,
  patchUpdateLocation,
  postCreateLocation,
}
