import {
  ColumnFiltersState,
  getCoreRowModel,
  getFacetedRowModel,
  getFacetedUniqueValues,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'

import { formatISO } from 'date-fns'
import { useServiceOrder } from 'providers/service-orders'
import { useServiceOrderFilters } from 'providers/service-orders/filters'

import { ServiceType } from 'app/core/types/system'

import {
  serviceOrderListColumns,
  serviceOrderListColumnsEditable,
} from './tables'
import { ServiceOrdersReviewTemplate } from './template'

const ServiceOrdersReview: React.FC = () => {
  const {
    listServiceOrders,
    updateServiceOrder,
    serviceOrderPageInfo,
    serviceOrders,
    setFilters,
    isLoading,
  } = useServiceOrder()
  const {
    containsName,
    formattedDate,
    mechanizationCheckbox,
    generalServiceCheckbox,
  } = useServiceOrderFilters()

  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [page, setPage] = useState<number>(1)

  const data = useMemo(() => serviceOrders || [], [serviceOrders])

  const getServiceType = useCallback((): ServiceType | null | undefined => {
    const isMechanization = mechanizationCheckbox === 'true'
    const isGeneralService = generalServiceCheckbox === 'true'

    if (isMechanization && isGeneralService) return
    if (isMechanization) return ServiceType.mechanization
    if (isGeneralService) return ServiceType.generalService

    return null
  }, [generalServiceCheckbox, mechanizationCheckbox])

  const table = useReactTable({
    data,
    columns: serviceOrderListColumns(),
    state: {
      columnFilters,
    },
    onColumnFiltersChange: setColumnFilters,
    defaultColumn: serviceOrderListColumnsEditable(updateServiceOrder),
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  const filters = useMemo(() => {
    return {
      init_date: formatISO(formattedDate.currentDate, {
        representation: 'date',
      }),
      final_date: formatISO(formattedDate.finalDate, {
        representation: 'date',
      }),
      service_type: getServiceType(),
      contains_name: containsName,
      page,
    }
  }, [containsName, formattedDate, getServiceType, page])

  useEffect(() => {
    if (filters)
      setFilters(prevState => ({
        ...prevState,
        ...filters,
      }))
  }, [filters, setFilters])

  useEffect(() => {
    listServiceOrders()
  }, [listServiceOrders])

  return (
    <ServiceOrdersReviewTemplate
      activeFilters={filters}
      columnFilters={columnFilters}
      table={table}
      serviceOrders={serviceOrders}
      serviceOrderPageInfo={serviceOrderPageInfo}
      setPage={setPage}
      isLoading={isLoading}
    />
  )
}

export { ServiceOrdersReview }
