import {
  HandlingTypes,
  HandlingTypesFilter,
  HandlingEntity,
} from 'app/core/types/handling'

export const HANDLING_TYPES = Object.entries(HandlingTypes).map(
  ([key, value]) => ({ label: value, value: key })
)

export const HANDLING_TYPES_FILTER = Object.entries(HandlingTypesFilter).map(
  ([key, value]) => ({ label: value, value: key })
)

export const MOCK_HANDLINGS: HandlingEntity[] = [
  {
    id: 1,
    type: HandlingTypes.sanitary_handling,
    date: '00/00/0000',
    animals: 1154,
  },
  {
    id: 2,
    type: HandlingTypes.fattening_handling,
    date: '00/00/0000',
    animals: 980,
  },
  {
    id: 3,
    type: HandlingTypes.weaning_handling,
    date: '00/00/0000',
    animals: 120,
  },
  {
    id: 4,
    type: HandlingTypes.fourth_month_handling,
    date: '00/00/0000',
    animals: 1236,
  },
  {
    id: 5,
    type: HandlingTypes.sanitary_handling,
    date: '00/00/0000',
    animals: 278,
  },
  {
    id: 6,
    type: HandlingTypes.first_handling,
    date: '00/00/0000',
    animals: 801,
  },
  {
    id: 7,
    type: HandlingTypes.sanitary_handling,
    date: '00/00/0000',
    animals: 388,
  },
  {
    id: 8,
    type: HandlingTypes.sanitary_handling,
    date: '00/00/0000',
    animals: 1203,
  },
]
