import React, { useCallback, useState } from 'react'
import Skeleton from 'react-loading-skeleton'
import { useHistory, useLocation } from 'react-router-dom'

import { LogoVertical, NoProfile } from 'assets/svg'
import classNames from 'classnames'
import { useAuthUser } from 'providers/auth-user'
import { useServiceOrder } from 'providers/service-orders'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  Dropdown,
  ExportServiceOrders,
  IDropdownItem,
  OSDrawer,
} from 'components/organisms'
import UserProfile, {
  IUserProfileItem,
} from 'components/organisms/user-profile'

import { useFarm } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { StorageKeys } from 'app/core/types/storage'

import styles from './styles.module.scss'

type MenuItem = {
  name: string
  navigateTo?: NavigateList
}

type MenuItemProps = MenuItem[]

type OSLayoutProps = {
  largerContent?: boolean
}

const FARM_GROUP = {
  id: '0',
  name: 'Guanabara',
}

const OSLayout: React.FC<OSLayoutProps> = ({ children, largerContent }) => {
  const history = useHistory()
  const location = useLocation()

  const { user, selectedFarm, setSelectedFarm } = useAuthUser()
  const { activeFarmsDroplist: farmsDroplist } = useFarm()
  const { exportServiceOrders, setFilters, isLoadingExport } = useServiceOrder()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isExportOpen, setIsExportOpen] = useState(false)

  const isServiceOrdersPage = location.pathname.includes(
    NavigateList.serviceOrders
  )
  const isServiceOrdersReviewPage = location.pathname.includes(
    NavigateList.serviceOrdersReview
  )
  const showExportButton = isServiceOrdersPage || isServiceOrdersReviewPage

  const handleLogout = (): void => {
    localStorage.removeItem(StorageKeys.auth_token)
    history.push('/')
  }

  const handleExport = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { initDate, endDate } = data as {
        initDate: string
        endDate: string
      }

      setIsExportOpen(false)

      await exportServiceOrders(initDate, endDate)
    },
    [exportServiceOrders]
  )

  const menuItems: MenuItemProps = [
    {
      name: 'Boletins',
      navigateTo: NavigateList.serviceOrders,
    },
    {
      name: 'Revisão Geral',
      navigateTo: NavigateList.serviceOrdersReview,
    },
  ]

  const userProfileOptions: IUserProfileItem[] = [
    {
      name: 'Parâmetros do Sistema',
      action: (): void => history.push(NavigateList.parameters),
      icon: IconNames.settings,
      onlyAdmin: true,
    },
    {
      name: 'Ir para Gestão de Rebanhos',
      action: (): void => history.push(NavigateList.dashboard),
      icon: IconNames.cow,
    },
    {
      name: 'Sair',
      action: handleLogout,
      icon: IconNames.exit,
    },
  ]

  const farmOptions = farmsDroplist?.map(({ label, value }) => {
    return {
      title: 'Fazenda',
      subTitle: `${label}`,
      action: (): void => {
        setSelectedFarm({
          id: value,
          name: label,
        })
        setFilters(prevState => ({
          ...prevState,
          farm_id: Number(value),
        }))
      },
    }
  }) as IDropdownItem[]

  const fixedGroup: IDropdownItem = {
    title: 'Grupo',
    subTitle: 'Guanabara',
    hasDivider: true,
    action: (): void => {
      setSelectedFarm(FARM_GROUP)
      setFilters(prevState => ({
        ...prevState,
        farm_id: null,
      }))
    },
  }

  const toggleDrawer = (): void => setIsDrawerOpen(!isDrawerOpen)

  const toggleExport = (): void => setIsExportOpen(!isExportOpen)

  return (
    <>
      <main className={styles.page}>
        <header className={styles.headerContainer}>
          <div className={styles.header}>
            <img className={styles.logo} src={LogoVertical} draggable={false} />
            {selectedFarm ? (
              <Dropdown
                iconPosition={ButtonIconPosition.right}
                label={selectedFarm.id === FARM_GROUP.id ? 'Grupo' : 'Fazenda'}
                items={[...farmOptions, fixedGroup]}
                className={styles.dropdown}
                selected={
                  selectedFarm.name ? selectedFarm.name : FARM_GROUP.name
                }
              />
            ) : (
              <Skeleton width={180} height={42} />
            )}
            <div className={styles.tabContainer}>
              <nav className={styles.menu}>
                <ul>
                  {menuItems.map(({ name, navigateTo }) => (
                    <li
                      className={classNames(
                        styles.menuItem,
                        navigateTo &&
                          location.pathname.includes(navigateTo) &&
                          styles.menuItemActive
                      )}
                      key={name}
                      onClick={(): void =>
                        navigateTo && history.push(navigateTo)
                      }
                    >
                      {name}
                    </li>
                  ))}
                </ul>
              </nav>
            </div>
          </div>

          {showExportButton && (
            <Button
              testId="exportButton"
              type={ButtonType.primary}
              label="Exportar Planilha"
              size={ButtonSize.large}
              className={styles.exportButton}
              icon={<Icon name={IconNames['download']} />}
              onClick={(): void => setIsExportOpen(true)}
              loading={isLoadingExport}
            />
          )}

          <Button
            testId="createOSButton"
            type={ButtonType.primary}
            label="Lançar Boletim"
            size={ButtonSize.large}
            className={styles.launchButton}
            onClick={(): void => setIsDrawerOpen(true)}
            icon={<Icon name={IconNames['add-circle']} />}
          />
          <UserProfile
            image={NoProfile}
            name={user?.name}
            farmName={user?.farm_name ? `Fazenda ${user.farm_name}` : ''}
            email={user?.email}
            items={userProfileOptions}
          />
        </header>

        <div
          className={classNames(
            styles.pageContent,
            largerContent && styles.pageContentLarger
          )}
        >
          {children}
        </div>
      </main>

      {isDrawerOpen && (
        <OSDrawer isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      )}

      <ExportServiceOrders
        handleExportSubmit={handleExport}
        isOpen={isExportOpen}
        handleModal={toggleExport}
      />
    </>
  )
}

export { OSLayout }
