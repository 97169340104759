import { useCallback, useEffect, useState } from 'react'

import { getAllUsers, patchUpdateUser } from 'app/core/services'
import { UserHook } from 'app/core/types/hooks'
import { IUpdateUserRequestData, UserEntity } from 'app/core/types/user'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

const useUser = (): UserHook => {
  const [users, setUsers] = useState<UserEntity[]>()
  const [isLoading, setIsLoading] = useState(false)

  const readAllUsers = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)
      const data = await getAllUsers()
      setUsers(data.filter(user => user.is_active))
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [])

  const updateUser = useCallback(
    async (id: number, data: IUpdateUserRequestData): Promise<void> => {
      try {
        setIsLoading(true)
        await patchUpdateUser(id, data)
        addToast({ message: Messages.USER_UPDATE_SUCCESS, type: 'success' })
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  useEffect(() => {
    readAllUsers()
  }, [readAllUsers])

  return {
    users,
    updateUser,
    isLoading,
  }
}

export { useUser }
