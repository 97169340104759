import React, { useMemo } from 'react'

import classNames from 'classnames'

import {
  Divider,
  Icon,
  IconNames,
  Tooltip,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { ServiceOrderInfoProps } from 'app/core/types/service-order'
import { parseServiceOrderDuration } from 'app/core/utils'

import styles from './styles.module.scss'

interface IServiceOrderInfoProps {
  onClick: () => void
  serviceOrderInfo: ServiceOrderInfoProps
}

const ServiceOrderInfo: React.FC<IServiceOrderInfoProps> = ({
  onClick,
  serviceOrderInfo,
}) => {
  const observationMaxLength = 17

  const serviceOrderIconLines = useMemo(() => Array(4).fill({}), [])

  return (
    <div
      className={classNames(
        styles.serviceOrder,
        serviceOrderInfo.new && styles.newOS
      )}
      onClick={onClick}
    >
      <div className={styles.serviceOrderInfo}>
        <div className={styles.serviceOrderInfoLayout}>
          <div className={styles.serviceOrderInfoIcon}>
            <Icon name={IconNames.clipboard} size={48} />
            <div className={styles.serviceOrderInfoBadge}>
              {serviceOrderIconLines.map((_, index) => (
                <span key={index} />
              ))}
            </div>
          </div>
          <div>
            <Typography
              text="Nº do Boletim"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={serviceOrderInfo.bulletin_number}
              variant={TypographyVariant.h6}
              className={styles.serviceOrderInfoText}
            />
          </div>
        </div>
        <div>
          <Divider vertical className={styles.serviceOrderDivider} />
        </div>
      </div>
      <div className={styles.serviceOrderContent}>
        <div className={styles.serviceOrderColumn}>
          <div>
            <Typography
              text="Serviço"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={serviceOrderInfo.service_name}
              variant={TypographyVariant.p}
              className={styles.serviceInfoPrimary}
            />
          </div>
          <div>
            <Typography
              text="Maquinário Operado"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            {serviceOrderInfo.machinery_name ? (
              <Typography
                text={serviceOrderInfo.machinery_name}
                variant={TypographyVariant.p}
                className={styles.serviceInfo}
              />
            ) : (
              <Typography
                text="-"
                variant={TypographyVariant.p}
                className={styles.serviceInfoEmpty}
              />
            )}
          </div>
        </div>
        <div className={styles.serviceOrderColumn}>
          <div>
            <Typography
              text="Duração"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={parseServiceOrderDuration(serviceOrderInfo.duration)}
              variant={TypographyVariant.p}
              className={styles.serviceInfo}
            />
          </div>
          <div>
            <Typography
              text="Odômetro"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            {serviceOrderInfo.odometer ? (
              <Typography
                text={serviceOrderInfo.odometer}
                variant={TypographyVariant.p}
                className={styles.serviceInfo}
              />
            ) : (
              <Typography
                text="-"
                variant={TypographyVariant.p}
                className={styles.serviceInfoEmpty}
              />
            )}
          </div>
        </div>
        <div className={styles.serviceOrderColumn}>
          <div>
            <Typography
              text="Local"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={serviceOrderInfo.local_name}
              variant={TypographyVariant.p}
              className={styles.serviceInfo}
            />
          </div>
          <div>
            <Typography
              text="Executor"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={serviceOrderInfo.executor_name}
              variant={TypographyVariant.p}
              className={styles.serviceInfo}
            />
          </div>
        </div>
        <div className={styles.serviceOrderColumn}>
          <div>
            <Typography
              text="Classificação"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            <Typography
              text={serviceOrderInfo.classification_name}
              variant={TypographyVariant.p}
              className={styles.serviceInfo}
            />
          </div>
          <div className={styles.observation}>
            <Typography
              text="Comentário"
              variant={TypographyVariant.caption3}
              className={styles.serviceTitle}
            />
            {serviceOrderInfo.observation &&
            serviceOrderInfo.observation.length > observationMaxLength ? (
              <Tooltip
                tooltip={serviceOrderInfo.observation}
                label={serviceOrderInfo.observation}
              />
            ) : (
              <Typography
                text={serviceOrderInfo.observation || '-'}
                variant={TypographyVariant.p}
                className={
                  serviceOrderInfo.observation
                    ? styles.serviceInfo
                    : styles.serviceInfoEmpty
                }
              />
            )}
          </div>
        </div>
        <div>
          <Typography
            text="Centro de Custos"
            variant={TypographyVariant.caption3}
            className={styles.serviceTitle}
          />
          <Typography
            text={serviceOrderInfo.cost_center_name}
            variant={TypographyVariant.p}
            className={styles.serviceInfo}
          />
        </div>
      </div>
    </div>
  )
}

export { ServiceOrderInfo }
