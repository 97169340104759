import React, { useCallback, useState } from 'react'

import { useBreedingStation } from 'app/core/hooks'
import { BreedingStationFilterProps } from 'app/core/types/breeding'

import BreedingStationHistoryTemplate from './template'

const BreedingStationHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<BreedingStationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteBreedingStationId, setDeleteBreedingStationId] =
    useState<number>()

  const { stations, deleteStation, isLoading } = useBreedingStation({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterGroups = async (data: unknown): Promise<void> => {
    const dataCopy = data as BreedingStationFilterProps

    setPage(1)
    setFilters(dataCopy)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteBreedingStationId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteBreedingStationId) {
      await deleteStation(deleteBreedingStationId)

      handleDeleteModalClose()
    }
  }, [deleteBreedingStationId, deleteStation])

  return (
    <BreedingStationHistoryTemplate
      stations={stations}
      setPage={setPage}
      page={page}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterGroups}
      isLoading={isLoading}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      deleteBreedingStationId={deleteBreedingStationId}
      setDeleteBreedingStationId={setDeleteBreedingStationId}
    />
  )
}

export { BreedingStationHistory }
