import { ColumnDef } from '@tanstack/react-table'

import { cellEmptyState } from 'components/organisms/custom-table/helpers'

import { CostCenterProps } from 'app/core/types/system'

const costCentersListColumns = (): ColumnDef<CostCenterProps, unknown>[] => [
  {
    header: 'Nome',
    accessorKey: 'name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Código',
    accessorKey: 'code',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: 'Fazenda',
    accessorKey: 'farm_name',
    cell: ({ getValue }): JSX.Element => cellEmptyState(getValue),
  },
  {
    header: '',
    accessorKey: 'id',
    cell: (): JSX.Element => <></>,
  },
]

export { costCentersListColumns }
