import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { DiagnosticReadResponseData } from 'app/core/types/breeding'

import styles from './styles.module.scss'

type DiagnosticsHistoryProps = {
  groups?: DiagnosticReadResponseData
  isDrawerOpen: boolean
  handleDeleteModalClose: () => void
  handleDeleteModalConfirm: () => Promise<void>
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  currentPage: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
  deleteGroupDiagnosticId: number | undefined
  setDeleteGroupDiagnosticId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
}

const DiagnosticsHistoryTemplate: React.FC<DiagnosticsHistoryProps> = ({
  groups,
  isDrawerOpen,
  handleDeleteModalClose,
  handleDeleteModalConfirm,
  handleToggleDrawer,
  onSubmit,
  currentPage,
  setCurrentPage,
  isLoading,
  deleteGroupDiagnosticId,
  setDeleteGroupDiagnosticId,
}) => {
  const history = useHistory<NavigateList>()

  const { sectorsDroplist } = useSector()

  const { Form, formState, reset } = useForm({ onSubmit })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
  }

  return (
    <>
      <BoxedLayout
        title="Diagnósticos Reprodutivos de Gestação"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={(): void =>
          history.push(NavigateList.diagnosticsCreate)
        }
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalPages={groups?.pages}
      >
        {withLoading(
          <>
            <DataCounter
              counter={groups?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.groupsContainer}>
              {groups?.items.map(group => (
                <ExpandCard
                  className={styles.customExpandCard}
                  expandable={false}
                  navigation={(): void =>
                    history.push(
                      `${NavigateList.diagnosticsDetails}${group.id}`
                    )
                  }
                  buttonLabel="Editar"
                  buttonIcon={IconNames.pencil}
                  animals={group.diagnostics_count}
                  singleDate={group.date}
                  disableColEqualSize
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      setDeleteGroupDiagnosticId(group.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                />
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período de Nascimento"
                name="init_date"
                htmlType="date"
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Aplicar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!deleteGroupDiagnosticId}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Grupo de Diagnósticos`}
        modalText={`Você tem certeza que deseja excluir este grupo de diagnósticos?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { DiagnosticsHistoryTemplate }
