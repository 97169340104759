import { useCallback, useState } from 'react'
import { useParams } from 'react-router-dom'

import * as yup from 'yup'

import { useForm } from 'components/organisms'

import { useAnimal } from 'app/core/hooks'
import { useEvaluations } from 'app/core/hooks/evaluation'
import { IParamProps } from 'app/core/routes/routes'
import { EvaluationCreateRequestData } from 'app/core/types/evaluation'
import {
  calcAverageDailyGain,
  dateTimeISOFormat,
  dateToday,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { EvaluationDetailsTemplate } from './template'

const EvaluationDetails: React.FC = () => {
  const { animalId } = useParams<IParamProps>()

  const [isEvaluationModalOpen, setIsEvaluationModalOpen] =
    useState<boolean>(false)

  const { animalEvaluations, createEvaluation } = useEvaluations({
    animalId,
  })
  const { animal } = useAnimal({ animalId })

  const handleNewEvaluation = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { score, date, evaluation, weight } = data

      const avg = calcAverageDailyGain(
        animal?.additional_information.birth_weight as number,
        weight as number,
        animal?.basic_information.birth_date as string,
        dateToday
      )

      const request = {
        animal_id: Number(animalId),
        score: Number(score),
        date: dateTimeISOFormat(date as string),
        evaluation,
        ...(weight ? { weight: Number(weight), average_daily_gain: avg } : {}),
      } as EvaluationCreateRequestData

      handleEvaluationModal()

      await createEvaluation(request)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [animal, animalId, createEvaluation]
  )

  const handleEvaluationModal = (): void => {
    reset()
    setIsEvaluationModalOpen(prevState => !prevState)
  }

  const validationSchema = yup.object({
    score: yup.string().required(Messages.EVALUATION_SCORE_REQUIRED).nullable(),
  })

  const { Form, handleSubmit, reset } = useForm({
    onSubmit: handleNewEvaluation,
    validationSchema,
  })

  const onSubmitEvaluation = async (
    data: Record<string, unknown>
  ): Promise<void> => {
    await handleNewEvaluation(data)
    reset()
  }

  return (
    <EvaluationDetailsTemplate
      animal={animal}
      animalEvaluations={animalEvaluations}
      onSubmitEvaluation={handleSubmit(onSubmitEvaluation)}
      isEvaluationModalOpen={isEvaluationModalOpen}
      handleEvaluationModal={handleEvaluationModal}
      form={Form}
    />
  )
}

export { EvaluationDetails }
