/* eslint-disable @typescript-eslint/naming-convention */
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  InseminationAnimalCreateRequestData,
  InseminationAnimalCreateResponseData,
  InseminationAnimalReadResponseData,
  InseminationAnimalUpdateRequestData,
  InseminationAnimalUpdateResponseData,
  InseminationCreateRequestData,
  InseminationFilterProps,
  InseminationProps,
  InseminationReadResponseData,
} from 'app/core/types/hormonal'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/insemination'

const getReadAllInseminations = async (
  filters?: InseminationFilterProps,
  page?: number,
  size?: number
): Promise<InseminationReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadInsemination = async (
  inseminationId?: string | number
): Promise<InseminationProps> => {
  const url = `${baseUrl}/${inseminationId}/`
  const response = await http.get(url)
  return response.data
}

const getReadAnimalsByInsemination = async (
  inseminationId: string | number,
  page?: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<InseminationAnimalReadResponseData> => {
  const url = `${baseUrl}/${inseminationId}/inseminations/`

  const response = await http.get(url, {
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const postCreateInsemination = async (
  request: InseminationCreateRequestData
): Promise<InseminationProps> => {
  const url = `${baseUrl}/`

  const response = await http.post(url, request)
  return response.data
}

const postAddInseminationAnimal = async (
  inseminationId: string | number,
  request: InseminationAnimalCreateRequestData[]
): Promise<InseminationAnimalCreateResponseData> => {
  const url = `${baseUrl}/${inseminationId}/add_insemination/`
  const response = await http.post(url, request)

  return response.data
}

const postRemoveInseminationAnimal = async (
  inseminationId: number,
  inseminatedAnimalId: number
): Promise<[]> => {
  const url = `${baseUrl}/${inseminationId}/remove_insemination/`

  const response = await http.post(url, [inseminatedAnimalId])
  return response.data
}

const patchUpdateInseminationAnimal = async (
  inseminatedAnimalId: number,
  request: InseminationAnimalUpdateRequestData
): Promise<InseminationAnimalUpdateResponseData> => {
  const url = `${baseUrl}/${inseminatedAnimalId}/update_insemination/`

  const response = await http.patch(url, request)
  return response.data
}

const deleteGroupInsemination = async (groupId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}/${groupId}/`)

  return response.data
}

export {
  deleteGroupInsemination,
  getReadAllInseminations,
  getReadAnimalsByInsemination,
  getReadInsemination,
  patchUpdateInseminationAnimal,
  postAddInseminationAnimal,
  postCreateInsemination,
  postRemoveInseminationAnimal,
}
