import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  DrawerTitle,
} from 'components/molecules'
import { ISelectButtonItem, useForm } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import { useAnimal, useFarm, useSector } from 'app/core/hooks'
import { useCollectiveMovements } from 'app/core/hooks/collective-movements'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { AnimalFilterProps, AnimalListProps } from 'app/core/types/animal'
import {
  ANIMAL_CATEGORY_FILTER,
  ANIMAL_SEX_FILTER,
  ASSOCIATION_ANIMALS_FILTER,
  BIRTH_TYPES_FILTER,
  HERD_ANIMALS_FILTER,
  IS_ACTIVE_FILTER,
  REPRODUCTIVE_STATUS_FILTER,
  SHUTDOWN_REASON_OPTIONS,
  SHUTDOWN_REASON_OPTIONS_FILTER,
} from 'app/core/types/filters'
import {
  ShutdownDescriptionLabel,
  ShutdownDescriptionStorageProps,
  ShutdownReasonResponseProps,
  ShutdownType,
  ShutdownTypeKeys,
} from 'app/core/types/shutdown'
import { StorageKeys } from 'app/core/types/storage'
import { FarmProps, SectorProps } from 'app/core/types/system'
import { addToast, removeEmptyFilters } from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

import { ENTRY_OPTIONS, EntryOptions } from '../../handlings/create/consts'
import { HANDLING_TYPES } from '../../handlings/history/consts'
import { ExportHerdDynamic } from '../export'
import { AnimalTable } from './table'

const AnimalList: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = useCallback(
    (path, params): void => history.push(path, params),
    [history]
  )

  const { farms, farmsDroplist } = useFarm()
  const { sectors, sectorsDroplist } = useSector()
  const { removeStorageCollectiveMovement } = useCollectiveMovements({})

  const [animals, setAnimals] = useState<AnimalListProps[]>([])
  const [animalQuantity, setAnimalQuantity] = useState<number>()
  const [animalPages, setAnimalPages] = useState<number>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [isExportModalOpen, setIsExportModalOpen] = useState(false)
  const [isFilterDrawer, setIsFilterDrawer] = useState(false)
  const [newEntryType, setNewEntryType] = useState<string | undefined>('')
  const [handlingType, setHandlingType] = useState<string | undefined>()
  const [selectedFarm, setSelectedFarm] = useState<FarmProps>()
  const [selectedSector, setSelectedSector] = useState<SectorProps>()
  const [shutdownReasons, setShutdownReasons] = useState<
    ShutdownReasonResponseProps[]
  >([])
  const [shutdownDescriptions, setShutdownDescriptions] =
    useState<SelectItemProps[]>()
  const [selectedShutdownReason, setSelectedShutdownReason] = useState<string>()
  const [selectedShutdownDescription, setSelectedShutdownDescription] =
    useState<string>()
  const [page, setPage] = useState<number>(1)
  const [filters, setFilters] = useState<AnimalFilterProps>()
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingHerdDynamic, setIsLoadingHerdDynamic] = useState(false)

  const { exportAnimals, exportGeneralReport, isLoadingExport } = useAnimal({
    filters,
    page,
  })

  const getShutdownReasons = (): void => {
    http
      .get('/system_parameters/shutdown_reason/')
      .then(res => {
        const reasons = res.data as ShutdownReasonResponseProps[]
        setShutdownReasons(reasons)
      })
      .catch(e => {
        addToast({ message: e.message })
      })
  }

  const getAnimals = useCallback((): void => {
    setIsLoading(true)

    http
      .get('/animals/', { params: { ...filters, page, size: 50 } })
      .then(response => {
        setAnimals(response.data.items)
        setAnimalQuantity(response.data.total)
        setAnimalPages(response.data.pages)

        if (response.data.total === 0) {
          addToast({ message: Messages.ANIMALS_NOT_FOUND, type: 'warning' })
        }
      })
      .catch(e => {
        addToast({ message: e.message })
      })
      .finally(() => {
        setIsLoading(false)
      })
  }, [page, filters])

  const toggleFilterDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
    setIsFilterDrawer(true)
  }

  const toggleNewEntryDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
    setIsFilterDrawer(false)
  }

  const handleFilterAnimals = useCallback(
    async (data: Record<string, unknown>) => {
      const selectedFilters = removeEmptyFilters(data) as AnimalFilterProps
      setFilters(selectedFilters)
      setPage(1)
      setIsDrawerOpen(false)
      setIsFilterDrawer(true)
    },
    []
  )

  const handleNewEntry = useCallback(async (): Promise<void> => {
    if (newEntryType === EntryOptions.electronicEartag) {
      navigateTo(NavigateList.electronicEartagCreate)
    }

    if (newEntryType === EntryOptions.stockNumber) {
      navigateTo(NavigateList.stockNumberCreate)
    }

    if (newEntryType === EntryOptions.collectiveMovement) {
      removeStorageCollectiveMovement()

      navigateTo(NavigateList.collectiveMovementsCreate)
    }

    if (selectedFarm && newEntryType === EntryOptions.handlingCreate) {
      localStorage.removeItem(StorageKeys.handling_review)
      localStorage.removeItem(StorageKeys.handling_review_failed)
      localStorage.removeItem(StorageKeys.handling_farm_review)
      localStorage.removeItem(StorageKeys.handling_sector_review)

      localStorage.setItem(
        StorageKeys.handling_farm_review,
        JSON.stringify(selectedFarm)
      )

      localStorage.setItem(
        StorageKeys.handling_sector_review,
        JSON.stringify(selectedSector)
      )

      navigateTo(NavigateList.handlingCreate, {
        handlingType,
      })
    }

    if (sectorsDroplist && newEntryType === EntryOptions.animalCreate) {
      localStorage.removeItem(StorageKeys.animals_review)
      localStorage.setItem(
        StorageKeys.animals_sector_review,
        JSON.stringify(selectedSector)
      )
      navigateTo(NavigateList.animalCreate)
    }

    if (
      selectedShutdownReason &&
      newEntryType === EntryOptions.animalShutdown
    ) {
      localStorage.removeItem(StorageKeys.shutdown_review)
      localStorage.removeItem(StorageKeys.shutdown_last_review)
      localStorage.removeItem(StorageKeys.shutdown_date_last_review)
      localStorage.setItem(
        StorageKeys.shutdown_type_review,
        JSON.stringify({
          shutdown_type: selectedShutdownReason,
          shutdown_description: selectedShutdownDescription,
        })
      )
      navigateTo(NavigateList.shutdownCreate)
    }
  }, [
    removeStorageCollectiveMovement,
    navigateTo,
    selectedFarm,
    selectedSector,
    sectorsDroplist,
    handlingType,
    newEntryType,
    selectedShutdownDescription,
    selectedShutdownReason,
  ])

  useEffect(() => {
    getShutdownReasons()
  }, [])

  useEffect(() => {
    getAnimals()
  }, [getAnimals])

  useEffect(() => {
    setSelectedShutdownDescription(undefined)

    if (selectedShutdownReason) {
      setShutdownDescriptions(
        shutdownReasons
          .filter(
            reason =>
              reason.type ===
              ShutdownType[
                selectedShutdownReason as keyof typeof ShutdownTypeKeys
              ]
          )
          .map(reason => ({
            label: reason.description,
            value: reason.id.toString(),
          }))
      )
    }
  }, [selectedShutdownReason, shutdownReasons])

  useEffect(() => {
    if (shutdownDescriptions) {
      localStorage.setItem(
        StorageKeys.shutdown_descriptions,
        JSON.stringify({
          options: shutdownDescriptions,
          selectedValue: selectedShutdownDescription,
        } as ShutdownDescriptionStorageProps)
      )
    }
  }, [selectedShutdownDescription, shutdownDescriptions])

  const farmSectors = selectedFarm?.sectors
    .filter(sector => sector.status)
    .map(e => ({
      label: e.name,
      value: `${e.id}`,
    }))

  const entryTypeChecker = (): boolean | undefined => {
    const isHandlingEntry = newEntryType === EntryOptions.handlingCreate
    const isAnimalEntry = newEntryType === EntryOptions.animalCreate
    const isShutdownEntry = newEntryType === EntryOptions.animalShutdown
    const isCollectiveMovement =
      newEntryType === EntryOptions.collectiveMovement
    const isElectronicEartag = newEntryType === EntryOptions.electronicEartag
    const isStockNumber = newEntryType === EntryOptions.stockNumber

    if (isCollectiveMovement) {
      return false
    }

    if (isStockNumber) {
      return false
    }

    if (isElectronicEartag) {
      return false
    }

    if (isHandlingEntry && handlingType && selectedFarm && selectedSector) {
      return false
    }

    if (isAnimalEntry && selectedSector) {
      return false
    }

    if (
      isShutdownEntry &&
      selectedShutdownReason &&
      selectedShutdownDescription
    ) {
      return false
    }

    return true
  }

  const {
    Form: FilterForm,
    formState,
    reset,
  } = useForm({ onSubmit: handleFilterAnimals })
  const { Form: NewEntryForm } = useForm({ onSubmit: handleNewEntry })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    setFilters(undefined)
    setActiveFilterSection([])
    reset()
  }

  const generalInfoSection = [
    'categories',
    'farm_id',
    'sector_id',
    'sex',
    'electronic_eartag',
    'birth_number',
    'stock_number',
    'name',
    'init_date',
    'final_date',
    'is_active',
    'belongs_to_herd',
  ]
  const birthSection = [
    'last_birth_date_init',
    'last_birth_date_end',
    'birth_forecast_start',
    'birth_forecast_end',
    'last_birth_type',
  ]
  const shutdownSection = [
    'shutdown_reason',
    'shutdown_date_init',
    'shutdown_date_end',
  ]

  const reproductionSection = [
    'weaning_date_init',
    'weaning_date_end',
    'aptitude',
    'association_animal',
  ]

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = (section: string[]): void => {
    setActiveFilterSection(section)
  }

  const handleExportModal = (): void => {
    setIsExportModalOpen(!isExportModalOpen)
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Animais Totais',
      icon: IconNames.download,
      action: exportAnimals,
    },
    {
      name: 'Dinâmica de Rebanho',
      icon: IconNames.download,
      action: handleExportModal,
    },
    {
      name: 'Relatório Geral de Animais',
      icon: IconNames.download,
      action: exportGeneralReport,
      onlyAdmin: true,
    },
  ]

  return (
    <>
      <BoxedLayout
        title="Rebanho"
        quantity={animalQuantity}
        quantityLabel="Animais"
        headerButtonLabel="Filtrar"
        headerButtonAction={toggleFilterDrawer}
        headerButtonIcon={<Icon name={IconNames.filter} />}
        headerButtonFilterActiveFilters={activeFilters}
        headerLargeButtonLabel="Novo lançamento"
        headerLargeButtonAction={toggleNewEntryDrawer}
        currentPage={page}
        setCurrentPage={setPage}
        totalPages={animalPages}
        currentPageItems={animals.length}
        currentPageItemsLabel={'animal'}
        headerSelectButtonLabel="Exportar"
        headerSelectButtonItems={selectButtonItems}
        headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
        headerSelectButtonIconPosition={ButtonIconPosition.right}
        headerSelectButtonLoading={isLoadingExport || isLoadingHerdDynamic}
        isChildrenSticky
      >
        <AnimalTable animals={animals} isLoading={isLoading} />
      </BoxedLayout>

      <ExportHerdDynamic
        isOpen={isExportModalOpen}
        handleModal={handleExportModal}
        setIsLoadingHerdDynamic={setIsLoadingHerdDynamic}
      />

      {isFilterDrawer ? (
        <CustomDrawer
          title="Filtrar"
          isOpen={isDrawerOpen}
          onClose={toggleFilterDrawer}
          reset={resetFilters}
          scrollableDrawer
        >
          <FilterForm>
            <DrawerTitle text="Informações gerais" />

            <DrawerRow>
              <DrawerCol>
                <FilterForm.InputText
                  label="Nº de Brinco Eletrônico"
                  placeholder="Pesquisar nº de brinco eletrônico"
                  name="electronic_eartag"
                  disabled={handleDisabled('electronic_eartag')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.InputText
                  label="Nº de Nascimento"
                  placeholder="Pesquisar nº de nascimento"
                  name="birth_number"
                  disabled={handleDisabled('birth_number')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.InputText
                  label="Nº de Plantel"
                  placeholder="Pesquisar nº de plantel"
                  name="stock_number"
                  disabled={handleDisabled('stock_number')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <FilterForm.Select
                  label="Fazenda"
                  name="farm_id"
                  options={farmsDroplist}
                  disabled={handleDisabled('farm_id')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
              {sectorsDroplist && (
                <DrawerCol>
                  <FilterForm.Select
                    label="Setor"
                    name="sector_id"
                    options={sectorsDroplist}
                    disabled={handleDisabled('sector_id')}
                    onChange={(): void => handleChange(generalInfoSection)}
                  />
                </DrawerCol>
              )}
              <DrawerCol>
                <FilterForm.RadioButton
                  chipButtonMode
                  title="Sexo do Animal"
                  name="sex"
                  disabled={handleDisabled('sex')}
                  options={ANIMAL_SEX_FILTER}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <FilterForm.InputText
                  label="Nome do Animal"
                  placeholder="Pesquisar nome"
                  name="name"
                  disabled={handleDisabled('name')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
              <DrawerCol column>
                <label htmlFor="init_date">Período de Nascimento</label>
                <div>
                  <FilterForm.InputText
                    name="init_date"
                    htmlType="date"
                    disabled={handleDisabled('init_date')}
                    onChange={(): void => handleChange(generalInfoSection)}
                  />

                  <FilterForm.InputText
                    name="final_date"
                    htmlType="date"
                    disabled={handleDisabled('final_date')}
                    onChange={(): void => handleChange(generalInfoSection)}
                  />
                </div>
              </DrawerCol>
              <DrawerCol>
                <FilterForm.RadioButton
                  chipButtonMode
                  title="Status"
                  name="is_active"
                  options={IS_ACTIVE_FILTER}
                  disabled={handleDisabled('is_active')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              <DrawerCol>
                <FilterForm.Select
                  label="Categoria"
                  name="categories"
                  options={ANIMAL_CATEGORY_FILTER}
                  disabled={handleDisabled('categories')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.RadioButton
                  chipButtonMode
                  title="Animal de rebanho"
                  name="belongs_to_herd"
                  options={HERD_ANIMALS_FILTER}
                  disabled={handleDisabled('belongs_to_herd')}
                  onChange={(): void => handleChange(generalInfoSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Partos" />

            <DrawerRow>
              <DrawerCol>
                <FilterForm.InputText
                  label="Data do último parto"
                  name="last_birth_date_init"
                  htmlType="date"
                  disabled={handleDisabled('last_birth_date_init')}
                  onChange={(): void => handleChange(birthSection)}
                />

                <FilterForm.InputText
                  emptyLabelSpacing
                  name="last_birth_date_end"
                  htmlType="date"
                  disabled={handleDisabled('last_birth_date_end')}
                  onChange={(): void => handleChange(birthSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.InputText
                  label="Partos previstos entre"
                  name="birth_forecast_start"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_start')}
                  onChange={(): void => handleChange(birthSection)}
                />

                <FilterForm.InputText
                  emptyLabelSpacing
                  name="birth_forecast_end"
                  htmlType="date"
                  disabled={handleDisabled('birth_forecast_end')}
                  onChange={(): void => handleChange(birthSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.Select
                  label="Tipo do último parto"
                  name="last_birth_type"
                  disabled={handleDisabled('last_birth_type')}
                  options={BIRTH_TYPES_FILTER}
                  onChange={(): void => handleChange(birthSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Baixas" />

            <DrawerRow>
              <DrawerCol>
                <FilterForm.Select
                  label="Motivo de baixa"
                  name="shutdown_reason"
                  options={SHUTDOWN_REASON_OPTIONS_FILTER}
                  disabled={handleDisabled('shutdown_reason')}
                  onChange={(): void => handleChange(shutdownSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.InputText
                  label="Data da baixa"
                  name="shutdown_date_init"
                  htmlType="date"
                  disabled={handleDisabled('shutdown_date_init')}
                  onChange={(): void => handleChange(shutdownSection)}
                />

                <FilterForm.InputText
                  emptyLabelSpacing
                  name="shutdown_date_end"
                  htmlType="date"
                  disabled={handleDisabled('shutdown_date_end')}
                  onChange={(): void => handleChange(shutdownSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerTitle text="Reprodução" />

            <DrawerRow>
              <DrawerCol>
                <FilterForm.InputText
                  label="Desmamaram entre"
                  name="weaning_date_init"
                  htmlType="date"
                  disabled={handleDisabled('weaning_date_init')}
                  onChange={(): void => handleChange(reproductionSection)}
                />

                <FilterForm.InputText
                  emptyLabelSpacing
                  name="weaning_date_end"
                  htmlType="date"
                  disabled={handleDisabled('weaning_date_end')}
                  onChange={(): void => handleChange(reproductionSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.CheckboxButton
                  emptyLabelSpacing
                  name="aptitude"
                  options={REPRODUCTIVE_STATUS_FILTER}
                  disabled={handleDisabled('aptitude')}
                  onChange={(): void => handleChange(reproductionSection)}
                />
              </DrawerCol>
              <DrawerCol>
                <FilterForm.CheckboxButton
                  emptyLabelSpacing
                  name="association_animal"
                  options={ASSOCIATION_ANIMALS_FILTER}
                  disabled={handleDisabled('association_animal')}
                  onChange={(): void => handleChange(reproductionSection)}
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerButtonContainer>
              <Button
                type={ButtonType.primary}
                label="Aplicar"
                size={ButtonSize.large}
              />
            </DrawerButtonContainer>
          </FilterForm>
        </CustomDrawer>
      ) : (
        <CustomDrawer
          title="Novo Lançamento"
          isOpen={isDrawerOpen}
          onClose={toggleFilterDrawer}
        >
          <NewEntryForm>
            <NewEntryForm.Select
              label="Tipo de lançamento"
              placeholder="Selecione uma opção"
              name="entry_type"
              options={ENTRY_OPTIONS}
              onChange={(value): void =>
                setNewEntryType(`${(value as SelectItemProps).value}`)
              }
            />

            {farms &&
              farmsDroplist &&
              newEntryType === EntryOptions.handlingCreate && (
                <>
                  <NewEntryForm.Select
                    label="Tipo de manejo"
                    placeholder="Selecione uma opção"
                    name="handling_type"
                    options={HANDLING_TYPES}
                    onChange={(value): void =>
                      setHandlingType(`${(value as SelectItemProps).value}`)
                    }
                  />
                  <NewEntryForm.Select
                    label="Selecionar fazenda"
                    placeholder="Selecione uma opção"
                    name="farm_name"
                    options={farmsDroplist}
                    onChange={(e): void =>
                      setSelectedFarm(
                        farms.find(
                          farm =>
                            farm.id === parseInt((e as SelectItemProps)?.value)
                        )
                      )
                    }
                  />
                  {sectors && farmSectors && (
                    <NewEntryForm.Select
                      label="Selecionar setor"
                      placeholder="Selecione uma opção"
                      name="sector_name"
                      options={farmSectors}
                      onChange={(e): void =>
                        setSelectedSector(
                          sectors.find(
                            sector =>
                              sector.id ===
                              parseInt((e as SelectItemProps)?.value)
                          )
                        )
                      }
                    />
                  )}
                </>
              )}

            {sectors &&
              sectorsDroplist &&
              newEntryType === EntryOptions.animalCreate && (
                <NewEntryForm.Select
                  label="Selecionar setor"
                  placeholder="Selecione uma opção"
                  name="sector_id"
                  options={sectorsDroplist}
                  onChange={(e): void =>
                    setSelectedSector(
                      sectors.find(
                        sector =>
                          sector.id === parseInt((e as SelectItemProps)?.value)
                      )
                    )
                  }
                />
              )}

            {newEntryType === EntryOptions.animalShutdown && (
              <>
                <NewEntryForm.Select
                  label="Motivo de baixa"
                  placeholder="Selecione uma opção"
                  name="shutdown_reason"
                  options={SHUTDOWN_REASON_OPTIONS}
                  onChange={(value): void =>
                    setSelectedShutdownReason(
                      `${(value as SelectItemProps).value}`
                    )
                  }
                />

                {selectedShutdownReason && (
                  <NewEntryForm.Select
                    label={
                      ShutdownDescriptionLabel[
                        selectedShutdownReason as keyof typeof ShutdownType
                      ]
                    }
                    placeholder="Selecione uma opção"
                    name="shutdown_description"
                    options={shutdownDescriptions as SelectItemProps[]}
                    onChange={(value): void =>
                      setSelectedShutdownDescription(
                        `${(value as SelectItemProps).value}`
                      )
                    }
                  />
                )}
              </>
            )}

            <DrawerButtonContainer>
              <Button
                type={ButtonType.primary}
                label="Avançar"
                size={ButtonSize.large}
                icon={<Icon name={IconNames['chevron-forward']} />}
                iconPosition={ButtonIconPosition.right}
                disabled={entryTypeChecker()}
              />
            </DrawerButtonContainer>
          </NewEntryForm>
        </CustomDrawer>
      )}
    </>
  )
}

export { AnimalList }
