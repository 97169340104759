import React from 'react'

import { Menu } from 'components/organisms'

import { AnimalHistoryTypes, IAnimalEntity } from 'app/core/types/animal'

import {
  AnimalAdvancedInfo,
  AnimalBasicInfo,
  AnimalHeader,
  AnimalHistory,
} from './helpers'
import styles from './styles.module.scss'

export interface IAnimalLayoutProps {
  animal: IAnimalEntity
  buttonAction: () => void
  isAdminUser: boolean
}

const AnimalLayout: React.FC<IAnimalLayoutProps> = ({
  animal,
  buttonAction,
  isAdminUser,
}) => {
  return (
    <div className={styles.page}>
      <Menu />

      <main className={styles.main}>
        <div className={styles.stickyHeader}>
          <AnimalHeader
            header={animal.header}
            buttonAction={buttonAction}
            shutdown={animal.history.find(
              e => e.type === AnimalHistoryTypes.animal_shutdown
            )}
            isAdminUser={isAdminUser}
          />

          <div className={styles.animalInfo}>
            <AnimalBasicInfo
              additionalInformation={animal.additional_information}
              basicInfo={animal.basic_information}
              isCompositeBreed={animal.additional_information.composite_breed}
            />
          </div>
        </div>

        <div className={styles.animalInfo}>
          <AnimalAdvancedInfo
            sex={animal.header.sex}
            breed={animal.basic_information.breed}
            additionalInformation={animal.additional_information}
            genealogy={animal.genealogy}
            reproduction={animal.reproduction}
            association={animal.association}
          />
          {animal.history.length > 0 && (
            <AnimalHistory history={animal.history} />
          )}
        </div>
      </main>
    </div>
  )
}

export { AnimalLayout }
