import React from 'react'

import {
  Chip,
  ChipColorVariant,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import { AnimalSexLabel } from 'app/core/types/animal'
import { BirthProps } from 'app/core/types/birth'

import styles from './styles.module.scss'

type BirthExpandedInfoProps = {
  data: BirthProps
}

const BirthExpandedInfo: React.FC<BirthExpandedInfoProps> = ({ data }) => {
  return (
    <div className={styles.expandedSection}>
      {data.sex && (
        <div>
          <span className={styles.caption}>Sexo da cria</span>
          <Chip
            colorVariant={
              data.calf_sex === AnimalSexLabel.female
                ? ChipColorVariant.sexFemale
                : ChipColorVariant.sexMale
            }
          />
        </div>
      )}

      <div>
        <span className={styles.caption}>Tipo do parto</span>
        <Chip selected={false} label={data.birth_type} />
      </div>

      {data.farm && (
        <div>
          <span className={styles.caption}>Fazenda</span>
          <Chip selected={false} label={data.farm?.name} />
        </div>
      )}

      {data.sector && (
        <div>
          <span className={styles.caption}>Setor</span>
          <Chip selected={false} label={data.sector?.name} />
        </div>
      )}

      {data.breed && (
        <div>
          <span className={styles.caption}>Raça</span>
          <Chip selected={false} label={data.breed?.name} />
        </div>
      )}

      {data.father && (
        <div>
          <span className={styles.caption}>Pai / Grupo de repasse</span>
          <Typography text={data.father?.name} variant={TypographyVariant.h6} />
        </div>
      )}
    </div>
  )
}

export { BirthExpandedInfo }
