/* eslint-disable @typescript-eslint/naming-convention */
import {
  BirthCreateRequestData,
  BirthCreateResponseData,
  BirthInfoResponseData,
  BirthProps,
  BirthUpdateRequestData,
  BirthsFilterProps,
  BirthsResponseData,
} from 'app/core/types/birth'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/calf_birth/'

const getReadAllBirths = async (
  filters?: BirthsFilterProps,
  page?: number,
  size?: number
): Promise<BirthsResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })

  return response.data
}

const getReadBirth = async (birthId: string | number): Promise<BirthProps> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.get(url)
  return response.data
}

const getBirthInfo = async (
  motherId: string | number
): Promise<BirthInfoResponseData> => {
  const response = await http.get(`${baseUrl}calf_birth_info/`, {
    paramsSerializer: { indexes: null },
    params: { mother_id: motherId },
  })
  return response.data
}

const postCreateBirth = async (
  payload: BirthCreateRequestData
): Promise<BirthCreateResponseData> => {
  const response = await http.post(baseUrl, payload)
  return response.data
}

const patchUpdateBirth = async (
  birthId: string | number,
  request: BirthUpdateRequestData
): Promise<void> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.patch(url, request)
  return response.data
}

const deleteCalfBirth = async (birthId: number): Promise<void> => {
  const url = `${baseUrl}${birthId}/`

  const response = await http.delete(url)
  return response.data
}

export {
  deleteCalfBirth,
  getBirthInfo,
  getReadAllBirths,
  getReadBirth,
  patchUpdateBirth,
  postCreateBirth,
}
