import React, { useCallback, useState } from 'react'

import { useFarm, useSchedules } from 'app/core/hooks'
import { ScheduleFilterProps } from 'app/core/types/hormonal'

import { ScheduleHistoryTemplate } from './template'

const ScheduleHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<ScheduleFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedScheduleId, setSelectedScheduleId] = useState<
    number | undefined
  >(undefined)

  const { schedules, isLoading, deleteSchedule } = useSchedules({
    filters,
    page,
  })
  const { farmsDroplist } = useFarm()

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterSchedules = async (data: unknown): Promise<void> => {
    setFilters(data as ScheduleFilterProps)
    handleToggleDrawer()
  }

  const handleToggleModal = useCallback(
    (scheduleId?: number): void => {
      setSelectedScheduleId(scheduleId)
      setIsModalOpen(!isModalOpen)
    },
    [isModalOpen]
  )

  const handleDeleteModalConfirm = useCallback(async () => {
    if (selectedScheduleId) {
      deleteSchedule(selectedScheduleId)
      handleToggleModal(undefined)
    }
  }, [deleteSchedule, selectedScheduleId, handleToggleModal])

  return (
    <ScheduleHistoryTemplate
      schedules={schedules}
      farms={farmsDroplist}
      currentPage={page}
      setCurrentPage={setPage}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      onSubmit={handleFilterSchedules}
      isLoading={isLoading}
      isModalOpen={isModalOpen}
      handleToggleModal={handleToggleModal}
      onDeleteClick={handleDeleteModalConfirm}
    />
  )
}

export { ScheduleHistory }
