import { IRadioButtonOption } from "components/atoms";

export const USER_TYPE_OPTIONS: IRadioButtonOption[] = [
  {
    label: 'Admin',
    value: 'admin',
  },
  {
    label: 'Usuário',
    value: 'regular',
  },
  {
    label: 'Executor',
    value: 'executor',
    disabled: true
  },
]
