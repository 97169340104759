import { Dispatch, SetStateAction } from 'react'

import { RecommendationCard } from './components'
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  withLoading,
} from 'components/molecules'
import { ISelectButtonItem, SearchAnimal, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import {
  MatingRecommendationFilterProps,
  MatingRecommendationReadResponseData,
} from 'app/core/types/mating-recommendation'

type MatingRecommendationHistoryTemplateProps = {
  recommendations?: MatingRecommendationReadResponseData
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<
    SetStateAction<MatingRecommendationFilterProps | undefined>
  >
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  headerSelectButtonItems: ISelectButtonItem[]
  isLoading: boolean
  isLoadingExport: boolean
}

const MatingRecommendationHistoryTemplate: React.FC<MatingRecommendationHistoryTemplateProps> =
  ({
    recommendations,
    currentPage,
    setCurrentPage,
    setFilters,
    isDrawerOpen,
    handleToggleDrawer,
    onSubmit,
    headerSelectButtonItems,
    isLoading,
    isLoadingExport,
  }) => {
    const { sectorsDroplist } = useSector()

    const { Form, formState, reset } = useForm({ onSubmit })

    const activeFilters = Object.entries(formState.touchedFields).length

    const resetFilters = (): void => {
      setFilters(undefined)
      reset()
    }

    return (
      <>
        <BoxedLayout
          title="Recomendações de Acasalamento"
          currentPage={currentPage}
          totalPages={recommendations?.pages}
          setCurrentPage={setCurrentPage}
          headerSelectButtonLabel="Planilha"
          headerSelectButtonItems={headerSelectButtonItems}
          headerSelectButtonIcon={<Icon name={IconNames['chevron-down']} />}
          headerSelectButtonIconPosition={ButtonIconPosition.right}
          headerSelectButtonLoading={isLoadingExport}
        >
          <SearchAnimal
            navigation={NavigateList.matingRecommendationDetails}
            findOnlyFemale
          />

          {withLoading(
            <>
              <DataCounter
                counter={recommendations?.total}
                counterLabel={'Registros'}
                filterButtonAction={handleToggleDrawer}
                filterButtonActiveFilters={activeFilters}
              />

              {recommendations?.items.map(recommendation => (
                <RecommendationCard
                  key={recommendation.id}
                  recommendation={recommendation}
                />
              ))}
            </>
          )(isLoading)}
        </BoxedLayout>

        <CustomDrawer
          title="Filtrar"
          isOpen={isDrawerOpen}
          onClose={handleToggleDrawer}
          reset={resetFilters}
        >
          <Form>
            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Brinco Eletrônico"
                  name="electronic_eartag"
                  placeholder="Pesquisar nº de brinco eletrônico"
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Nascimento"
                  name="birth_number"
                  placeholder="Pesquisar nº de nascimento"
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Nº de Plantel"
                  name="stock_number"
                  placeholder="Inserir número"
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerRow>
              {sectorsDroplist && (
                <DrawerCol>
                  <Form.Select
                    label="Setor"
                    name="sector_id"
                    options={sectorsDroplist}
                  />
                </DrawerCol>
              )}
              <DrawerCol>
                <Form.InputText
                  label="Nome do Animal"
                  name="animal_name"
                  placeholder="Pesquisar nome do animal"
                />
              </DrawerCol>
              <DrawerCol>
                <Form.InputText
                  label="Período de Nascimento"
                  name="init_date"
                  htmlType="date"
                />

                <Form.InputText
                  emptyLabelSpacing
                  name="final_date"
                  htmlType="date"
                />
              </DrawerCol>
            </DrawerRow>

            <DrawerButtonContainer>
              <Button
                type={ButtonType.primary}
                label="Aplicar"
                size={ButtonSize.large}
              />
            </DrawerButtonContainer>
          </Form>
        </CustomDrawer>
      </>
    )
  }

export { MatingRecommendationHistoryTemplate }
