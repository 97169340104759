/* eslint-disable @typescript-eslint/naming-convention */
import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useRef, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { useLocation } from 'app/core/hooks'
import {
  LocationFormStateProps,
  LocationProps,
  LocationUpdateRequestData,
} from 'app/core/types/system'

import { locationListColumns } from './tables'
import { LocationTabTemplate } from './template'

export enum LocationDrawerSteps {
  CREATE = 1,
  ALERT = 2,
  REVISION = 3,
}

export type FormRef = {
  resetForm: () => void
  resetEditForm: () => void
  setValue(name: string, value: unknown): void
}

const LocationTab: React.FC = () => {
  const ref = useRef<FormRef>(null)

  const {
    locations,
    createLocation,
    updateLocation,
    deleteLocation,
    isLoading,
  } = useLocation()

  const [globalFilter, setGlobalFilter] = useState('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [formState, setFormState] = useState<LocationFormStateProps>(
    {} as LocationFormStateProps
  )
  const [drawerStep, setDrawerStep] = useState<LocationDrawerSteps>(
    LocationDrawerSteps.CREATE
  )
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedLocation, setSelectedLocation] = useState<LocationProps>()

  const handleToggleDrawer = useCallback((): void => {
    setIsDrawerOpen(prevState => !prevState)
    setDrawerStep(LocationDrawerSteps.CREATE)
    ref?.current?.resetForm()
    setFormState({} as LocationFormStateProps)
  }, [])

  const handleEditModal = (): void => {
    setSelectedLocation(undefined)
    setIsEditModalOpen(prevState => !prevState)
  }

  const handleDeleteModal = (): void => {
    setSelectedLocation(undefined)
    setIsDeleteModalOpen(prevState => !prevState)
  }

  const handleCreateNewLocation = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      if (drawerStep === LocationDrawerSteps.REVISION) {
        await createLocation(formState)

        handleToggleDrawer()

        return
      }

      setFormState(data as LocationFormStateProps)
      setDrawerStep(prevState => prevState + 1)
    },
    [createLocation, drawerStep, formState, handleToggleDrawer]
  )

  const handleDeleteConfirm = useCallback(async (): Promise<void> => {
    if (!selectedLocation?.id) return
    await deleteLocation(selectedLocation?.id)
    handleDeleteModal()
  }, [selectedLocation, deleteLocation])

  const handleDrawerBack = (): void => {
    if (drawerStep === LocationDrawerSteps.CREATE) {
      handleToggleDrawer()
      return
    }

    setDrawerStep(prevState => prevState - 1)
  }

  const handleEditFormSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { name, farm_id } = data as {
        name: string
        farm_id: SelectItemProps
      }

      if (!selectedLocation?.id) return

      const request = {
        name,
        ...(farm_id && { farm_id: Number(farm_id.value) }),
      } as LocationUpdateRequestData

      await updateLocation(selectedLocation?.id, request)

      handleEditModal()
    },
    [selectedLocation, updateLocation]
  )

  const handleEdit = useCallback((location: LocationProps) => {
    ref?.current?.resetEditForm()
    ref?.current?.setValue('name', location.name)
    ref?.current?.setValue('farm', {
      value: location.farm_name,
      label: location.farm_name,
    })
    setSelectedLocation(location)
    setIsEditModalOpen(true)
  }, [])

  const handleDelete = useCallback((location: LocationProps) => {
    setSelectedLocation(location)
    setIsDeleteModalOpen(true)
  }, [])

  const table = useReactTable({
    data: locations || [],
    columns: locationListColumns(handleEdit, handleDelete),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <LocationTabTemplate
      locations={locations}
      table={table}
      setGlobalFilter={setGlobalFilter}
      isDrawerOpen={isDrawerOpen}
      isDeleteModalOpen={isDeleteModalOpen}
      isEditModalOpen={isEditModalOpen}
      drawerStep={drawerStep}
      handleToggleDrawer={handleToggleDrawer}
      handleDrawerBack={handleDrawerBack}
      handleDeleteModal={handleDeleteModal}
      handleDeleteConfirm={handleDeleteConfirm}
      handleEditModal={handleEditModal}
      handleEditFormSubmit={handleEditFormSubmit}
      formState={formState}
      onSubmit={handleCreateNewLocation}
      parentRef={ref}
      selectedLocation={selectedLocation}
      isLoading={isLoading}
    />
  )
}

export { LocationTab }
