import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { useInsemination, useInseminationAnimal } from 'app/core/hooks'
import { NavigateParams } from 'app/core/routes/routes'
import { deleteGroupInsemination } from 'app/core/services'
import { InseminationFilterProps } from 'app/core/types/hormonal'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

import { InseminationHistoryTemplate } from './template'

const InseminationHistory: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path): void => history.push(path)

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<InseminationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteGroupInseminationId, setDeleteGroupInseminationId] =
    useState<number>()

  const { inseminations, isLoading, readAllInseminations } = useInsemination({
    filters,
    page,
  })
  const { clearInseminationStorage } = useInseminationAnimal({})

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterInsemination = async (data: unknown): Promise<void> => {
    setFilters(data as InseminationFilterProps)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteGroupInseminationId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteGroupInseminationId) {
      try {
        await deleteGroupInsemination(Number(deleteGroupInseminationId))

        readAllInseminations()

        addToast({
          message: Messages.INSEMINATION_GROUP_DELETED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }

      handleDeleteModalClose()
    }
  }, [deleteGroupInseminationId, readAllInseminations])

  useEffect(() => {
    clearInseminationStorage()
  }, [clearInseminationStorage])

  return (
    <InseminationHistoryTemplate
      navigateTo={navigateTo}
      entries={inseminations}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterInsemination}
      page={page}
      setPage={setPage}
      isLoading={isLoading}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      deleteGroupInseminationId={deleteGroupInseminationId}
      setDeleteGroupInseminationId={setDeleteGroupInseminationId}
    />
  )
}

export { InseminationHistory }
