/* eslint-disable react-hooks/rules-of-hooks */
import { ColumnDef } from '@tanstack/react-table'

import { Select, SelectItemProps } from 'components/atoms'

import { useClassification, useCostCenter } from 'app/core/hooks'
import {
  ServiceOrderInfoProps,
  ServiceOrderUpdateData,
} from 'app/core/types/service-order'

import styles from '../../styles.module.scss'

type EditableFieldProps = {
  options: SelectItemProps[]
  name: string
}

type EditableFields = {
  [key in 'classification_id' | 'cost_center_id']: EditableFieldProps
}

const serviceOrderListColumnsEditable = (
  updateServiceOrder: (data: ServiceOrderUpdateData) => Promise<void>
): Partial<ColumnDef<ServiceOrderInfoProps>> => {
  const { classificationsDroplist } = useClassification()
  const { costCentersDroplist } = useCostCenter()

  const columns: Partial<ColumnDef<ServiceOrderInfoProps>> = {
    cell: ({ row: { original }, column: { id } }) => {
      const inputId = id as keyof ServiceOrderInfoProps & string

      const editableFields: EditableFields = {
        classification_id: {
          options: classificationsDroplist,
          name: 'classification_name',
        },
        cost_center_id: {
          options: costCentersDroplist,
          name: 'cost_center_name',
        },
      }

      const onSelect = (selectedValue: string | boolean | undefined): void => {
        updateServiceOrder({
          serviceOrderId: original.id,
          payload: {
            [inputId]: selectedValue,
          },
        })
      }

      const editableField = editableFields[inputId as keyof EditableFields]

      if (editableField) {
        const { options, name } = editableField
        const title = original[name as keyof ServiceOrderInfoProps & string]

        return (
          <div title={String(title)}>
            <Select
              className={styles.dropdown}
              name={inputId}
              options={options}
              defaultValue={options.find(
                e => e.value === String(original[inputId])
              )}
              onChange={(value): void =>
                onSelect((value as SelectItemProps).value)
              }
            />
          </div>
        )
      }

      return <></>
    },
  }
  return columns
}

export { serviceOrderListColumnsEditable }
