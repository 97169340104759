import { useCallback, useState } from 'react'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useMatingRecommendations } from 'app/core/hooks/mating-recommendation'
import { MatingRecommendationFilterProps } from 'app/core/types/mating-recommendation'

import { MatingRecommendationHistoryTemplate } from './template'

const MatingRecommendationHistory: React.FC = () => {
  const [filters, setFilters] = useState<MatingRecommendationFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const {
    recommendations,
    isLoading,
    exportMatingRecommendations,
    isLoadingExport,
  } = useMatingRecommendations({
    filters,
    page,
  })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleFilterMatingRecommendation = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      setPage(1)
      setFilters(filters as MatingRecommendationFilterProps)

      handleToggleDrawer()
    },
    []
  )

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Exportar',
      icon: IconNames.download,
      action: (): void => {
        exportMatingRecommendations()
      },
    },
  ]

  return (
    <MatingRecommendationHistoryTemplate
      recommendations={recommendations}
      currentPage={page}
      setCurrentPage={setPage}
      setFilters={setFilters}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterMatingRecommendation}
      headerSelectButtonItems={selectButtonItems}
      isLoading={isLoading}
      isLoadingExport={isLoadingExport}
    />
  )
}

export { MatingRecommendationHistory }
