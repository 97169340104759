import { Column, ColumnFiltersState, Updater } from '@tanstack/react-table'
import { useMemo } from 'react'

import { InputDebounced } from 'components/atoms'

import checkboxStyle from '../../atoms/checkbox-button/styles.module.scss'
import styles from './styles.module.scss'

type FilterTableProps = {
  column: Column<unknown, unknown>
  columnFilters: ColumnFiltersState
  setColumnFilters: (updater: Updater<ColumnFiltersState>) => void
}

const FilterTable: React.FC<FilterTableProps> = ({
  column,
  columnFilters,
  setColumnFilters,
}) => {
  const { filterVariant } = column.columnDef.meta ?? {}

  const columnFilterValue = column.getFilterValue()
  const columnName = column.columnDef.header as string
  const columnId = column.id as string
  const hasMultipleFilters =
    Array.isArray(columnFilterValue) && columnFilterValue.length > 0

  const sortedUniqueValues = useMemo(
    () =>
      Array.from(column.getFacetedUniqueValues().keys()).sort().slice(0, 5000),
    [column]
  )

  const isFilterActive = (
    columnId: string,
    filterValue: string | null | undefined
  ): boolean => {
    const filter = columnFilters.find(filter => filter.id === columnId)
    if (filter && Array.isArray(filter.value))
      return filter.value.includes(filterValue as string)
    return false
  }

  const handleCheckboxChange = (
    id: string,
    value: string,
    checked: boolean
  ): void => {
    setColumnFilters(oldFilters => {
      const currentFilter = oldFilters.find(filter => filter.id === id)
      let newFilterValues: string[] = []

      if (currentFilter) {
        const existingValues = currentFilter.value as string[]
        newFilterValues = checked
          ? [...existingValues, value]
          : existingValues.filter(v => v !== value)
      } else if (checked) {
        newFilterValues = [value]
      }

      const filtersWithoutCurrent = oldFilters.filter(
        filter => filter.id !== id
      )

      if (newFilterValues.length > 0)
        return [...filtersWithoutCurrent, { id, value: newFilterValues }]

      return filtersWithoutCurrent
    })
  }

  return (
    <div className={styles.filterTable}>
      <InputDebounced
        autoFocus
        initialValue={
          hasMultipleFilters ? '' : ((columnFilterValue ?? '') as string)
        }
        handleReset={(): void => setColumnFilters([])}
        onChange={(value): void => column.setFilterValue(value)}
        placeholder={`Filtrar por ${columnName.toLowerCase()}` ?? 'Filtrar...'}
      />

      {filterVariant === 'checkbox' && sortedUniqueValues.length > 0 && (
        <ul className={styles.filterTableContent}>
          {sortedUniqueValues.map((value: string) => (
            <li key={value}>
              <label htmlFor={`filter-${value}`}>
                <span>{value}</span>
                <input
                  className={checkboxStyle.checkboxButton}
                  id={`filter-${value}`}
                  name={`filter-${value}`}
                  checked={isFilterActive(columnId, value.toLowerCase())}
                  onChange={(e): void =>
                    handleCheckboxChange(
                      columnId,
                      value.toLowerCase(),
                      e.target.checked
                    )
                  }
                  type="checkbox"
                />
              </label>
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}

export { FilterTable }
