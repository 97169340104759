import { useCallback, useState } from 'react'

import { addDays, addMonths, format, isAfter, parse, subMonths } from 'date-fns'
import * as yup from 'yup'

import { CustomModal } from 'components/molecules'
import { useForm } from 'components/organisms'

import { useAnimal } from 'app/core/hooks'
import { dateToday } from 'app/core/utils'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ExportHerdDynamicProps = {
  handleModal: () => void
  isOpen: boolean
  setIsLoadingHerdDynamic: React.Dispatch<React.SetStateAction<boolean>>
}

type DateProps = {
  min?: string
  max?: string
}

const ExportHerdDynamic: React.FC<ExportHerdDynamicProps> = ({
  handleModal,
  isOpen,
  setIsLoadingHerdDynamic,
}) => {
  const { exportHerdDynamic } = useAnimal({})

  const [startDate, setStartDate] = useState<DateProps>({
    min: undefined,
    max: dateToday,
  })
  const [endDate, setEndDate] = useState<DateProps>({
    min: undefined,
    max: dateToday,
  })

  const inputDateFormat = 'yyyy-MM-dd'

  const handleFormSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      setIsLoadingHerdDynamic(true)

      const { start_date, end_date } = data

      handleModal()

      const payload = {
        startDate: String(start_date),
        endDate: String(end_date),
      }

      await exportHerdDynamic(payload)

      setIsLoadingHerdDynamic(false)
    },
    [handleModal, exportHerdDynamic, setIsLoadingHerdDynamic]
  )

  const validationSchema = yup.object({
    start_date: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    end_date: yup
      .string()
      .required(Messages.YUP_REQUIRED_FIELD)
      .test(
        'start_date',
        Messages.ANIMALS_HERD_DYNAMIC_HINT,
        function (value: string | undefined) {
          const currentStartDate = parse(
            this.parent.start_date,
            inputDateFormat,
            new Date()
          )
          const currentFinalDate = parse(
            value || '',
            inputDateFormat,
            new Date()
          )
          const maxDateRange = addMonths(currentStartDate, 3)

          return isAfter(maxDateRange, currentFinalDate)
        }
      ),
  })

  const { Form, handleSubmit } = useForm({
    onSubmit: handleFormSubmit,
    validationSchema,
  })

  const handleChange = (
    field: 'startDate' | 'endDate',
    selectedDate: Date | null
  ): void => {
    if (field === 'startDate' && selectedDate) {
      const today = new Date()
      const minDate = format(addDays(selectedDate, 1), inputDateFormat)
      const maxDateRange = addMonths(selectedDate, 3)

      let maxDate = format(maxDateRange, inputDateFormat)

      if (isAfter(maxDateRange, today)) {
        maxDate = dateToday
      }

      setEndDate({
        min: minDate,
        max: maxDate,
      })
    }

    if (field === 'endDate' && selectedDate) {
      const minDate = format(subMonths(selectedDate, 3), inputDateFormat)
      const maxDate = format(addDays(selectedDate, 1), inputDateFormat)

      setStartDate({
        min: minDate,
        max: maxDate,
      })
    }
  }

  return (
    <CustomModal
      modalIsOpen={!!isOpen}
      handleCloseModal={handleModal}
      modalHeader="Selecionar período"
      primaryButtonLabel="Exportar"
      primaryButtonAction={handleSubmit(handleFormSubmit)}
      secondaryButtonLabel="Cancelar"
      secondaryButtonAction={handleModal}
    >
      <div className={styles.form}>
        <Form>
          <p className={styles.modalText}>
            {Messages.ANIMALS_HERD_DYNAMIC_HINT}
          </p>
          <div className={styles.formFieldsetField}>
            <Form.InputText
              htmlType="date"
              name="start_date"
              label="Data de início"
              onChange={(e): void =>
                handleChange('startDate', e.target.valueAsDate)
              }
              min={startDate.min}
              max={startDate.max}
            />
          </div>
          <div className={styles.formFieldsetField}>
            <Form.InputText
              htmlType="date"
              name="end_date"
              label="Data final"
              onChange={(e): void =>
                handleChange('endDate', e.target.valueAsDate)
              }
              min={endDate.min}
              max={endDate.max}
            />
          </div>
        </Form>
      </div>
    </CustomModal>
  )
}

export { ExportHerdDynamic }
