import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction, useEffect, useImperativeHandle } from 'react'

import classNames from 'classnames'
import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DrawerRow,
  SearchTable,
} from 'components/molecules'
import { CustomTable, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'

import { useFarm } from 'app/core/hooks'
import {
  ServiceFormStateProps,
  ServiceProps,
  ServiceType,
} from 'app/core/types/system'
import { Messages } from 'config/messages'

import { DrawerSteps, FormRef } from '.'
import * as templateStyles from '../../styles.module.scss'
import styles from './styles.module.scss'

type ServicesTabTemplateProps = {
  services: ServiceProps[] | undefined
  serviceTypeOptions: SelectItemProps[]
  table: Table<ServiceProps>
  setGlobalFilter: Dispatch<SetStateAction<string>>
  isDrawerOpen: boolean
  isDeleteModalOpen: boolean
  isEditModalOpen: boolean
  drawerStep: DrawerSteps
  handleToggleDrawer: () => void
  handleDrawerBack: () => void
  handleDeleteModal: () => void
  handleDeleteConfirm: () => Promise<void>
  handleEditModal: () => void
  handleEditFormSubmit: (data: Record<string, unknown>) => Promise<void>
  formState: ServiceFormStateProps
  onSubmit: IFormProps['onSubmit']
  parentRef: React.RefObject<FormRef>
  selectedService?: ServiceProps
  isLoading: boolean
}

const ServicesTabTemplate: React.FC<ServicesTabTemplateProps> = ({
  services,
  serviceTypeOptions,
  table,
  setGlobalFilter,
  isDrawerOpen,
  isDeleteModalOpen,
  isEditModalOpen,
  drawerStep,
  handleToggleDrawer,
  handleDrawerBack,
  handleDeleteModal,
  handleDeleteConfirm,
  handleEditModal,
  handleEditFormSubmit,
  formState,
  onSubmit,
  parentRef,
  selectedService,
  isLoading,
}) => {
  const { activeFarmsDroplist } = useFarm()

  const generalTypeServices =
    services &&
    services?.filter(service => service.type === ServiceType.generalService)
  const mechanizationServices =
    services &&
    services?.filter(service => service.type === ServiceType.mechanization)

  const showFields =
    drawerStep === DrawerSteps.CREATE || drawerStep === DrawerSteps.ALERT

  const validationSchema = yup.object({
    service: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    serviceType: yup.string().required(Messages.YUP_REQUIRED_FIELD).nullable(),
    farms: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
        })
      )
      .min(1, Messages.YUP_REQUIRED_FIELD)
      .required(Messages.YUP_REQUIRED_FIELD),
  })

  useImperativeHandle(parentRef, () => ({
    resetForm(): void {
      reset()
    },
    resetEditForm(): void {
      resetEdit()
    },
    setValue(name: string, value: unknown): void {
      setValueEdit(name, value)
    },
  }))

  const { Form, reset, setValue } = useForm({
    onSubmit,
    validationSchema,
  })

  const editValidationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    type: yup.mixed<SelectItemProps>().required(Messages.YUP_REQUIRED_FIELD),
  })

  const {
    Form: EditForm,
    handleSubmit,
    setValue: setValueEdit,
    reset: resetEdit,
  } = useForm({
    onSubmit: handleEditFormSubmit,
    validationSchema: editValidationSchema,
  })

  useEffect(() => {
    const formIsEmpty = Object.keys(formState).length === 0

    if (activeFarmsDroplist && formIsEmpty)
      setValue('farms', activeFarmsDroplist)
  }, [activeFarmsDroplist, formState, setValue])

  if (!services)
    return (
      <Typography text={Messages.EMPTY_DATA} variant={TypographyVariant.p} />
    )

  return (
    <>
      <header className={templateStyles.default.parametersHeader}>
        <SearchTable setGlobalFilter={setGlobalFilter} />

        <div className={templateStyles.default.parametersActions}>
          <Button
            testId="createServiceButton"
            label="Criar novo serviço"
            onClick={handleToggleDrawer}
            type={ButtonType.ghost}
          />
        </div>
      </header>

      <CustomTable table={table} isLoading={isLoading} disableNavigation />

      <CustomDrawer
        title="Criar novo serviço"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
      >
        <Form>
          {showFields && (
            <>
              <DrawerRow singleItem>
                <Form.InputText
                  label="Novo serviço"
                  name="service"
                  placeholder="Escreva o nome do novo serviço aqui"
                />
              </DrawerRow>

              <DrawerRow className={styles.serviceType} singleItem>
                <Form.RadioButton
                  chipButtonMode
                  name="serviceType"
                  title="Tipo de serviço"
                  options={serviceTypeOptions}
                />
              </DrawerRow>

              <DrawerRow singleItem>
                <Form.Select
                  label="Fazendas"
                  name="farms"
                  options={activeFarmsDroplist}
                  isMulti
                />
              </DrawerRow>
            </>
          )}

          {drawerStep === DrawerSteps.ALERT && (
            <DrawerRow singleItem>
              <div className={templateStyles.default.servicesAlert}>
                <Icon name={IconNames['information']} />
                <Typography
                  text="Antes de continuar confira os serviços relacionados abaixo e garanta que este serviço já não está contemplado."
                  variant={TypographyVariant.p}
                />
              </div>
              <div className={templateStyles.default.servicesCard}>
                <Typography
                  text={ServiceType.generalService}
                  variant={TypographyVariant.p}
                />
                {generalTypeServices && generalTypeServices.length > 0 ? (
                  <ul>
                    {generalTypeServices.map(service => (
                      <li key={service.id}>
                        {service.name}{' '}
                        <span className={templateStyles.default.farmName}>
                          (Fazenda {service.farm_name})
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography
                    text={Messages.EMPTY_DATA}
                    variant={TypographyVariant.caption3}
                  />
                )}

                <Typography
                  text={ServiceType.mechanization}
                  variant={TypographyVariant.p}
                />
                {mechanizationServices && mechanizationServices.length > 0 ? (
                  <ul>
                    {mechanizationServices.map(service => (
                      <li key={service.id}>
                        {service.name}{' '}
                        <span className={templateStyles.default.farmName}>
                          (Fazenda {service.farm_name})
                        </span>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <Typography
                    text={Messages.EMPTY_DATA}
                    variant={TypographyVariant.caption3}
                  />
                )}
              </div>
            </DrawerRow>
          )}

          {drawerStep === DrawerSteps.REVISION && (
            <div
              className={classNames(
                templateStyles.default.servicesCard,
                templateStyles.default.servicesCardResume
              )}
            >
              <Typography
                text="Nome do novo serviço a ser criado:"
                variant={TypographyVariant.p}
              />
              <Typography
                text={formState.service}
                variant={TypographyVariant.h4}
              />
              <Typography
                text="Tipo do serviço:"
                variant={TypographyVariant.p}
              />
              <Typography
                text={formState.serviceType}
                variant={TypographyVariant.h4}
              />
              <Typography text="Fazendas:" variant={TypographyVariant.p} />
              {formState.farms.map(farm => (
                <Typography
                  key={farm.value}
                  text={farm.label}
                  variant={TypographyVariant.h5}
                />
              ))}
            </div>
          )}

          <DrawerRow className={templateStyles.default.formActions} singleItem>
            <Button
              onClick={handleDrawerBack}
              htmlType="button"
              type={ButtonType.ghost}
              label={drawerStep === DrawerSteps.CREATE ? 'Cancelar' : 'Voltar'}
              size={ButtonSize.medium}
              icon={<Icon name={IconNames['chevron-back']} />}
            />

            <Button
              type={ButtonType.primary}
              label={
                drawerStep === DrawerSteps.REVISION
                  ? 'Criar serviço'
                  : 'Continuar'
              }
              size={ButtonSize.medium}
            />
          </DrawerRow>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!isEditModalOpen}
        handleCloseModal={handleEditModal}
        modalHeader="Editar serviço"
        primaryButtonLabel="SALVAR"
        primaryButtonAction={handleSubmit(handleEditFormSubmit)}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleEditModal}
      >
        <div className={styles.editForm}>
          <EditForm>
            <EditForm.InputText
              name="name"
              placeholder="Nome do serviço"
              label="Nome do serviço"
              className={styles.breedField}
            />
            <EditForm.Select
              label="Tipo de serviço"
              name="type"
              options={serviceTypeOptions}
              className={styles.breedField}
            />
            <EditForm.Select
              label="Fazenda"
              name="farm_id"
              options={activeFarmsDroplist}
              defaultValue={activeFarmsDroplist?.find(
                farm => farm.value === String(selectedService?.farm_id)
              )}
            />
          </EditForm>
        </div>
      </CustomModal>

      <CustomModal
        modalIsOpen={isDeleteModalOpen}
        handleCloseModal={handleDeleteModal}
        modalHeader={`Excluir serviço`}
        modalText={`Você tem certeza que deseja excluir o serviço ${selectedService?.name}?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModal}
      />
    </>
  )
}

export { ServicesTabTemplate }
