import { useCallback, useEffect, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { getAllClassifications } from 'app/core/services/system/classification'
import { ClassificationHook } from 'app/core/types/hooks'
import { ClassificationProps } from 'app/core/types/system'
import { handleHttpError } from 'app/core/utils'

const useClassification = (farmId?: string): ClassificationHook => {
  const [classifications, setClassifications] = useState<ClassificationProps[]>(
    []
  )
  const [isLoading, setIsLoading] = useState(false)

  const classificationsDroplist = classifications.map(Classification => {
    return {
      label: Classification.name,
      value: `${Classification.id}`,
      subLabel: `${Classification.code}`,
    }
  }) as SelectItemProps[]

  const readAllClassifications = useCallback(async (): Promise<void> => {
    try {
      setIsLoading(true)

      const data = await getAllClassifications({ farm_id: Number(farmId) })
      setClassifications(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [farmId])

  useEffect(() => {
    readAllClassifications()
  }, [readAllClassifications])

  return {
    classifications,
    classificationsDroplist,
    readAllClassifications,
    isLoading,
  }
}

export { useClassification }
