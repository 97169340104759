/* eslint-disable react-hooks/exhaustive-deps */

/* eslint-disable @typescript-eslint/naming-convention */
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { useBreedingStation, useBreedingStationAnimal } from 'app/core/hooks'
import { IParamProps } from 'app/core/routes/routes'
import {
  AnimalDrawerType,
  AnimalFilterProps,
  AnimalSexLabel,
} from 'app/core/types/animal'
import { BreedingStationUpdateRequestData } from 'app/core/types/breeding'

import {
  breedingStationFemalesColumns,
  breedingStationMalesColumns,
} from '../tables'
import { BreedingStationDetailsTemplate } from './template'

const BreedingStationDetails: React.FC = () => {
  const { stationId } = useParams<IParamProps>()

  const [selectedSex, setSelectedSex] = useState<AnimalSexLabel>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [femalePage, setFemalePage] = useState<number>(1)
  const [malePage, setMalePage] = useState<number>(1)
  const [initialDate, setInitialDate] = useState<string>('')
  const [finalDate, setFinalDate] = useState<string>('')
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false)

  const { station, updateStation } = useBreedingStation({ stationId })
  const {
    males,
    females,
    addAnimal,
    removeAnimal,
    isLoading,
    filterAnimals,
    filterIsActive,
  } = useBreedingStationAnimal({
    stationId,
    femalePage,
    malePage,
  })

  const handleToggleDrawer = (sex?: AnimalSexLabel): void => {
    if (sex) {
      setSelectedSex(sex)
    }

    setIsDrawerOpen(prevState => !prevState)
  }

  const handleRemoveAnimal = (animalId: number): void => {
    removeAnimal(animalId)
  }

  const handleUpdateStation = async (
    request: Partial<BreedingStationUpdateRequestData>
  ): Promise<void> => {
    setIsLoadingUpdate(true)

    await updateStation(request)

    setIsLoadingUpdate(false)
  }

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const filterType = filters['filterType']
      delete filters['filterType']

      const updatedFilters = {
        ...filters,
        sex: selectedSex,
      } as AnimalFilterProps

      filterType == AnimalDrawerType.filter
        ? await filterAnimals(updatedFilters)
        : await addAnimal(updatedFilters)

      return handleToggleDrawer()
    },
    [selectedSex]
  )

  useEffect(() => {
    if (initialDate || finalDate) {
      handleUpdateStation({
        init_date: initialDate,
        final_date: finalDate,
      })
    }
  }, [initialDate, finalDate])

  const tableFemales = useReactTable({
    data: females?.items || [],
    columns: breedingStationFemalesColumns(
      handleRemoveAnimal,
      station?.is_active
    ),
    getCoreRowModel: getCoreRowModel(),
  })

  const tableMales = useReactTable({
    data: males?.items || [],
    columns: breedingStationMalesColumns(
      handleRemoveAnimal,
      station?.is_active
    ),
    getCoreRowModel: getCoreRowModel(),
  })

  return (
    <BreedingStationDetailsTemplate
      females={females}
      tableFemales={tableFemales}
      femalePage={femalePage}
      setFemalePage={setFemalePage}
      males={males}
      tableMales={tableMales}
      malePage={malePage}
      setMalePage={setMalePage}
      station={station}
      handleUpdateStation={handleUpdateStation}
      selectedSex={selectedSex}
      isDrawerOpen={isDrawerOpen}
      handleToggleDrawer={handleToggleDrawer}
      onSubmit={handleFilterAnimal}
      setFinalDate={setFinalDate}
      setInitialDate={setInitialDate}
      isLoading={isLoading}
      isLoadingUpdate={isLoadingUpdate}
      resetFilters={async (): Promise<void> => {
        filterAnimals({
          sex: selectedSex,
        } as AnimalFilterProps)
      }}
      filterIsActive={filterIsActive}
    />
  )
}

export { BreedingStationDetails }
