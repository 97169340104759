import jwt_decode from 'jwt-decode'
import { SelectedFiles } from 'use-file-picker'

import { StorageKeys } from 'app/core/types/storage'
import {
  AuthResponseData,
  IUpdateUserRequestData,
  IUserJwtPayload,
  IUserRequestData,
  UserEntity,
  UserRequestData,
} from 'app/core/types/user'
import {
  parseDateToISO,
  parseSelectedFileToBlob,
  removeEmptyFilters,
} from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/auth/'

const postAuthUser = async (
  payload: IUserRequestData
): Promise<AuthResponseData> => {
  const response = await http.post(baseUrl, payload)
  return response.data
}

const getAuthUser = async (): Promise<UserEntity> => {
  const token = localStorage.getItem(StorageKeys.auth_token)
  const decodedToken: IUserJwtPayload = jwt_decode(token as string)
  const response = await http.get(`${baseUrl}users/${decodedToken.user_id}/`)
  return response.data
}

const getAllUsers = async (
  filters?: Partial<UserRequestData>
): Promise<UserEntity[]> => {
  const response = await http.get(`${baseUrl}users/`, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const patchUpdateUser = async (
  userId: number,
  payload: IUpdateUserRequestData
): Promise<UserEntity> => {
  const response = await http.patch(`${baseUrl}users/${userId}/`, payload)
  return response.data
}

const postImportUser = async (file: SelectedFiles): Promise<void> => {
  const formData = new FormData()

  const fileBlob = parseSelectedFileToBlob(file)

  formData.append('file', fileBlob.data, fileBlob.name)
  formData.append('date', parseDateToISO(new Date().toISOString()))

  const response = await http.post(`${baseUrl}import/`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export {
  getAllUsers,
  getAuthUser,
  patchUpdateUser,
  postAuthUser,
  postImportUser,
}
