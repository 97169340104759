import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import * as yup from 'yup'

import { SelectItemProps } from 'components/atoms'
import { useForm } from 'components/organisms'

import { useFarm } from 'app/core/hooks'
import { useUser } from 'app/core/hooks/user'
import { NavigateList } from 'app/core/routes/routes'
import { IUpdateUserRequestData, UserEntity } from 'app/core/types/user'
import { scrollToTop } from 'app/core/utils'
import { Messages } from 'config/messages'

import { UserUpdateTemplate } from './template'

const UserUpdate: React.FC = () => {
  const location = useLocation()
  const selectedUser = location.state as UserEntity

  const history = useHistory()
  const navigateTo = (): void => history.push(NavigateList.userList)

  const { farmsDroplist, isLoading } = useFarm()
  const { updateUser } = useUser()

  const [checked, setChecked] = useState(selectedUser.is_active)

  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    email: yup
      .string()
      .label('E-mail')
      .email(Messages.YUP_INVALID_EMAIL)
      .required(Messages.YUP_REQUIRED_FIELD),
    farm_id: yup
      .object({
        value: yup.string(),
      })
      .default(undefined),
    type: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    birth_date: yup.string().default(undefined),
    registration_number: yup.string().default(undefined),
  })

  const handleChangeStatus = useCallback(
    (): void => setChecked(!checked),
    [checked]
  )

  const handleUpdateUser = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const selectedFarm = data.farm_id as SelectItemProps | undefined
      data.farm_id = selectedFarm ? Number(selectedFarm.value) : undefined
      const updateData = data as unknown as IUpdateUserRequestData
      updateData.is_active = checked
      updateData.birth_date = updateData.birth_date
        ? new Date(updateData.birth_date)
        : undefined
      updateData.registration_number =
        (updateData.registration_number ?? 0) > 0
          ? updateData.registration_number
          : undefined

      await updateUser(Number(selectedUser.id), updateData)
    },
    [checked, selectedUser.id, updateUser]
  )

  const { Form, control } = useForm({
    onSubmit: handleUpdateUser,
    validationSchema,
  })

  useEffect(() => {
    scrollToTop()
  }, [])

  return (
    <UserUpdateTemplate
      selectedUser={selectedUser}
      farmsDroplist={farmsDroplist ?? []}
      form={Form}
      formControl={control}
      userStatus={checked}
      onStatusChange={handleChangeStatus}
      onCancelClick={navigateTo}
      isLoading={isLoading || farmsDroplist?.length == 0}
    />
  )
}

export { UserUpdate }
