import { Dispatch, SetStateAction } from 'react'

import { AxiosError } from 'axios'

import {
  AnimalCowhideColors,
  AnimalFilterProps,
  AnimalListProps,
  AnimalSex,
  AnimalSexLabel,
  AnimalSpecies,
  IAnimalParentProps,
} from './animal'
import { DefaultPagination } from './system'

export enum ReproductiveDiagnosticsType {
  insemination = 'Inseminação',
  impregnate = 'Repasse',
}

export enum BirthType {
  normal = 'Normal',
  assisted = 'Auxiliado',
  abortion = 'Aborto',
  abortionMaternityFund = 'Aborto - Fundo de Maternidade',
  stillbirth = 'Natimorto',
  stillbirth_assisted = 'Natimorto - Parto Auxiliado',
  stillbirth_animal_attack = 'Natimorto - Ataque Animal',
  stillbirth_physical_defect = 'Natimorto - Defeito Físico',
  induced = 'Induzido',
  premature = 'Prematuro',
}

export const ABORTION_TYPES = [
  BirthType.abortion,
  BirthType.abortionMaternityFund,
  BirthType.stillbirth,
  BirthType.stillbirth_assisted,
  BirthType.stillbirth_animal_attack,
  BirthType.stillbirth_physical_defect,
]

export const BIRTH_TYPES = Object.values(BirthType).map(value => ({
  label: value,
  value: value,
}))

type BirthItemObject = {
  name: string
}

export type BirthProps = {
  id: number
  mother_name: string
  mother_id: number
  mother_electronic_eartag: string
  mother_stock_number: string
  calf_name: string
  calf_birth_number: number
  birth_date: string
  calf_sex: AnimalSex | AnimalSexLabel
  sex?: AnimalSex | AnimalSexLabel
  birth_type: BirthType
  type?: BirthType
  farm?: BirthItemObject
  sector: BirthItemObject
  breed: BirthItemObject
  father: IAnimalParentProps
  calf_cowhide_color: string
  cowhide_color?: string
  calf_birth_weight: number

  specie: AnimalSpecies
  stock_number: string
}

export type BirthCreateHookProps = {
  animals: BirthCreateProps[]
}

export type BirthHook = {
  animals: BirthCreateProps[]
  births?: BirthsResponseData
  birth?: BirthProps
  addBirth: (props: BirthCreateHookProps) => Promise<void>
  updateAnimals: (index: number, data: BirthCreateProps) => Promise<void>
  addAnimal: (filters: AnimalFilterProps) => Promise<void>
  updateBirth: (data: BirthProps) => Promise<void>
  setAnimals: Dispatch<SetStateAction<BirthCreateProps[]>>
  failedAnimals: BirthCreateProps[]
  exportFailedAnimals: () => void
  removeStorage: () => void
  isLoading: boolean
  deleteBirth: (birthId: number) => Promise<void>
}

export type BirthHookProps = {
  birthId?: string | number
  filters?: BirthsFilterProps
  page?: number
  size?: number
}

export type BirthUpdateRequestData = {
  date?: string
  calf_birth_number?: number
  calf_name?: string
  calf_sex?: AnimalSex | AnimalSexLabel
  calf_cowhide_color?: string
  calf_birth_weight?: number
}

export type BirthsFilterProps = {
  animal_name: string
  breeding_group_id: number
  init_date: string
  final_date: string
  sector_id: number
} & Pick<AnimalListProps, 'birth_number' | 'stock_number' | 'electronic_eartag'>

export type BirthCreateProps = {
  id?: number
  date?: string
  type?: BirthType
  mother_id?: string | number
  birth_number?: string | number
  name?: string
  sex?: AnimalSexLabel
  cowhide_color?: AnimalCowhideColors
  birth_weight?: number
  mother_electronic_eartag?: string
  mother_stock_number?: string
  father_name?: string
  error_message?: string
  [key: string]: unknown // Index signature
}

type BirthCalfBirth = {
  date?: string
  type?: BirthType
  mother_id?: number | string
}

type BirthNewCalf = {
  birth_number?: number | string
  name?: string
  sex?: AnimalSexLabel | undefined
  cowhide_color?: AnimalCowhideColors | undefined
  birth_weight?: number | string
}

export type BirthCreateRequestData = {
  calf_birth: BirthCalfBirth
  new_calf?: BirthNewCalf[]
}

export type BirthCreateResponseData = {
  calf_birth_id: number
  calf_id: number
}

export type BirthInfoResponseData = {
  father_id: number
  father_name: string
  breeding_group_father_id: number
  last_diagnostic_type: ReproductiveDiagnosticsType
  insemination_date: string
  last_diagnostic_date: string
}

export type BirthResponseError = {
  detail: string
  message: string
}

export type BirthCalfFatherError = {
  animal_id: number
  error: AxiosError<BirthResponseError>
}

export type BirthsResponseData = {
  items: BirthProps[]
} & DefaultPagination

type BirthAnimalListProps = {
  is_birth_valid?: boolean
  calf_father?: string
} & AnimalListProps &
  BirthInfoResponseData

export type BirthAnimalReadResponseProps = {
  items: BirthAnimalListProps[]
} & DefaultPagination
