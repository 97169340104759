import { Chip, ChipColorVariant } from 'components/atoms'

const getCategory = (classificationCode: string): JSX.Element => {
  const isInvestment = classificationCode.charAt(0) === '7'

  return (
    <Chip
      colorVariant={
        isInvestment ? ChipColorVariant.invest : ChipColorVariant.cost
      }
    />
  )
}

export { getCategory }
