import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import { ScheduleExpandedInfo } from './components'
import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { useSector } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { ScheduleReadResponseData } from 'app/core/types/hormonal'

import styles from './styles.module.scss'

type ScheduleHistoryProps = {
  schedules?: ScheduleReadResponseData
  farms?: SelectItemProps[]
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
  isModalOpen: boolean
  handleToggleModal: (scheduleId?: number) => void
  onDeleteClick: () => Promise<void>
}

const ScheduleHistoryTemplate: React.FC<ScheduleHistoryProps> = ({
  schedules,
  currentPage,
  setCurrentPage,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  isLoading,
  isModalOpen,
  handleToggleModal,
  onDeleteClick,
}) => {
  const history = useHistory<NavigateList>()

  const { sectorsDroplist } = useSector()

  const { Form, formState, reset } = useForm({ onSubmit })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
  }

  return (
    <>
      <BoxedLayout
        title="Programação IATF"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={(): void =>
          history.push(NavigateList.iatfScheduleCreate)
        }
        currentPage={currentPage}
        totalPages={schedules?.pages}
        setCurrentPage={setCurrentPage}
      >
        {withLoading(
          <>
            <DataCounter
              counter={schedules?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.schedulesContainer}>
              {schedules?.items.map((schedule, i) => (
                <ExpandCard
                  id={schedule.id.toString()}
                  title={schedule.name}
                  initDate={schedule.date_start}
                  endDate={schedule.date_end}
                  navigation={(): void =>
                    history.push(
                      `${NavigateList.iatfScheduleDetails}${schedule.id}`
                    )
                  }
                  key={`schedule-${i}`}
                  buttonLabel="Editar"
                  buttonIcon={IconNames.pencil}
                  animalsFemale={schedule.total_females}
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      handleToggleModal(schedule.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                >
                  <ScheduleExpandedInfo
                    stationName={schedule.breeding_station_name}
                    protocolName={schedule.hormonal_protocol_name}
                  />
                </ExpandCard>
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            {sectorsDroplist && (
              <DrawerCol>
                <Form.Select
                  label="Setor"
                  name="sector_id"
                  options={sectorsDroplist}
                />
              </DrawerCol>
            )}
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período de Nascimento"
                name="init_date"
                htmlType="date"
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              label="Aplicar"
              type={ButtonType.primary}
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={isModalOpen}
        handleCloseModal={(): void => handleToggleModal(undefined)}
        modalHeader={`Excluir Programação IATF`}
        modalText={`Você tem certeza que deseja excluir esta programação IATF?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={onDeleteClick}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={(): void => handleToggleModal(undefined)}
      />
    </>
  )
}

export { ScheduleHistoryTemplate }
