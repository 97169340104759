import {
  createColumnHelper,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { Chip, Icon, IconNames } from 'components/atoms'
import { Dialog } from 'components/molecules'
import { CustomTable } from 'components/organisms'
import { DefaultLayout } from 'components/templates'

import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { UserEntity, UserType } from 'app/core/types/user'
import { addToast } from 'app/core/utils'
import { http } from 'interfaces/http'

import styles from './styles.module.scss'

const UserList: React.FC = () => {
  const history = useHistory()
  const navigateTo: NavigateParams = (path, params): void =>
    history.push(path, params)

  const [users, setUsers] = useState<UserEntity[]>([])
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)
  const [modalPosition, setModalPosition] = useState([0, 0])
  const [selectedRowId, setSelectedRowId] = useState<string | null>(null)

  const openDialog = (e: React.MouseEvent, id: string): void => {
    const positionXScreenFix = 105
    const positionYScreenFix = 0

    const targetElement = (e.target as HTMLElement).getBoundingClientRect()

    const posX = targetElement.left + targetElement.width - positionXScreenFix
    const posY = targetElement.top - positionYScreenFix

    setSelectedRowId(id)
    setIsDialogOpen(true)
    setModalPosition([posX, posY])
  }

  const renderDialog = (row: UserEntity): JSX.Element | null => {
    if (row.id === selectedRowId) {
      return (
        <Dialog
          items={[
            {
              itemName: 'Atualizar',
              itemAction: (): void =>
                navigateTo(NavigateList.userUpdate, {
                  id: row.id as string,
                  email: row.email as string,
                  type: row.type as UserType,
                  name: row.name as string,
                  farm_name: row.farm_name as string,
                  is_active: row.is_active as boolean,
                  registration_number: row.registration_number as number,
                  birth_date: row.birth_date as string,
                }),
            },
          ]}
          isOpen={isDialogOpen}
          setIsOpen={setIsDialogOpen}
          posX={modalPosition[0]}
          posY={modalPosition[1]}
        />
      )
    }

    return null
  }

  const usersSort = users
    .sort((a, b) => a.name.localeCompare(b.name))
    .sort((a, b) => Number(b.is_active) - Number(a.is_active))

  const columnHelper = createColumnHelper<UserEntity>()

  const columns = [
    columnHelper.accessor('name', {
      header: 'Nome',
      cell: (info): JSX.Element => (
        <span
          className={info.row.original.is_active ? '' : styles.nameDisabled}
        >
          {info.row.original.name}
        </span>
      ),
    }),
    columnHelper.accessor('email', {
      header: 'E-mail',
      cell: (info): JSX.Element => (
        <span
          className={info.row.original.is_active ? '' : styles.nameDisabled}
        >
          {info.row.original.email}
        </span>
      ),
    }),
    columnHelper.accessor('type', {
      header: 'Acesso',
      cell: (info): JSX.Element => (
        <Chip
          label={info.row.original.type === UserType.admin ? 'Admin' : 'User'}
          className={styles.chipType}
        />
      ),
    }),
    columnHelper.accessor('farm_name', {
      header: 'Fazenda',
      cell: (info): JSX.Element => (
        <Chip label={info.row.original.farm_name} className={styles.chipFarm} />
      ),
    }),
    columnHelper.accessor('is_active', {
      header: 'Status',
      cell: (info): JSX.Element => (
        <Chip
          label={info.row.original.is_active ? 'Ativo' : 'Inativo'}
          icon={
            info.row.original.is_active
              ? IconNames['radio-button-on']
              : IconNames['radio-button-off']
          }
          className={
            info.row.original.is_active
              ? styles.chipActive
              : styles.chipDisabled
          }
          disabled={!info.row.original.is_active}
        />
      ),
    }),
    columnHelper.accessor('id', {
      header: '',
      cell: (info): JSX.Element => (
        <span className={styles.actionMenuTriggerWrapper}>
          <Icon
            name={IconNames.ellipsis}
            className={styles.actionMenuTrigger}
            onClick={(e: React.MouseEvent): void =>
              openDialog(e, info.row.original.id as string)
            }
          />
          {renderDialog(info.row.original)}
        </span>
      ),
    }),
  ]

  const table = useReactTable({
    data: usersSort,
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    http
      .get('/auth/users/')
      .then(response => {
        setUsers(response.data)
      })
      .catch(e => {
        addToast({ message: e.data })
      })
  }, [])

  return (
    <DefaultLayout
      header="Usuários"
      subheader={`${users.length} ${
        users.length <= 1 ? 'usuário' : 'usuários'
      }`}
      buttonLabel="Adicionar Usuário"
      buttonAction={(): void => navigateTo(NavigateList.userCreate)}
    >
      <CustomTable
        table={table}
        disableNavigation
        className={styles.userTable}
      />
    </DefaultLayout>
  )
}

export { UserList }
