import { ColumnDef } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'
import { Id } from 'react-toastify'

import { Icon, IconNames } from 'components/atoms'
import { handleDeleteRow } from 'components/organisms/custom-table/helpers'

import { HandledAnimalProps, HandlingTypes } from 'app/core/types/handling'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

type HandlingCreateColumnsProps = {
  handlingType: HandlingTypes
  handledAnimals: HandledAnimalProps[]
  setHandledAnimals: Dispatch<SetStateAction<HandledAnimalProps[]>>
}

const handlingCreateColumns = ({
  handlingType,
  handledAnimals,
  setHandledAnimals,
}: HandlingCreateColumnsProps): ColumnDef<HandledAnimalProps, unknown>[] => [
  {
    header: 'ID do Animal para Manejo',
    accessorKey: 'animal_id',
  },
  {
    header: 'Nº de Nascimento',
    accessorKey: 'birth_number',
  },
  {
    header: 'Nº de Brinco Eletrônico',
    accessorKey: 'electronic_eartag',
  },
  ...(handlingType === HandlingTypes.weaning_handling
    ? [
        {
          header: 'Nº de Plantel',
          accessorKey: 'stock_number',
        },
        {
          header: 'Série',
          accessorKey: 'series',
        },
      ]
    : []),
  {
    header: 'Peso',
    accessorKey: 'current_weight',
  },
  ...(handlingType === HandlingTypes.fattening_handling
    ? [
        {
          header: 'Peso de Nascimento',
          accessorKey: 'birth_weight',
        },
        {
          header: 'Data de Nascimento',
          accessorKey: 'birth_date',
        },
      ]
    : []),
  {
    header: 'GMD',
    accessorKey: 'average_daily_gain',
  },
  {
    header: 'Produto Aplicado',
    accessorKey: 'product_name',
  },
  {
    header: 'Quantidade',
    accessorKey: 'product_quantity',
  },
  {
    header: 'Status',
    accessorKey: 'is_active',
  },
  {
    id: 'delete',
    cell: (info): JSX.Element => (
      <Icon
        name={IconNames.close}
        onClick={(): void | Id =>
          handledAnimals.length > 1
            ? handleDeleteRow(handledAnimals, setHandledAnimals, info.row.index)
            : addToast({
                message: Messages.ANIMAL_DELETE_DISABLED,
                type: 'warning',
              })
        }
      />
    ),
  },
]

export { handlingCreateColumns }
