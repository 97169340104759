import { SelectedFiles } from 'use-file-picker'

import {
  ClassificationProps,
  ClassificationsRequestData,
} from 'app/core/types/system'
import {
  parseDateToISO,
  parseSelectedFileToBlob,
  removeEmptyFilters,
} from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/system_parameters/classification/'

const getAllClassifications = async (
  filters?: Partial<ClassificationsRequestData>
): Promise<ClassificationProps[]> => {
  const response = await http.get(baseUrl, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postImportClassification = async (file: SelectedFiles): Promise<void> => {
  const formData = new FormData()

  const fileBlob = parseSelectedFileToBlob(file)

  formData.append('file', fileBlob.data, fileBlob.name)
  formData.append('date', parseDateToISO(new Date().toISOString()))

  const response = await http.post(`${baseUrl}import/`, formData, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data',
    },
  })
  return response.data
}

export { getAllClassifications, postImportClassification }
