import { Control, Controller } from 'react-hook-form'
import Switch from 'react-switch'

import {
  Button,
  ButtonSize,
  ButtonType,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  FormButtonContainer,
  FormRow,
  FormSection,
  withLoading,
} from 'components/molecules'
import { FormType } from 'components/organisms/form/types'
import { DefaultLayout } from 'components/templates'

import { UserEntity } from 'app/core/types/user'
import { dateTimeFormat } from 'app/core/utils'

import { USER_TYPE_OPTIONS } from '../consts'
import { UpdateUserLoading } from './loading'

type UserUpdateTemplateProps = {
  selectedUser: UserEntity
  farmsDroplist: SelectItemProps[]
  form: FormType
  formControl: Control
  userStatus: boolean
  onStatusChange: () => void
  onCancelClick: () => void
  isLoading: boolean
}

const UserUpdateTemplate: React.FC<UserUpdateTemplateProps> = ({
  selectedUser,
  farmsDroplist,
  form: Form,
  formControl: control,
  userStatus: checked,
  onStatusChange,
  onCancelClick,
  isLoading,
}) => {
  return (
    <DefaultLayout header="Atualizar Usuário" hasFixedHeight>
      {withLoading(
        <Form>
          <FormSection text="Informações Pessoais">
            <FormRow>
              <Form.InputText
                label="Nome Completo"
                name="name"
                placeholder="Inserir nome"
                defaultValue={selectedUser.name}
              />
              <Form.InputText
                label="E-mail"
                name="email"
                placeholder="Inserir e-mail"
                defaultValue={selectedUser.email}
              />
              <Form.InputText
                label="Data de nascimento"
                name="birth_date"
                htmlType="date"
                defaultValue={
                  selectedUser.birth_date
                    ? dateTimeFormat(selectedUser.birth_date, true)
                    : undefined
                }
              />
              <Form.InputText
                label="Matrícula"
                name="registration_number"
                placeholder="Inserir matrícula"
                defaultValue={selectedUser.registration_number?.toString()}
              />
            </FormRow>
          </FormSection>

          <FormSection text="Informações Administrativas">
            <FormRow>
              {farmsDroplist && (
                <Form.Select
                  label="Fazenda"
                  name="farm_id"
                  options={farmsDroplist}
                  defaultValue={farmsDroplist.find(
                    e => e.label === selectedUser.farm_name
                  )}
                />
              )}

              <Form.RadioButton
                title="Permissão"
                name="type"
                options={USER_TYPE_OPTIONS}
                defaultChecked={USER_TYPE_OPTIONS.find(
                  e => e.value === selectedUser.type
                )}
              />
            </FormRow>

            {/* //TODO: Isolate Switch as Form Component */}
            <FormRow>
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
              >
                <Typography text="Usuário" variant={TypographyVariant.label} />
                <Controller
                  name="switch"
                  control={control}
                  render={(): JSX.Element => (
                    <label
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                      }}
                    >
                      <Switch
                        checked={checked}
                        onChange={onStatusChange}
                        onColor="#C6DEC5"
                        offColor="#C2C2C2"
                        onHandleColor="#6A9068"
                        offHandleColor="#606062"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                      />
                      <span>
                        <Typography
                          text={checked ? 'Ativo' : 'Inativo'}
                          variant={TypographyVariant.label}
                        />
                      </span>
                    </label>
                  )}
                />
              </div>
            </FormRow>
          </FormSection>

          <FormButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Confirmar"
              size={ButtonSize.large}
            />
            <Button
              type={ButtonType.ghost}
              label="Cancelar"
              size={ButtonSize.large}
              onClick={onCancelClick}
            />
          </FormButtonContainer>
        </Form>
      )(isLoading, UpdateUserLoading)}
    </DefaultLayout>
  )
}

export { UserUpdateTemplate }
