import React, { useEffect } from 'react'

import { format, parseISO } from 'date-fns'

import { Typography, TypographyVariant } from 'components/atoms'

import { useServiceOrder } from 'app/core/hooks'
import { useUser } from 'app/core/hooks/user'
import {
  ServiceOrderHistoryDataProps,
  ServiceOrderHistoryDataTypes,
  ServiceOrderHistoryProps,
} from 'app/core/types/service-order'
import {
  ClassificationProps,
  CostCenterProps,
  FarmProps,
  LocationProps,
  MachineryProps,
  ServiceProps,
} from 'app/core/types/system'
import { UserEntity } from 'app/core/types/user'
import { parseServiceOrderDuration } from 'app/core/utils'

import styles from './styles.module.scss'

type EntitiesProps = {
  classifications?: ClassificationProps[]
  locations?: LocationProps[]
  services?: ServiceProps[]
  executers?: UserEntity[]
  farms?: FarmProps[]
  costCenters?: CostCenterProps[]
  machineries?: MachineryProps[]
}

const getEntryField = (field: string, isOldValue: boolean): string => {
  const fieldName =
    ServiceOrderHistoryDataTypes[
      field as keyof typeof ServiceOrderHistoryDataTypes
    ] ?? ''
  if (!isOldValue) return fieldName
  return `${fieldName} Anterior`
}

const EntryValue: React.FC<
  {
    item: ServiceOrderHistoryDataProps
  } & EntitiesProps
> = ({ item, ...entities }) => {
  const entityFinders = {
    classification_id: entities.classifications,
    cost_center_id: entities.costCenters,
    location_id: entities.locations,
    service_id: entities.services,
    farm_id: entities.farms,
    machinery_id: entities.machineries,
    executor_id: entities.executers,
  } as const

  const getEntryValue = (
    value: string | number | null,
    field: keyof typeof ServiceOrderHistoryDataTypes
  ): string | number => {
    if (value === null || value === '') return '-'

    if (field === 'is_machinery_used') {
      if (value) return 'Sim'

      return 'Não'
    }

    if (field === 'duration') {
      return parseServiceOrderDuration(value as number)
    }

    if (field in entityFinders) {
      const entityList = entityFinders[field as keyof typeof entityFinders]
      if (entityList) {
        const entity = entityList.find(entity => entity.id === value)
        return entity?.name || value
      }
    }

    return value
  }

  return (
    <div className={styles.entryCol}>
      <Typography
        text={getEntryField(item.field, item.is_old_value)}
        variant={TypographyVariant.caption3}
        altColor
      />
      <Typography
        text={getEntryValue(item.value, item.field)}
        variant={TypographyVariant.p}
      />
    </div>
  )
}

const Entry: React.FC<
  {
    history?: ServiceOrderHistoryProps
    users?: UserEntity[]
  } & EntitiesProps
> = ({ history, users, ...entities }) => {
  if (!history) return null

  const isUpdate = history.is_update
  const createdBy = users?.find(
    user =>
      user.id === history.data.find(item => item.field === 'created_by')?.value
  )?.name
  const createdDate = history.data.find(item => item.field === 'date')?.value
  const updatedBy = users?.find(user => user.id === history.updated_by)?.name

  return (
    <div className={styles.entry}>
      <header className={styles.entryHeader}>
        <Typography
          text={`${isUpdate ? 'Alteração de boletim' : 'Boletim criado'}`}
          variant={TypographyVariant.h5}
          className={styles.entryName}
        />
        {isUpdate && (
          <p className={styles.entryInfo}>
            <time dateTime={history.date}>
              {format(parseISO(history.date), 'dd/MM/yy HH:mm')}{' '}
            </time>
            {updatedBy && <span>por {updatedBy}</span>}
          </p>
        )}
      </header>
      <div className={styles.entryRow}>
        {isUpdate ? (
          <>
            {history.data.map((item, index) => (
              <EntryValue key={`history-${index}`} item={item} {...entities} />
            ))}
          </>
        ) : (
          <>
            <div className={styles.entryCol}>
              {createdDate && (
                <>
                  <Typography
                    text="Data"
                    variant={TypographyVariant.caption3}
                    altColor
                  />
                  <Typography
                    text={format(
                      parseISO(createdDate as string),
                      'dd/MM/yy HH:mm'
                    )}
                    variant={TypographyVariant.p}
                  />
                </>
              )}
            </div>
            <div className={styles.entryCol}>
              <Typography
                text="Usuário"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Typography text={createdBy} variant={TypographyVariant.p} />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ServiceOrderHistory: React.FC<
  {
    serviceOrderId: number
  } & EntitiesProps
> = ({ serviceOrderId, ...entities }) => {
  const { history, getHistory } = useServiceOrder()
  const { users } = useUser()

  const historySorted = [
    ...(history
      ?.filter(item => item.is_update)
      .sort((a, b) => b.date.localeCompare(a.date)) || []),
    history?.find(item => !item.is_update),
  ].filter(Boolean)

  useEffect(() => {
    getHistory(serviceOrderId)
  }, [getHistory, serviceOrderId])

  return (
    <>
      {historySorted &&
        historySorted.map((update, i) => (
          <Entry key={i} history={update} users={users} {...entities} />
        ))}
    </>
  )
}

export { ServiceOrderHistory }
