import { SelectedFiles } from 'use-file-picker'

import { SelectItemProps } from 'components/atoms'

import { AnimalSpecies } from './animal'

export const ANESTRUS_MINIMUM = 30
export const GESTATION_DIAGNOSTIC_MINIMUM = 28
export const FILTER_ANIMALS_SIZE = 10000
export const EXPORT_ANIMALS_SIZE = 200000
export const DEFAULT_ITEMS_PER_PAGE_DETAILS = 300
export const DEFAULT_ITEMS_PER_PAGE_LIST = 50

export type DefaultPagination = {
  total: number
  pages: number
  size: number
  page: number
}

export type DefaultQueryParam = {
  name: string
  value: string
}

export type FailMessage = Record<number, string>

export type ErrorResponse = {
  detail: string
  message: string
}

export type BreedProps = {
  id: number
  description: string
  abbreviation: string
  specie: AnimalSpecies
  is_european: boolean
}

export type BreedCreateRequestData = Omit<BreedProps, 'id'>

export type AssociationProps = {
  id: number
  association_name: string
  handling_group: number[]
  feeding_group: number[]
}

export type AssociationCreateRequestData = Omit<AssociationProps, 'id'>

export type AssociationDetailsResponseData = Pick<
  AssociationProps,
  'id' | 'association_name'
>

export type FarmProps = {
  id: number
  name: string
  active_sectors: number
  active_animals: number
  status: boolean
  cnpj: string
  state_registration: number
  total_area: number
  productive_area: number
  zip_code: number
  address: string
  address_number: string
  neighborhood: string
  complement: string
  city: string
  state: string
  sectors: SectorProps[]
}

export enum ServiceType {
  generalService = 'Serviços Gerais',
  mechanization = 'Mecanização',
}

export type ServiceCreateRequestData = {
  name: string
  type: ServiceType
  farm_id: number
}

export type ServiceUpdateRequestData = Partial<ServiceCreateRequestData>

export type ServiceProps = {
  id: number
  name: string
  type: ServiceType
  is_active: boolean
  new?: boolean
  farm_id: number
  farm_name: string
}

export type ServiceFormStateProps = {
  service: string
  serviceType: string
  farms: SelectItemProps[]
}

export type LocationFormStateProps = {
  location: string
  farms: SelectItemProps[]
}

export type LocationCreateData = {
  name: string
  farm_id: number
  farm_name: string
}

export type LocationCreateRequestData = {
  name: string
  farm_id: number
}

export type LocationUpdateRequestData = {
  name: string
  farm_id?: number
}

export type LocationProps = {
  id: number
  name: string
  farm_id: number
  is_active: boolean
  farm_name: string
  new?: boolean
}

export type ClassificationProps = {
  id: number
  code: string
  name: string
  is_active: boolean
  farm_id: number
  farm_name: string
}

export type CostCenterProps = {
  id: number
  code: string
  name: string
  is_active: boolean
  classification_id: number
  farm_id: number
  farm_name: string
}

export type SuggestionProps = {
  classification: ClassificationProps
  cost_center: CostCenterProps
}

export type MachineryProps = {
  id: number
  name: string
  fleet_number: number
  code: string
  current_mileage: number
  farm_id: number
  farm_name: string
}

export type FarmCreateRequestData = Omit<FarmProps, 'id' | 'sectors'>

export type FarmParametersProps = {
  avg_ua: number
}

export type SectorActivity = {
  id: number
  activity: string
  sector_id: number
}

export type SectorProps = {
  id: number
  name: string
  activities?: SectorActivity[]
  status: boolean
  farm?: FarmProps
}

export type SectorCreateRequestData = {
  name: string
  activities: string[]
  status: boolean
  farm_id: number
}

export type ReproductionParamsProps = {
  european_gestation_period: number
  zebu_gestation_period: number
  aptitude: number
  female_birth_weight: number
  gestation_diagnosis_limit: number
  max_gestation_period: number
  id: number
  male_birth_weight: number
  anestrus_limit: number
  days_animals_wean: number
}

export enum BrazilianStates {
  AC = 'Acre',
  AL = 'Alagoas',
  AP = 'Amapá',
  AM = 'Amazonas',
  BA = 'Bahia',
  CE = 'Ceará',
  DF = 'Distrito',
  ES = 'Espírito',
  GO = 'Goiás',
  MA = 'Maranhão',
  MT = 'Mato',
  MS = 'Mato',
  MG = 'Minas',
  PA = 'Pará',
  PB = 'Paraíba',
  PR = 'Paraná',
  PE = 'Pernambuco',
  PI = 'Piauí',
  RJ = 'Rio',
  RN = 'Rio',
  RS = 'Rio',
  RO = 'Rondônia',
  RR = 'Roraima',
  SC = 'Santa',
  SP = 'São',
  SE = 'Sergipe',
  TO = 'Tocantins',
}

export const BRAZILIAN_STATES = Object.entries(BrazilianStates).map(
  ([key, value]) => ({ label: value, value: key })
)

export type ZipCodeResponseData = {
  cep: string
  state: string
  city: string
  neighborhood: string
  street: string
}

export enum SectorActivities {
  breed = 'Cria',
  rearing = 'Recria',
  milk = 'Leite',
  fattening = 'Engorda',
  slaughter = 'Corte',
}

export const SECTOR_ACTIVITIES = Object.values(SectorActivities).map(value => ({
  label: value,
  value,
}))

export type ActiveImportProps = {
  file: SelectedFiles
  fileName: string
  added: number
  changed: number
  disables: number
  has_investment?: boolean
}

export enum ImportEntity {
  EXECUTORS = 'auth',
  CLASSIFICATION = 'classification',
  COST_CENTER = 'cost_center',
  MACHINERY = 'machinery',
}

export type ImportCheckResponseData = Pick<
  ActiveImportProps,
  'added' | 'changed' | 'disables' | 'has_investment'
>

export enum ImportedParameterType {
  executor = 'Executor',
  machinery = 'Maquinario',
  classification = 'Classificação',
  cost_center = 'Centro de Custo',
}

export type ImportHistoryResponse = {
  id: number
  name: string
  date: string
}

export type SystemRequestData = {
  name: string
  farm_id: number
}

export type ServicesRequestData = SystemRequestData & {
  type: ServiceType
}

export type ClassificationsRequestData = SystemRequestData & {
  code: string
}

export type CostCentersRequestData = SystemRequestData & {
  code: string
  classification_id: number
}

export type MachineryRequestData = SystemRequestData
