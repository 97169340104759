/* eslint-disable @typescript-eslint/naming-convention */
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'

import * as yup from 'yup'

import { Button, ButtonSize, ButtonType, SelectItemProps } from 'components/atoms'
import {
  CustomModal,
  FormButtonContainer,
  FormRow,
  FormSection,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { DefaultLayout } from 'components/templates'

import { useFarm } from 'app/core/hooks'
import { NavigateList } from 'app/core/routes/routes'
import { IUserRequestData } from 'app/core/types/user'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'
import { http } from 'interfaces/http'

import { USER_TYPE_OPTIONS } from '../consts'

type UserCreateProps = {
  default: true
}

const UserCreate: React.FC<UserCreateProps> = () => {
  const history = useHistory()
  const navigateTo = (): void => history.push(NavigateList.userList)

  const { farmsDroplist } = useFarm()

  const [modalIsOpen, setModalIsOpen] = useState(false)
  const [username, setUsername] = useState('')

  const validationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    email: yup
      .string()
      .label('E-mail')
      .email(Messages.YUP_INVALID_EMAIL)
      .required(Messages.YUP_REQUIRED_FIELD),
    farm_id: yup.object({
      value: yup.string(),
    }),
    type: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const handleCreateUser = async (data: unknown): Promise<void> => {
    const { email, name, type, farm_id } = data as IUserRequestData

    const farmId = (farm_id as unknown as SelectItemProps).value

    await http
      .post('/auth/users/', {
        email,
        farm_id: farmId,
        name,
        type,
      })
      .then(() => {
        setUsername(name)
        setModalIsOpen(true)
      })
      .catch(e => {
        const message = e.response.data.message
        addToast({ message })
      })
  }

  const { Form } = useForm({ onSubmit: handleCreateUser, validationSchema })

  return (
    <DefaultLayout header="Adicionar Novo Usuário" hasFixedHeight>
      <Form>
        <FormSection text="Informações Pessoais">
          <FormRow>
            <Form.InputText
              label="Nome Completo"
              name="name"
              placeholder="Inserir nome"
            />
            <Form.InputText
              label="E-mail"
              name="email"
              placeholder="Inserir e-mail"
            />
          </FormRow>
        </FormSection>

        <FormSection text="Informações Administrativas">
          <FormRow>
            {farmsDroplist && (
              <Form.Select
                label="Fazenda"
                name="farm_id"
                options={farmsDroplist}
              />
            )}
            <Form.RadioButton
              title="Permissão"
              name="type"
              options={USER_TYPE_OPTIONS}
              defaultChecked={USER_TYPE_OPTIONS.find(
                e => e.value === 'regular'
              )}
            />
          </FormRow>
        </FormSection>

        <FormButtonContainer>
          <Button
            type={ButtonType.primary}
            label="Confirmar"
            size={ButtonSize.large}
          />
          <Button
            type={ButtonType.ghost}
            label="Cancelar"
            size={ButtonSize.large}
            onClick={navigateTo}
          />
        </FormButtonContainer>
      </Form>

      <CustomModal
        modalIsOpen={modalIsOpen}
        handleCloseModal={navigateTo}
        modalHeader="Novo usuário adicionado"
        modalText={`O usuário ${username} foi adicionado com sucesso!`}
        primaryButtonLabel="Concluir"
        primaryButtonAction={navigateTo}
      />
    </DefaultLayout>
  )
}

export { UserCreate }
