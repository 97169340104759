import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { SelectedFiles, useFilePicker } from 'use-file-picker'

import { IconNames, SelectItemProps } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useFarm } from 'app/core/hooks'
import { useCollectiveMovements } from 'app/core/hooks/collective-movements'
import { NavigateList, NavigateParams } from 'app/core/routes/routes'
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  CollectiveMovementProps,
  CollectiveMovementsCreateProps,
} from 'app/core/types/collective-movements'
import { FilterSections } from 'app/core/types/filters'
import { StorageKeys } from 'app/core/types/storage'
import { FILTER_ANIMALS_SIZE } from 'app/core/types/system'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { collectiveMovementsCreateColumns } from '../tables'
import { CollectiveMovementsCreateTemplate } from './template'

const CollectiveMovementsCreate: React.FC = () => {
  const history = useHistory<NavigateParams>()

  const { farms, activeFarmsDroplist: farmsDroplist } = useFarm()

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const [originSectors, setOriginSectors] = useState<SelectItemProps[]>([])
  const [destinationSectors, setDestinationSectors] = useState<
    SelectItemProps[]
  >([])
  const [page, setPage] = useState<number>(1)
  const [filters, setFilters] = useState<Partial<AnimalFilterProps>>()
  const [activeFilterSection, setActiveFilterSection] = useState<string[]>([])

  const {
    animals,
    setAnimals,
    collectiveMovement,
    setCollectiveMovement,
    searchAnimals,
    removeAnimal,
    createStorageCollectiveMovement,
    importCollectiveMovement,
    importCollectiveMovementTemplate,
    isLoading,
  } = useCollectiveMovements({ page })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(prevState => !prevState)
  }

  const handleGoToReview = (): void => {
    if (animals?.items.length === 0) {
      addToast({ message: Messages.COLLECTIVE_MOVEMENTS_ANIMAL_NOT_ADDED })
      return
    }

    if (
      !collectiveMovement.destinationFarm ||
      !collectiveMovement.destinationSector
    ) {
      addToast({ message: Messages.COLLECTIVE_MOVEMENTS_DESTINATION_REQUIRED })
      return
    }

    createStorageCollectiveMovement()

    history.push(NavigateList.collectiveMovementsReview)
  }

  const handleSelectedItem = (
    name: keyof CollectiveMovementProps,
    value: SelectItemProps
  ): void => {
    const selectedFarm = farms?.filter(farm => farm.id === Number(value.value))

    if (name === 'originFarm' || name === 'destinationFarm') {
      const farmSectors =
        selectedFarm &&
        (selectedFarm[0]?.sectors
          .filter(e => e.status)
          .map(e => ({
            label: e.name,
            value: `${e.id}`,
          })) as SelectItemProps[])

      if (farmSectors && name === 'originFarm') {
        setOriginSectors(farmSectors)
      }

      if (farmSectors && name === 'destinationFarm') {
        const filteredSectors = farmSectors.filter(
          sector => sector.value !== collectiveMovement?.originSector?.value
        )

        setDestinationSectors(filteredSectors)
      }
    }

    if (localStorage.getItem(StorageKeys.collective_movements_review_info)) {
      if (selectedFarm && name === 'originFarm') {
        setAnimals([])
        addToast({
          message: Messages.COLLECTIVE_MOVEMENTS_SELECT_ORIGIN,
          type: 'warning',
        })
      }
    }

    setCollectiveMovement(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleFilterAnimal = useCallback(
    async (filters: Record<string, unknown>): Promise<void> => {
      const updatedFilters = {
        ...filters,
        sector_id: [Number(collectiveMovement?.originSector.value)],
        size: FILTER_ANIMALS_SIZE,
        is_active: 'true',
      } as AnimalFilterProps

      setFilters(updatedFilters)

      handleToggleDrawer()
    },
    [collectiveMovement?.originSector]
  )

  const columns = useMemo<ColumnDef<CollectiveMovementsCreateProps>[]>(
    () =>
      collectiveMovementsCreateColumns({
        handleRemoveAnimal: removeAnimal,
        originFarm: collectiveMovement?.originFarm?.label,
        destinationFarm: collectiveMovement?.destinationFarm?.label,
        destinationSector: collectiveMovement?.destinationSector?.label,
      }),
    [collectiveMovement, removeAnimal]
  )

  const table = useReactTable({
    data: (animals?.items as CollectiveMovementsCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  useEffect(() => {
    if (filters) {
      searchAnimals(filters)
    }
  }, [filters, searchAnimals])

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file => handleImportSheet(file),
  })

  const handleImportSheet = async (
    selectedFile: SelectedFiles
  ): Promise<void> => {
    if (
      !collectiveMovement.destinationFarm ||
      !collectiveMovement.destinationSector
    ) {
      addToast({ message: Messages.COLLECTIVE_MOVEMENTS_DESTINATION_REQUIRED })
      return
    }

    importCollectiveMovement({
      target_sector_id: Number(collectiveMovement?.destinationSector?.value),
      file: selectedFile,
    })
  }

  const selectButtonItems: ISelectButtonItem[] = [
    {
      name: 'Importar',
      icon: IconNames.upload,
      action: (): void => {
        openFileSelector()
      },
    },
    {
      name: 'Exportar template',
      icon: IconNames.download,
      action: (): void => {
        importCollectiveMovementTemplate()
      },
    },
  ]

  const filterSections: FilterSections = {
    generalInfoSection: [
      'categories',
      'sector_id',
      'sex',
      'electronic_eartag',
      'birth_number',
      'stock_number',
      'name',
      'init_date',
      'final_date',
    ],
    reproductionSection: [
      'weaning_date_init',
      'weaning_date_end',
      'aptitude',
      'association_animal',
    ],
  }

  const handleDisabled = useCallback(
    (name: string) =>
      activeFilterSection.length > 0 && !activeFilterSection.includes(name),
    [activeFilterSection]
  )

  const handleChange = useCallback(
    (section: string[] | undefined): void => {
      if (section) {
        setActiveFilterSection(section)
      }
    },
    [setActiveFilterSection]
  )

  return (
    <CollectiveMovementsCreateTemplate
      animals={animals}
      table={table}
      farmsDroplist={farmsDroplist}
      collectiveMovement={collectiveMovement}
      handleSelectedItem={handleSelectedItem}
      handleGoToReview={handleGoToReview}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      isLoading={isLoading}
      originSectors={originSectors}
      destinationSectors={destinationSectors}
      page={page}
      setPage={setPage}
      onSubmit={handleFilterAnimal}
      handleChange={handleChange}
      handleDisabled={handleDisabled}
      filterSections={filterSections}
      setActiveFilterSection={setActiveFilterSection}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { CollectiveMovementsCreate }
