import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useRef, useState } from 'react'

import { SelectItemProps } from 'components/atoms'

import { useService } from 'app/core/hooks'
import {
  ServiceFormStateProps,
  ServiceProps,
  ServiceType,
  ServiceUpdateRequestData,
} from 'app/core/types/system'

import { servicesListColumns } from './tables'
import { ServicesTabTemplate } from './template'

export enum DrawerSteps {
  CREATE = 1,
  ALERT = 2,
  REVISION = 3,
}

export type FormRef = {
  resetForm: () => void
  resetEditForm: () => void
  setValue(name: string, value: unknown): void
}

const ServicesTab: React.FC = () => {
  const ref = useRef<FormRef>(null)

  const { services, createService, updateService, deleteService, isLoading } =
    useService()

  const [globalFilter, setGlobalFilter] = useState('')
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [formState, setFormState] = useState<ServiceFormStateProps>(
    {} as ServiceFormStateProps
  )
  const [drawerStep, setDrawerStep] = useState<DrawerSteps>(DrawerSteps.CREATE)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [selectedService, setSelectedService] = useState<ServiceProps>()

  const serviceTypeOptions = Object.keys(ServiceType).map(key => ({
    label: ServiceType[key as keyof typeof ServiceType],
    value: ServiceType[key as keyof typeof ServiceType],
  }))

  const handleToggleDrawer = useCallback((): void => {
    setIsDrawerOpen(prevState => !prevState)
    setDrawerStep(DrawerSteps.CREATE)
    ref?.current?.resetForm()
    setFormState({} as ServiceFormStateProps)
  }, [])

  const handleEditModal = (): void => {
    setSelectedService(undefined)
    setIsEditModalOpen(prevState => !prevState)
  }

  const handleDeleteModal = (): void => {
    setSelectedService(undefined)
    setIsDeleteModalOpen(prevState => !prevState)
  }

  const handleCreateNewService = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      if (drawerStep === DrawerSteps.REVISION) {
        await createService(formState)

        handleToggleDrawer()

        return
      }

      setFormState(data as ServiceFormStateProps)
      setDrawerStep(prevState => prevState + 1)
    },
    [createService, drawerStep, formState, handleToggleDrawer]
  )

  const handleDrawerBack = (): void => {
    if (drawerStep === DrawerSteps.CREATE) {
      handleToggleDrawer()
      return
    }

    setDrawerStep(prevState => prevState - 1)
  }

  const handleEditFormSubmit = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const { name, farm_id, type } = data as {
        name: string
        farm_id: SelectItemProps
        type: SelectItemProps
      }

      if (!selectedService?.id) return

      const request = {
        name,
        ...(farm_id && { farm_id: Number(farm_id.value) }),
        type: type.value,
      } as Partial<ServiceUpdateRequestData>

      await updateService(selectedService?.id, request)

      handleEditModal()
    },
    [selectedService, updateService]
  )

  const handleDeleteConfirm = useCallback(async (): Promise<void> => {
    if (!selectedService?.id) return
    await deleteService(selectedService?.id)
    handleDeleteModal()
  }, [selectedService, deleteService])

  const handleEdit = useCallback((service: ServiceProps) => {
    ref?.current?.resetEditForm()
    ref?.current?.setValue('name', service.name)
    ref?.current?.setValue('type', {
      value: service.type,
      label: service.type,
    })
    setSelectedService(service)
    setIsEditModalOpen(true)
  }, [])

  const handleDelete = useCallback((service: ServiceProps) => {
    setSelectedService(service)
    setIsDeleteModalOpen(true)
  }, [])

  const table = useReactTable({
    data: services || [],
    columns: servicesListColumns(handleEdit, handleDelete),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  return (
    <ServicesTabTemplate
      services={services}
      serviceTypeOptions={serviceTypeOptions}
      table={table}
      setGlobalFilter={setGlobalFilter}
      isDrawerOpen={isDrawerOpen}
      isDeleteModalOpen={isDeleteModalOpen}
      isEditModalOpen={isEditModalOpen}
      drawerStep={drawerStep}
      handleToggleDrawer={handleToggleDrawer}
      handleDrawerBack={handleDrawerBack}
      handleDeleteModal={handleDeleteModal}
      handleDeleteConfirm={handleDeleteConfirm}
      handleEditModal={handleEditModal}
      handleEditFormSubmit={handleEditFormSubmit}
      formState={formState}
      onSubmit={handleCreateNewService}
      parentRef={ref}
      selectedService={selectedService}
      isLoading={isLoading}
    />
  )
}

export { ServicesTab }
