/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useState } from 'react'

import * as XLSX from 'xlsx'

import {
  getReadAllAnimals,
  postAddHandling,
  getHandling as serviceGetHandling,
  getHandlings as serviceGetHandlings,
} from 'app/core/services'
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  HandledAnimalProps,
  HandlingEntity,
  HandlingParams,
  HandlingReadResponseData,
  HandlingTypes,
  ImportHandlingProps,
} from 'app/core/types/handling'
import { HandlingHook } from 'app/core/types/hooks'
import { IProductProps } from 'app/core/types/products'
import { StorageKeys } from 'app/core/types/storage'
import { FarmProps, SectorProps } from 'app/core/types/system'
import {
  addToast,
  calcAverageDailyGain,
  dateForFileName,
  dateTimeXslxFormat,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  getValueOrNull,
  handleHttpError,
  parseNumberFromSheet,
} from 'app/core/utils'
import { Messages } from 'config/messages'

type IsFirstHandlingResult = {
  is_active: boolean
}

type NotFirstHandlingResult = {
  is_active?: undefined
}

type HandledAnimalDefaultRowReturnType =
  | IsFirstHandlingResult
  | NotFirstHandlingResult

const useHandlings = (state?: HandlingParams): HandlingHook => {
  const createHandlingData = state as HandlingParams

  const savedHandling = JSON.parse(
    localStorage.getItem(StorageKeys.handling_review) as string
  ) as HandlingEntity

  const savedHandlingFarm = JSON.parse(
    localStorage.getItem(StorageKeys.handling_farm_review) as string
  ) as FarmProps

  const savedHandlingSector = JSON.parse(
    localStorage.getItem(StorageKeys.handling_sector_review) as string
  ) as SectorProps

  const savedFailedHandlings = JSON.parse(
    localStorage.getItem(StorageKeys.handling_review_failed) as string
  )

  const handlingType =
    (savedHandling && savedHandling?.type) ||
    HandlingTypes[
      createHandlingData?.handlingType as keyof typeof HandlingTypes
    ]

  const handledAnimalDefaultRow = useCallback(
    (handlingType: HandlingTypes): HandledAnimalDefaultRowReturnType => {
      const newAnimalDefaultRow =
        handlingType === HandlingTypes.first_handling
          ? { is_active: true, product_count: 1, product: {} }
          : { product_count: 1, product: {} }

      return newAnimalDefaultRow
    },
    []
  )

  const defaultAnimalRow = handledAnimalDefaultRow(handlingType)

  const [handledAnimals, setHandledAnimals] = useState<HandledAnimalProps[]>(
    savedHandling?.handlings || [defaultAnimalRow]
  )
  const [failedHandlings, setFailedHandlings] = useState<
    Partial<HandledAnimalProps>[]
  >(() => savedFailedHandlings || [])

  const [handlingDate, setHandlingDate] = useState(
    savedHandling?.date || new Date().toISOString()
  )
  const [isLoading, setIsLoading] = useState(false)
  const [handlings, setHandlings] = useState<HandlingReadResponseData>()
  const [handling, setHandling] = useState<HandlingEntity>()

  const createHandling = async (): Promise<void> => {
    if (savedHandling) {
      setIsLoading(true)

      const handlingAnimals = savedHandling?.handlings?.map(animal => {
        animal.average_daily_gain = animal.average_daily_gain ?? 0

        return animal
      })

      const formatDate = new Date(savedHandling?.date)
      savedHandling.date = formatDate.toISOString()

      const payload = {
        date: savedHandling.date,
        type: savedHandling.type,
        farm_id: savedHandling.farm_id,
        handlings: handlingAnimals,
      }

      try {
        const response = await postAddHandling(payload)

        const failedHandlings = [] as Partial<HandledAnimalProps>[]
        let successfulHandlings = handledAnimals

        if (response.erros.length) {
          response.erros.forEach(error => {
            const animalId = Number(Object.keys(error)[0])
            const errorMessage = error[animalId]
            const failedHandling = handledAnimals.find(
              animal => animal.id === animalId
            )

            if (failedHandling) {
              failedHandlings.push({
                stock_number: failedHandling.stock_number,
                electronic_eartag: failedHandling.electronic_eartag,
                birth_number: failedHandling.birth_number,
                error_message: errorMessage,
              })
            }

            successfulHandlings = [
              ...successfulHandlings.filter(
                animal => animal.animal_id !== animalId
              ),
            ]
          })

          setFailedHandlings(failedHandlings)
          setHandledAnimals(successfulHandlings)

          localStorage.setItem(
            StorageKeys.handling_review_failed,
            JSON.stringify(failedHandlings)
          )
          localStorage.setItem(
            StorageKeys.handling_review,
            JSON.stringify({
              type: handlingType,
              date: handlingDate,
              farm_id: savedHandlingFarm?.id,
              handlings: successfulHandlings,
            })
          )

          addToast({
            message: `Não foi possível criar ${getPlural(
              'manejo',
              failedHandlings.length,
              true
            )}. Verifique a planilha de inconsistências e tente novamente.`,
          })
        }

        if (successfulHandlings.length) {
          addToast({
            message: `${getPlural(
              'Manejo',
              successfulHandlings.length,
              true
            )} ${getPlural('criado', successfulHandlings.length)} com sucesso.`,
            type: 'success',
          })
        }
      } catch (e) {
        handleHttpError(e, false)
      } finally {
        setIsLoading(false)
      }
    }
  }

  const filterDuplicatedAnimals = useCallback(
    (
      newAnimals: HandledAnimalProps[],
      handledAnimals: HandledAnimalProps[]
    ): HandledAnimalProps[] => {
      const filteredAnimals: HandledAnimalProps[] = []

      newAnimals.forEach(newAnimal => {
        const alreadyAdded = handledAnimals.some(
          animal => animal.id === newAnimal.id
        )

        if (alreadyAdded) {
          addToast({
            message: `Animal já adicionado: ${
              newAnimal.stock_number || newAnimal.birth_number || newAnimal.id
            }`,
          })
        } else {
          filteredAnimals.push(newAnimal)
        }
      })

      return filteredAnimals
    },
    []
  )

  const searchAnimals = useCallback(
    async (filters: AnimalFilterProps): Promise<HandledAnimalProps[]> => {
      try {
        const animals = await getReadAllAnimals(filters)

        if (!animals.items.length) {
          addToast({
            message: Messages.ANIMALS_NOT_FOUND,
          })

          return []
        }

        const formattedAnimals = animals.items.map(animal => ({
          id: animal.id,
          animal_id: animal.id,
          birth_number: animal.birth_number,
          electronic_eartag: animal.electronic_eartag,
          stock_number: animal.stock_number,
          is_active: animal.is_active,
          product_count: 1,
          product: {},
          added_from_filter: true,
        })) as HandledAnimalProps[]

        return formattedAnimals
      } catch (e) {
        const message = (e as Error).message
        addToast({ message })
        throw new Error(message)
      }
    },
    []
  )

  const xlsxToHandledAnimais = async (
    content: string,
    type: HandlingTypes
  ): Promise<HandledAnimalProps[]> => {
    setIsLoading(true)

    const isFirstHandling = type === HandlingTypes.first_handling
    const isWeaningHandling = type === HandlingTypes.weaning_handling

    const data = new Uint8Array(content as unknown as ArrayBuffer)
    const workbook = XLSX.read(data, {
      type: 'array',
      cellText: false,
      cellDates: true,
    })

    const handledAnimals = [] as HandledAnimalProps[]

    await Promise.all(
      workbook.SheetNames.map(async sheetName => {
        const worksheet = workbook.Sheets[sheetName]
        const rows = XLSX.utils.sheet_to_json(worksheet, {
          header: 1,
          blankrows: false,
          dateNF: 'd"/"m"/"yyyy',
          raw: true,
        }) as ImportHandlingProps[]

        for (let i = 1; i < rows.length; i++) {
          const row = rows[i]

          if (!row[0] && !row[1] && !row[2]) {
            throw new Error('Preencha algum animal na planilha de importação.')
          }

          const importedStockNumber = String(row[0] || '').trim()
          const importedElectronicEartag = String(row[0] || '').trim()
          const importedBirthNumber = String(row[2] || '').trim()
          const newElectronicEartag = row[3] ? row[3] : null
          const importedSeries = row[3] ? String(row[3]).trim() : null

          let weight = row[3] ? parseNumberFromSheet(row[3]) : null
          let avg = undefined

          /*
            Depending on the sheet import handling, the row with the value 'weight'
            could be at a different index
          */
          if (isFirstHandling) weight = parseNumberFromSheet(row[2])
          if (isWeaningHandling) weight = parseNumberFromSheet(row[4])

          const product: Record<string, string> = {}
          let product_count = 0

          const productStartIndex = isWeaningHandling ? 5 : 4

          // Itarate over the next 10 columns in search for products
          for (let index = productStartIndex; index < 23; index += 3) {
            const nameIndex = index
            const quantityIndex = index + 2
            const doseTypeIndex = index + 1

            const productName = row[nameIndex]
              ? String(row[nameIndex]).trim()
              : null
            const productDoseType = row[doseTypeIndex]
              ? String(row[doseTypeIndex]).trim()
              : null
            const productDose = row[quantityIndex]
              ? Number(row[quantityIndex])
              : null

            if (productName && productDose) {
              product[productName] = `${productDose}${productDoseType}`
              product_count++
            }
          }

          if (product_count === 0) product_count = 1

          const filters = {
            stock_number_or_birth_number_or_electronic_eartag: String(
              row[0] || row[1] || row[2]
            )
              .trim()
              .replace(/\n/g, ''),
          } as AnimalFilterProps

          const animal = (await getReadAllAnimals(filters)).items[0]

          if (animal && weight) {
            avg = calcAverageDailyGain(
              animal.birth_weight,
              weight,
              animal.birth_date,
              handlingDate
            )
          }

          const handling: HandledAnimalProps = animal
            ? {
                id: animal.id,
                animal_id: animal.id,
                birth_number: animal.birth_number,
                birth_weight: animal.birth_weight,
                birth_date: animal.birth_date,
                electronic_eartag: isFirstHandling
                  ? newElectronicEartag || undefined
                  : animal.electronic_eartag,
                stock_number: animal.stock_number,
                is_active: animal.is_active,
                average_daily_gain: avg,
                current_weight: weight || undefined,
                product_count: product_count,
                product: product as unknown as IProductProps,
                series: importedSeries || undefined,
              }
            : {
                birth_number: importedBirthNumber,
                stock_number: importedStockNumber,
                electronic_eartag:
                  importedElectronicEartag !== ''
                    ? importedElectronicEartag
                    : undefined,
                current_weight: weight || undefined,
                product_count: product_count,
                product: product as unknown as IProductProps,
                series: importedSeries || undefined,
                error_message: `Animal não encontrado: ${
                  row[0] || row[1] || row[2]
                }`,
              }

          handledAnimals.push(handling)
        }
      })
    )

    setIsLoading(false)

    return handledAnimals
  }

  const getHandlings = useCallback(
    async (page?: number, filters?: Record<string, unknown>): Promise<void> => {
      try {
        setIsLoading(true)
        const handlings = await serviceGetHandlings(page, filters)
        setHandlings(handlings)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const getHandling = useCallback(
    async (
      handlingId: number,
      filters?: Record<string, unknown>
    ): Promise<void> => {
      try {
        setIsLoading(true)
        const handling = await serviceGetHandling(handlingId, filters ?? {})
        setHandling(handling)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoading(false)
      }
    },
    []
  )

  const exportFailedHandlings = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Brinco eletrônico',
        'Nº de Nascimento',
        'Inconsistência',
      ],
      ...failedHandlings.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `manejos-${dateForFileName()}`,
    })
  }

  const exportHandlings = (): void => {
    const handlingDate = handling?.date

    if (handling?.handlings) {
      const templateFile = generateXlsxTemplate([
        [
          'Nº de Nascimento',
          'Nº de Brinco eletrônico',
          'Nº de Plantel',
          'Peso',
          'GMD',
          'Produto aplicado',
          'Quantidade',
          'Status',
          'Data do manejo',
        ],
        ...handling?.handlings.map(handling => [
          handling?.birth_number,
          getValueOrNull(handling?.electronic_eartag, 'string'),
          handling?.stock_number,
          handling?.current_weight || 0,
          handling?.average_daily_gain || 0,
          handling?.product ? Object.keys(handling?.product)[0] : null,
          handling?.product ? Object.values(handling?.product)[0] : null,
          handling?.is_active ? 'Ativo' : 'Inativo',
          handlingDate ? dateTimeXslxFormat(handlingDate) : null,
        ]),
      ])

      downloadFile({
        data: templateFile,
        fileName: `manejos-${dateForFileName()}`,
      })
    }
  }

  return {
    createHandling,
    handledAnimals,
    setHandledAnimals,
    handlingDate,
    setHandlingDate,
    handlingType,
    savedHandling,
    savedHandlingFarm,
    savedHandlingSector,
    filterDuplicatedAnimals,
    searchAnimals,
    xlsxToHandledAnimais,
    isLoading,
    getHandlings,
    getHandling,
    handlings,
    handling,
    exportHandlings,
    failedHandlings,
    exportFailedHandlings,
  }
}

export { useHandlings }
