import { add, differenceInDays, formatISO, parseISO } from 'date-fns'

import {
  AnimalListProps,
  AnimalParentType,
  IAnimalParentProps,
} from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

const animalFatherName = (father: IAnimalParentProps): string | undefined => {
  const isBreedingGroup = father?.type === AnimalParentType.breeding_group

  const name = isBreedingGroup
    ? father?.name
    : father?.name || father?.birth_number || 'Não existe'

  return name
}

const calcAverageDailyGain = (
  birthWeight: number,
  newWeight: number,
  birthDate: string,
  handlingDate: string
): number => {
  const intervalDays = differenceInDays(
    parseISO(handlingDate),
    parseISO(birthDate)
  )

  const avg = (newWeight - birthWeight) / intervalDays
  return Math.floor(avg * 100) / 100
}

const formatWeight = (value: number | undefined): string =>
  value ? `${value} kg` : '0.00 kg'

const findAnimalId = (
  animal: AnimalListProps | undefined
): number | undefined => {
  const animalId = animal?.id

  if (!animal || !animalId) {
    addToast({ message: Messages.ANIMALS_NOT_FOUND })
    return
  }

  return animalId
}

const defaultGestationDiagnosisLimit = 280

const calcMaxBirthDate = (
  diagnosticDate: string,
  gestationDiagnosisLimit = defaultGestationDiagnosisLimit
): string | undefined => {
  const parsedLastDate = parseISO(diagnosticDate)

  const maxDate = formatISO(
    add(parsedLastDate, {
      days: gestationDiagnosisLimit,
    }),
    { representation: 'date' }
  )

  return maxDate
}

export {
  animalFatherName,
  calcAverageDailyGain,
  calcMaxBirthDate,
  findAnimalId,
  formatWeight,
}
