/* eslint-disable @typescript-eslint/naming-convention */
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  ScheduleAnimalProps,
  ScheduleCreateRequestData,
  ScheduleCreateResponseData,
  ScheduleFilterProps,
  ScheduleProps,
  ScheduleReadResponseData,
  ScheduleUpdateAnimalRequestData,
} from 'app/core/types/hormonal'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/hormonal/iatf_schedule'

const getReadAllSchedules = async (
  filters?: ScheduleFilterProps,
  page?: number,
  size?: number
): Promise<ScheduleReadResponseData> => {
  const response = await http.get(`${baseUrl}/`, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadSchedule = async (
  scheduleId?: string | number
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/${scheduleId}/`
  const response = await http.get(url)
  return response.data
}

const getReadAnimalsBySchedule = async (
  scheduleId: string | number,
  filters?: Partial<AnimalFilterProps>
): Promise<ScheduleAnimalProps[]> => {
  const url = `${baseUrl}/${scheduleId}/animals/`

  const response = await http.get(url, {
    params: { ...removeEmptyFilters(filters) },
  })
  return response.data
}

const postCreateSchedule = async (
  request: ScheduleCreateRequestData
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/`

  const response = await http.post(url, request)
  return response.data
}

const postUpdateScheduleAnimal = async (
  request: ScheduleUpdateAnimalRequestData[]
): Promise<ScheduleProps> => {
  const url = `${baseUrl}/bulk/`

  const response = await http.post(url, request)
  return response.data
}

const postAddScheduleAnimal = async (
  scheduleId: string | number,
  animalId: number
): Promise<ScheduleCreateResponseData> => {
  const url = `${baseUrl}/${scheduleId}/animal/${animalId}/`

  const data: Promise<ScheduleCreateResponseData> = await http
    .post(url)
    .then(res => res.data)
    .catch(e => e.response.data)

  return data
}

const deleteRemoveScheduleAnimal = async (
  scheduleId: string | number,
  animalId: number
): Promise<void> => {
  const url = `${baseUrl}/${scheduleId}/animal/${animalId}/`

  const response = await http.delete(url)
  return response.data
}

const deleteRemoveSchedule = async (
  scheduleId: string | number
): Promise<void> => {
  const url = `${baseUrl}/${scheduleId}/`

  const response = await http.delete(url)
  return response.data
}

export {
  deleteRemoveScheduleAnimal,
  getReadAllSchedules,
  getReadAnimalsBySchedule,
  getReadSchedule,
  postAddScheduleAnimal,
  postCreateSchedule,
  postUpdateScheduleAnimal,
  deleteRemoveSchedule,
}
