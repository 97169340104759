/* eslint-disable @typescript-eslint/naming-convention */
import React, { useCallback, useEffect, useState } from 'react'
import { DeepMap, FieldValues } from 'react-hook-form'
import { useParams } from 'react-router-dom'

import axios from 'axios'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  SelectItemProps,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { getDirtyValues } from 'components/organisms/form/helpers'
import { AnimalLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { useAnimal } from 'app/core/hooks'
import { useRevertShutdown } from 'app/core/hooks/animals/use_revert_shutdown'
import { IParamProps } from 'app/core/routes/routes'
import { getReadAnimal, patchUpdateAnimal } from 'app/core/services'
import {
  ANIMAL_SEX_OPTIONS,
  AnimalCategory,
  AnimalParentType,
  AnimalRequestData,
  AnimalSexLabel,
  AnimalUpdateRequestData,
  COWHIDE_COLORS,
  IAnimalEntity,
  IAnimalParentProps,
} from 'app/core/types/animal'
import { addToast } from 'app/core/utils'
import { Messages } from 'config/messages'

import { IS_YEARLING_BULL } from './consts'
import styles from './styles.module.scss'

const AnimalRecord: React.FC = () => {
  const [animal, setAnimal] = useState<IAnimalEntity>()
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [showElectronicEartagInput, setShowElectronicEartagInput] =
    useState(true)
  const [defaultYearlingBull, setDefaultYearlingBull] = useState<string>()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modifiedFields, setModifiedFields] = useState<
    DeepMap<FieldValues, true>
  >({})
  const [deleteElectronicEartag, setDeleteElectronicEartag] =
    useState<boolean>(false)

  const { animalId } = useParams<IParamProps>()
  const { updateAnimal } = useAnimal({ animalId })

  const handleToggleDrawer = useCallback((): void => {
    setIsDrawerOpen(prevState => !prevState)
    setShowElectronicEartagInput(false)
  }, [])

  const handleNewElectronicEartag = (): void => {
    setShowElectronicEartagInput(true)
  }

  const handleUpdateAnimal = useCallback(
    async (data: Record<string, unknown>): Promise<void> => {
      const modifiedFieldsValues = getDirtyValues(data, modifiedFields)

      if (Object.keys(modifiedFieldsValues).length === 0) {
        return
      }

      const { cowhide_color } = data as AnimalRequestData

      const sexData = data as { sex: SelectItemProps }

      const requestData = {
        ...modifiedFieldsValues,
        ...(cowhide_color?.label != animal?.basic_information.cowhide_color && {
          cowhide_color: cowhide_color?.label,
        }),
        ...(sexData?.sex?.value != animal?.header.sex && {
          sex: sexData?.sex?.value as AnimalSexLabel,
        }),
      }

      if (Object.keys(requestData).length > 0) {
        try {
          const animal = await patchUpdateAnimal(animalId, requestData)
          setAnimal(animal)
          addToast({ message: Messages.ANIMAL_UPDATE_SUCCESS, type: 'success' })
          setModifiedFields({})
          reset()
        } catch (e) {
          if (axios.isAxiosError(e) && e.response?.status === 409) {
            addToast({
              message: Messages.ANIMAL_UPDATE_CONFLICT,
            })
            return
          }

          addToast({
            message:
              (axios.isAxiosError(e) && e.response?.data.message) ||
              Messages.ERROR_MESSAGE,
          })
        }
      }

      handleToggleDrawer()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [animal, animalId, handleToggleDrawer, modifiedFields]
  )

  const { Form, formState, reset } = useForm({
    onSubmit: handleUpdateAnimal,
  })

  useEffect(() => {
    setModifiedFields(formState.dirtyFields)
  }, [formState])

  const getAnimal = useCallback(async (): Promise<void> => {
    try {
      const animal = await getReadAnimal(animalId)
      setAnimal(animal)
      setDefaultYearlingBull(
        animal.header.category === AnimalCategory.yearlingBull
          ? 'true'
          : 'false'
      )
    } catch (e) {
      addToast({ message: (e as Error).message })
    }
  }, [animalId])

  useEffect(() => {
    getAnimal()
  }, [getAnimal])

  const revertShutdown = useRevertShutdown

  const handleAnimalLayoutButtonAction = (): void => {
    if (animal?.header.is_active) {
      return handleToggleDrawer()
    }

    handleToggleModal()
  }

  const handleToggleModal = useCallback((): void => {
    setIsModalOpen(!isModalOpen)
  }, [isModalOpen])

  const handleRevertShutdown = useCallback(async (): Promise<void> => {
    handleToggleModal()

    if (!animal) return

    await revertShutdown(animal.id)

    getAnimal()
  }, [animal, revertShutdown, getAnimal, handleToggleModal])

  const handleDeleteModalClose = (): void => {
    setDeleteElectronicEartag(false)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteElectronicEartag) {
      const request = {
        electronic_eartag: null,
      } as Partial<AnimalUpdateRequestData>

      await updateAnimal(request)

      getAnimal()

      handleDeleteModalClose()
      handleToggleDrawer()
    }
  }, [deleteElectronicEartag, getAnimal, handleToggleDrawer, updateAnimal])

  const checkInputDisabled = (
    field: IAnimalParentProps | string | null
  ): boolean => {
    if (!field || typeof field === 'string') return false

    const { stock_number, name } = field
    return Boolean(stock_number || name)
  }

  const defaultInputValue = (
    field: IAnimalParentProps | string | null,
    type: AnimalParentType | undefined
  ): string => {
    if (!field) return ''

    if (typeof field === 'string') return field

    const { stock_number, name } = field
    if (type && type === AnimalParentType.breeding_group) return name || ''

    return stock_number || name || ''
  }

  return (
    <>
      {animal && (
        <AnimalLayout
          animal={animal}
          buttonAction={handleAnimalLayoutButtonAction}
          isAdminUser={isAdmin()}
        />
      )}

      <CustomDrawer
        title="Editar Ficha do Animal"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
      >
        <Form>
          {animal?.header.electronic_eartag && !showElectronicEartagInput && (
            <DrawerRow>
              <DrawerCol>
                <div className={styles.earTagNumberContainer}>
                  <>
                    <Typography
                      text="Nº do brinco eletrônico"
                      variant={TypographyVariant.caption3}
                      altColor
                    />

                    <Typography
                      text={animal.header.electronic_eartag?.toString()}
                      variant={TypographyVariant.h3}
                      className={styles.earTagNumber}
                    />

                    <div className={styles.earTagNumberContainerActions}>
                      <Button
                        type={ButtonType.outline}
                        label="Novo brinco"
                        size={ButtonSize.small}
                        onClick={handleNewElectronicEartag}
                        icon={<Icon name={IconNames['add-circle']} />}
                      />

                      {isAdmin() && (
                        <Button
                          type={ButtonType.destructive}
                          label="Excluir brinco"
                          size={ButtonSize.small}
                          onClick={(): void => setDeleteElectronicEartag(true)}
                          icon={<Icon name={IconNames.close} />}
                        />
                      )}
                    </div>
                  </>
                </div>
              </DrawerCol>
            </DrawerRow>
          )}

          {showElectronicEartagInput && animal?.header.electronic_eartag && (
            <DrawerRow>
              <DrawerCol>
                <Form.InputText
                  label="Novo Nº de Brinco Eletrônico"
                  name="electronic_eartag"
                  placeholder="Inserir brinco eletrônico"
                  defaultValue={animal.header.electronic_eartag?.toString()}
                />
              </DrawerCol>
            </DrawerRow>
          )}

          {animal && (
            <>
              <DrawerRow>
                <DrawerCol>
                  <Form.InputText
                    label="Nome"
                    name="name"
                    placeholder="Inserir nome do animal"
                    defaultValue={animal?.header.name?.toString()}
                  />
                </DrawerCol>
                <DrawerCol>
                  <Form.InputText
                    label="Série"
                    name="series"
                    placeholder="Inserir série do animal"
                    defaultValue={animal?.additional_information.series?.toString()}
                  />
                </DrawerCol>
                <DrawerCol>
                  <Form.Select
                    defaultValue={ANIMAL_SEX_OPTIONS.find(
                      option => option.value === animal.header.sex
                    )}
                    name="sex"
                    options={ANIMAL_SEX_OPTIONS}
                    label="Sexo"
                  />
                </DrawerCol>
              </DrawerRow>
              <DrawerRow>
                <DrawerCol>
                  <Form.Select
                    label="Pelagem"
                    name="cowhide_color"
                    options={COWHIDE_COLORS}
                    defaultValue={COWHIDE_COLORS.find(
                      e =>
                        e.label.toLowerCase() ===
                        animal.basic_information.cowhide_color?.toLowerCase()
                    )}
                  />
                </DrawerCol>
                <DrawerCol>
                  <Form.InputText
                    label="Nº de Plantel"
                    name="stock_number"
                    placeholder="Inserir Nº de plantel"
                    defaultValue={animal?.header.stock_number?.toString()}
                  />
                </DrawerCol>
              </DrawerRow>

              {isAdmin() && (
                <>
                  <DrawerRow>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.imported_info
                            .imported_paternal_great_grandfather ||
                            animal.genealogy.father_info?.great_grandfather
                        )}
                        label="Bisavô paterno"
                        name="imported_paternal_great_grandfather"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info
                            .imported_paternal_great_grandfather ||
                            animal.genealogy.father_info?.great_grandfather,
                          animal.genealogy.father_info?.great_grandfather?.type
                        )}
                      />
                    </DrawerCol>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.father_info?.grandfather ||
                            animal.genealogy.imported_info
                              .imported_paternal_grandfather
                        )}
                        label="Avô paterno"
                        name="imported_paternal_grandfather"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info
                            .imported_paternal_grandfather ||
                            animal.genealogy.father_info?.grandfather,
                          animal.genealogy.father_info?.grandfather?.type
                        )}
                      />
                    </DrawerCol>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.father_info ||
                            animal.genealogy.imported_info.imported_father
                        )}
                        label="Pai"
                        name="imported_father"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info.imported_father ||
                            animal.genealogy.father_info,
                          animal.genealogy.father_info?.type
                        )}
                      />
                    </DrawerCol>
                  </DrawerRow>

                  <DrawerRow>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.mother_info?.great_grandfather ||
                            animal.genealogy.imported_info
                              .imported_maternal_great_grandfather
                        )}
                        label="Bisavô materno"
                        name="imported_maternal_great_grandfather"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info
                            .imported_maternal_great_grandfather ||
                            animal.genealogy.mother_info?.great_grandfather,
                          animal.genealogy.mother_info?.great_grandfather?.type
                        )}
                      />
                    </DrawerCol>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.mother_info?.grandfather ||
                            animal.genealogy.imported_info
                              .imported_maternal_grandfather
                        )}
                        label="Avô materno"
                        name="imported_maternal_grandfather"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info
                            .imported_maternal_grandfather ||
                            animal.genealogy.mother_info?.grandfather,
                          animal.genealogy.mother_info?.grandfather?.type
                        )}
                      />
                    </DrawerCol>
                    <DrawerCol>
                      <Form.InputText
                        disabled={checkInputDisabled(
                          animal.genealogy.mother_info ||
                            animal.genealogy.imported_info.imported_mother
                        )}
                        label="Mãe"
                        name="imported_mother"
                        placeholder="Inserir nome"
                        defaultValue={defaultInputValue(
                          animal.genealogy.imported_info.imported_mother ||
                            animal.genealogy.mother_info,
                          animal.genealogy.mother_info?.type
                        )}
                      />
                    </DrawerCol>
                  </DrawerRow>
                </>
              )}

              <DrawerRow singleItem>
                <Form.Textarea
                  name="description"
                  label="Observações"
                  maxLength={300}
                />
              </DrawerRow>
            </>
          )}

          {animal?.header.sex === AnimalSexLabel.male && defaultYearlingBull && (
            <DrawerRow>
              <DrawerCol>
                <Form.RadioButton
                  title="Animal é rufião?"
                  name="is_yearling"
                  options={IS_YEARLING_BULL}
                  defaultChecked={IS_YEARLING_BULL.find(
                    e => e.value === defaultYearlingBull
                  )}
                />
              </DrawerCol>
            </DrawerRow>
          )}

          <DrawerButtonContainer>
            <Button
              type={ButtonType.primary}
              label="Salvar"
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>

          <CustomModal
            modalIsOpen={isModalOpen}
            handleCloseModal={handleToggleModal}
            modalHeader="Deseja realmente reverter a baixa desse animal?"
            primaryButtonLabel="Sim"
            primaryButtonAction={handleRevertShutdown}
            secondaryButtonLabel="Não"
            secondaryButtonAction={handleToggleModal}
          />
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={deleteElectronicEartag}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Brinco Eletrônico`}
        modalText={`Você tem certeza que deseja excluir o Brinco Eletrônico do animal?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { AnimalRecord }
