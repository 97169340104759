import React, { Dispatch, SetStateAction } from 'react'

import { debounce } from 'lodash-es'

import { ReproductionLayoutSection } from './components'
import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { ISelectButtonItem, Menu } from 'components/organisms'
import SelectButton from 'components/organisms/select-button'

import { NavigateList } from 'app/core/routes/routes'
import { DefaultLinkProps } from 'app/core/types/breeding'
import { InseminationTypes } from 'app/core/types/hormonal'

import styles from './styles.module.scss'

export interface IReproductionLayoutProps {
  id?: string
  title: string
  initialDate?: string
  setInitialDate?: Dispatch<SetStateAction<string>>
  finalDate?: string
  setFinalDate?: Dispatch<SetStateAction<string>>
  isLoadingDates?: boolean
  inseminationType?: InseminationTypes
  breedingStationId?: number
  breedingStationName?: string
  hormonalProtocolId?: number
  hormonalProtocolName?: string
  iatfScheduleId?: number | null
  iatfScheduleName?: string | null
  breedingGroups?: DefaultLinkProps[]
  responsible?: string | JSX.Element
  diagnosticDate?: string | JSX.Element
  backButton?: boolean
  headerButtonLabel?: string
  headerButtonIcon?: JSX.Element
  headerButtonAction?: () => void
  headerLargeButtonLabel?: string
  headerLargeButtonIcon?: JSX.Element
  headerLargeButtonIconPosition?: ButtonIconPosition
  headerLargeButtonAction?: () => void
  headerLargeButtonLoading?: boolean
  headerSelectButtonLabel?: string
  headerSelectButtonItems?: ISelectButtonItem[]
  headerSelectButtonIcon?: JSX.Element
  headerSelectButtonIconPosition?: ButtonIconPosition
  headerSelectButtonLoading?: boolean
}

const ReproductionLayout: React.FC<IReproductionLayoutProps> = ({
  id,
  title,
  initialDate,
  setInitialDate,
  finalDate,
  setFinalDate,
  isLoadingDates,
  inseminationType,
  breedingStationId,
  breedingStationName,
  hormonalProtocolId,
  hormonalProtocolName,
  iatfScheduleId,
  iatfScheduleName,
  breedingGroups,
  children,
  responsible,
  diagnosticDate,
  backButton,
  headerButtonAction,
  headerButtonIcon,
  headerButtonLabel,
  headerLargeButtonLabel,
  headerLargeButtonIcon,
  headerLargeButtonIconPosition,
  headerLargeButtonAction,
  headerLargeButtonLoading,
  headerSelectButtonLabel,
  headerSelectButtonItems,
  headerSelectButtonIcon,
  headerSelectButtonIconPosition,
  headerSelectButtonLoading,
}) => {
  return (
    <div className={styles.page}>
      <Menu />

      <main className={styles.main}>
        <header className={styles.headerContainer}>
          <div className={styles.header}>
            <div className={styles.headerInfoWrapper}>
              <span className={styles.headerMain}>
                <Typography
                  text={title}
                  variant={TypographyVariant.h4}
                  className={styles.headerText}
                />

                {id && (
                  <span className={styles.headerId}>
                    <Typography
                      text={id}
                      variant={TypographyVariant.caption2}
                    />
                  </span>
                )}
              </span>

              <div className={styles.headerInfoColumns}>
                {initialDate && finalDate && (
                  <ReproductionLayoutSection
                    title="Duração"
                    dateValues={[initialDate, finalDate]}
                    setInitialDate={setInitialDate}
                    setFinalDate={setFinalDate}
                    isLoadingDates={isLoadingDates}
                  />
                )}

                {inseminationType && (
                  <ReproductionLayoutSection
                    title="Tipo de cobertura/inseminação"
                    singleValue={inseminationType}
                  />
                )}

                {breedingStationName && breedingStationId && (
                  <ReproductionLayoutSection
                    linkUrl={`${NavigateList.breedingStationDetails}${breedingStationId}/`}
                    title="Estação de Monta"
                    singleValue={breedingStationName}
                  />
                )}

                {hormonalProtocolName && hormonalProtocolId && (
                  <ReproductionLayoutSection
                    linkUrl={`${NavigateList.hormonalProtocolDetails}${hormonalProtocolId}/`}
                    title="Protocolo Hormonal"
                    singleValue={hormonalProtocolName}
                  />
                )}

                {iatfScheduleName && iatfScheduleId && (
                  <ReproductionLayoutSection
                    linkUrl={`${NavigateList.iatfScheduleDetails}${iatfScheduleId}/`}
                    title="Programação IATF"
                    singleValue={iatfScheduleName}
                  />
                )}

                {breedingGroups?.length && (
                  <ReproductionLayoutSection
                    title="Grupos de Repasse"
                    multipleValues={breedingGroups}
                  />
                )}
                {diagnosticDate && (
                  <ReproductionLayoutSection
                    title="Data do diagnóstico"
                    singleValue={diagnosticDate}
                  />
                )}
                {responsible && (
                  <ReproductionLayoutSection
                    title="Responsável"
                    singleValue={responsible}
                  />
                )}
              </div>
            </div>

            <div className={styles.headerActionWrapper}>
              {backButton && (
                <Button
                  label="Voltar"
                  type={ButtonType.ghost}
                  onClick={(): void => history.back()}
                  icon={<Icon name={IconNames['chevron-back']} />}
                />
              )}

              {headerButtonAction && (
                <Button
                  label={headerButtonLabel}
                  type={ButtonType.ghost}
                  onClick={debounce(headerButtonAction, 300)}
                  icon={headerButtonIcon}
                  size={ButtonSize.small}
                />
              )}

              {headerSelectButtonItems && (
                <SelectButton
                  icon={headerSelectButtonIcon}
                  iconPosition={headerSelectButtonIconPosition}
                  label={headerSelectButtonLabel}
                  items={headerSelectButtonItems}
                  loading={headerSelectButtonLoading}
                />
              )}

              {headerLargeButtonAction && (
                <Button
                  label={headerLargeButtonLabel}
                  onClick={debounce(headerLargeButtonAction, 300)}
                  icon={headerLargeButtonIcon}
                  iconPosition={headerLargeButtonIconPosition}
                  loading={headerLargeButtonLoading}
                />
              )}
            </div>
          </div>
        </header>

        <section className={styles.contentWrapper}>{children}</section>
      </main>
    </div>
  )
}

export { ReproductionLayout }
