/* eslint-disable @typescript-eslint/naming-convention */
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  BreedingGroupAnimalResponseData,
  BreedingGroupCreateRequestFormatData,
  BreedingGroupFilterProps,
  BreedingGroupProps,
  BreedingGroupReadResponseData,
} from 'app/core/types/breeding'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/breeding_group/'

const getReadAllBreedingGroups = async (
  filters?: BreedingGroupFilterProps,
  page?: number,
  size?: number
): Promise<BreedingGroupReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingGroup = async (
  groupId: string | number
): Promise<BreedingGroupProps> => {
  const url = `${baseUrl}${groupId}/`

  const response = await http.get(url)
  return response.data
}

const getReadBreedingGroupFemales = async (
  groupId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/females/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getReadBreedingGroupMales = async (
  groupId: string | number,
  page: number,
  size?: number,
  filters?: Partial<AnimalFilterProps>
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/males/`

  const response = await http.get(url, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const postCreateBreedingGroup = async (
  request: BreedingGroupCreateRequestFormatData
): Promise<BreedingGroupProps> => {
  const response = await http.post(baseUrl, request)
  return response.data
}

const postAddGroupAnimal = async (
  groupId: string | number,
  animalsId: number[]
): Promise<Record<string, string>[]> => {
  const url = `${baseUrl}${groupId}/add_animal/`

  const response = await http.post(url, animalsId)
  return response.data
}

const postRemoveBreedingGroupAnimal = async (
  groupId: string | number,
  animalsId: number
): Promise<BreedingGroupAnimalResponseData> => {
  const url = `${baseUrl}${groupId}/remove_animal/`

  const response = await http.post(url, [animalsId])
  return response.data
}

const deleteBreedingGroup = async (
  breedingGroupId: string | number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${breedingGroupId}/`)

  return response.data
}

export {
  deleteBreedingGroup,
  getReadAllBreedingGroups,
  getReadBreedingGroup,
  getReadBreedingGroupFemales,
  getReadBreedingGroupMales,
  postAddGroupAnimal,
  postCreateBreedingGroup,
  postRemoveBreedingGroupAnimal,
}
