import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useCallback, useEffect, useRef, useState } from 'react'

import { useFilePicker } from 'use-file-picker'

import { useClassification, useImport } from 'app/core/hooks'
import { useNotifications } from 'app/core/hooks/notifications'
import { ImportEntity, ImportedParameterType } from 'app/core/types/system'

import { classificationsListColumns } from './tables'
import { ClassificationTabTemplate } from './template'

export type FormRef = {
  resetForm: () => void
}

const ClassificationTab: React.FC = () => {
  const ref = useRef<FormRef>(null)
  const HISTORY_ITEMS_VISIBLE = 5

  const { classifications, readAllClassifications, isLoading } =
    useClassification()
  const {
    activeImport,
    importClassification,
    handleImportFile,
    toggleModal: toggleImportModal,
    isModalOpen: isImportModalOpen,
    isLoading: isImportLoading,
    importedFiles,
    loadImportedParameters,
    downloadImportedParameterFile,
  } = useImport()
  const { createNotification } = useNotifications()

  const [globalFilter, setGlobalFilter] = useState('')
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false)
  const [historyCount, setHistoryCount] = useState(HISTORY_ITEMS_VISIBLE)

  const [openFileSelector] = useFilePicker({
    accept: '.xlsx',
    readAs: 'ArrayBuffer',
    onFilesSuccessfulySelected: file =>
      handleImportFile(file, ImportEntity.CLASSIFICATION),
  })

  const handleModalConfirm = useCallback(
    async (data: Record<string, unknown>) => {
      const { message } = data

      await importClassification()
      await readAllClassifications()

      if (message && message !== '') await createNotification(message as string)

      ref?.current?.resetForm()
    },
    [createNotification, importClassification, readAllClassifications]
  )

  const toggleHistoryModal = (): void => {
    setIsHistoryModalOpen(prevState => !prevState)
  }

  const handleShowMoreHistoryItems = (): void => {
    setHistoryCount(prevState => prevState + HISTORY_ITEMS_VISIBLE)
  }

  const table = useReactTable({
    data: classifications || [],
    columns: classificationsListColumns(),
    state: {
      globalFilter,
    },
    onGlobalFilterChange: setGlobalFilter,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  })

  const handleFileDownload = useCallback(
    async (fileName: string) => {
      downloadImportedParameterFile(fileName)
    },
    [downloadImportedParameterFile]
  )

  useEffect(() => {
    loadImportedParameters(ImportedParameterType.classification)
  }, [loadImportedParameters])

  return (
    <ClassificationTabTemplate
      table={table}
      setGlobalFilter={setGlobalFilter}
      handleModalConfirm={handleModalConfirm}
      handleShowMoreHistoryItems={handleShowMoreHistoryItems}
      historyCount={historyCount}
      openFileSelector={openFileSelector}
      activeImport={activeImport}
      toggleHistoryModal={toggleHistoryModal}
      toggleImportModal={toggleImportModal}
      isHistoryModalOpen={isHistoryModalOpen}
      isImportModalOpen={isImportModalOpen}
      isImportLoading={isImportLoading}
      isLoading={isLoading}
      parentRef={ref}
      importedFiles={importedFiles}
      onImportFileClick={handleFileDownload}
    />
  )
}

export { ClassificationTab }
