import { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { OSLayout } from 'components/templates'

import styles from './styles.module.scss'
import {
  ClassificationTab,
  CostCenterTab,
  ExecuterTab,
  FarmsTab,
  LocationTab,
  MachineryTab,
  ServiceTypesTab,
  ServicesTab,
} from './tabs'

export enum TabsTypes {
  services = 'services',
  serviceTypes = 'serviceTypes',
  location = 'location',
  farms = 'farms',
  executer = 'executer',
  machinery = 'machinery',
  classification = 'classification',
  costCenter = 'costCenter',
}

type TabButtonProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  onClick: (value: TabsTypes) => void
  testId?: string
}

const TabButton: React.FC<TabButtonProps> = ({
  activeTab,
  tabType,
  onClick,
  children,
  testId,
}) => (
  <li
    data-testid={testId}
    className={classNames(
      styles.tab,
      activeTab === tabType && styles.tabActive
    )}
    onClick={(): void => onClick(tabType)}
  >
    <span>{children}</span>
    <Icon name={IconNames['chevron-forward']} />
  </li>
)

type TabPageProps = {
  activeTab: TabsTypes
  tabType: TabsTypes
  children: React.ReactElement
}

const TabPage: React.FC<TabPageProps> = ({ activeTab, tabType, children }) =>
  activeTab === tabType ? children : null

const ParametersTemplate: React.FC = () => {
  const history = useHistory()
  const location = useLocation()

  const [activeTab, setActiveTab] = useState<TabsTypes>(TabsTypes.services)

  const handleTabClick = async (tab: TabsTypes): Promise<void> => {
    if (tab === activeTab) return
    setActiveTab(tab)
    history.push(`?tab=${tab}`)
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const tab = params.get('tab') as TabsTypes

    if (tab && Object.values(TabsTypes).includes(tab)) setActiveTab(tab)
  }, [location.search])

  return (
    <OSLayout>
      <Typography text="Parâmetros do Sistema" variant={TypographyVariant.h4} />
      <section className={styles.parameters}>
        <aside className={styles.parametersSidebar}>
          <Typography text="Parâmetros locais" variant={TypographyVariant.h5} />
          <nav>
            <ul className={styles.tabList}>
              <TabButton
                testId="servicesTab"
                activeTab={activeTab}
                tabType={TabsTypes.services}
                onClick={handleTabClick}
              >
                Serviços
              </TabButton>
              <TabButton
                testId="servicesTypeTab"
                activeTab={activeTab}
                tabType={TabsTypes.serviceTypes}
                onClick={handleTabClick}
              >
                Tipos de serviços
              </TabButton>
              <TabButton
                testId="locationTab"
                activeTab={activeTab}
                tabType={TabsTypes.location}
                onClick={handleTabClick}
              >
                Locais
              </TabButton>
              <TabButton
                testId="farmsTab"
                activeTab={activeTab}
                tabType={TabsTypes.farms}
                onClick={handleTabClick}
              >
                Fazendas
              </TabButton>
            </ul>
          </nav>

          <hr className={styles.parametersSidebarDivider} />

          <Typography
            text="Parâmetros importados do WK Radar Empresarial"
            variant={TypographyVariant.h5}
          />

          <nav>
            <ul className={styles.tabList}>
              <TabButton
                testId="executerTab"
                activeTab={activeTab}
                tabType={TabsTypes.executer}
                onClick={handleTabClick}
              >
                Executores
              </TabButton>
              <TabButton
                testId="machineryTab"
                activeTab={activeTab}
                tabType={TabsTypes.machinery}
                onClick={handleTabClick}
              >
                Maquinários
              </TabButton>
              <TabButton
                testId="classificationsTab"
                activeTab={activeTab}
                tabType={TabsTypes.classification}
                onClick={handleTabClick}
              >
                Classificações
              </TabButton>
              <TabButton
                testId="costCenterTab"
                activeTab={activeTab}
                tabType={TabsTypes.costCenter}
                onClick={handleTabClick}
              >
                Centro de custos
              </TabButton>
            </ul>
          </nav>
        </aside>
        <div className={styles.parametersList}>
          <TabPage activeTab={activeTab} tabType={TabsTypes.services}>
            <ServicesTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.serviceTypes}>
            <ServiceTypesTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.location}>
            <LocationTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.farms}>
            <FarmsTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.executer}>
            <ExecuterTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.machinery}>
            <MachineryTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.classification}>
            <ClassificationTab />
          </TabPage>
          <TabPage activeTab={activeTab} tabType={TabsTypes.costCenter}>
            <CostCenterTab />
          </TabPage>
        </div>
      </section>
    </OSLayout>
  )
}

export { ParametersTemplate }
