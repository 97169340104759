import { useCallback, useEffect, useState } from 'react'

import {
  getNotifications,
  postCreateNotification,
  postReadNotification,
} from 'app/core/services/notifications'
import { NotificationHook } from 'app/core/types/hooks'
import { NotificationProps } from 'app/core/types/notifications'
import { handleHttpError } from 'app/core/utils'

const useNotifications = (userId?: string): NotificationHook => {
  const [notifications, setNotifications] = useState<NotificationProps[]>([])

  const getAllNotifications = useCallback(async (): Promise<void> => {
    if (userId) {
      try {
        const data = await getNotifications()

        const filteredNotifications = data.filter(
          notification =>
            notification.user_id === Number(userId) && !notification.is_read
        )

        setNotifications(filteredNotifications)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [userId])

  const createNotification = useCallback(
    async (message: string): Promise<void> => {
      try {
        await postCreateNotification(message)
      } catch (e) {
        handleHttpError(e)
      }
    },
    []
  )

  const readNotification = useCallback(
    async (notificationId: number): Promise<void> => {
      if (notificationId) {
        try {
          await postReadNotification(notificationId)
          getAllNotifications()
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    [getAllNotifications]
  )

  useEffect(() => {
    getAllNotifications()
  }, [getAllNotifications])

  return {
    notifications,
    createNotification,
    readNotification,
  }
}

export { useNotifications }
