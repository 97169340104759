import { Table } from '@tanstack/react-table'
import {
  Dispatch,
  Fragment,
  SetStateAction,
  useEffect,
  useImperativeHandle,
} from 'react'

import classNames from 'classnames'
import * as yup from 'yup'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DrawerRow,
  SearchTable,
} from 'components/molecules'
import { CustomTable, useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'

import { useFarm } from 'app/core/hooks'
import { LocationFormStateProps, LocationProps } from 'app/core/types/system'
import { Messages } from 'config/messages'

import { FormRef, LocationDrawerSteps } from '.'
import * as templateStyles from '../../styles.module.scss'
import styles from './styles.module.scss'

type LocationTabTemplateProps = {
  locations: LocationProps[] | undefined
  table: Table<LocationProps>
  setGlobalFilter: Dispatch<SetStateAction<string>>
  isDrawerOpen: boolean
  isDeleteModalOpen: boolean
  isEditModalOpen: boolean
  drawerStep: LocationDrawerSteps
  handleToggleDrawer: () => void
  handleDrawerBack: () => void
  handleDeleteModal: () => void
  handleDeleteConfirm: () => Promise<void>
  handleEditModal: () => void
  handleEditFormSubmit: (data: Record<string, unknown>) => Promise<void>
  formState: LocationFormStateProps
  onSubmit: IFormProps['onSubmit']
  parentRef: React.RefObject<FormRef>
  selectedLocation?: LocationProps
  isLoading: boolean
}

const LocationTabTemplate: React.FC<LocationTabTemplateProps> = ({
  locations,
  table,
  setGlobalFilter,
  isDrawerOpen,
  isEditModalOpen,
  isDeleteModalOpen,
  drawerStep,
  handleToggleDrawer,
  handleDrawerBack,
  handleDeleteModal,
  handleDeleteConfirm,
  handleEditModal,
  handleEditFormSubmit,
  formState,
  onSubmit,
  parentRef,
  selectedLocation,
  isLoading,
}) => {
  const { activeFarms: farms, activeFarmsDroplist } = useFarm()

  const showFields =
    drawerStep === LocationDrawerSteps.CREATE ||
    drawerStep === LocationDrawerSteps.ALERT

  const validationSchema = yup.object({
    location: yup.string().required(Messages.YUP_REQUIRED_FIELD),
    farms: yup
      .array()
      .of(
        yup.object({
          value: yup.string(),
        })
      )
      .min(1, Messages.YUP_REQUIRED_FIELD)
      .required(Messages.YUP_REQUIRED_FIELD),
  })

  useImperativeHandle(parentRef, () => ({
    resetForm(): void {
      reset()
    },
    resetEditForm(): void {
      resetEdit()
    },
    setValue(name: string, value: unknown): void {
      setValueEdit(name, value)
    },
  }))

  const { Form, setValue, reset } = useForm({
    onSubmit,
    validationSchema,
  })

  const editValidationSchema = yup.object({
    name: yup.string().required(Messages.YUP_REQUIRED_FIELD),
  })

  const {
    Form: EditForm,
    handleSubmit,
    setValue: setValueEdit,
    reset: resetEdit,
  } = useForm({
    onSubmit: handleEditFormSubmit,
    validationSchema: editValidationSchema,
  })

  useEffect(() => {
    const formIsEmpty = Object.keys(formState).length === 0

    if (activeFarmsDroplist && formIsEmpty)
      setValue('farms', activeFarmsDroplist)
  }, [activeFarmsDroplist, formState, setValue])

  if (!locations)
    return (
      <Typography text={Messages.EMPTY_DATA} variant={TypographyVariant.p} />
    )

  return (
    <>
      <header className={templateStyles.default.parametersHeader}>
        <SearchTable setGlobalFilter={setGlobalFilter} />

        <div className={templateStyles.default.parametersActions}>
          <Button
            testId="createLocationButton"
            label="Criar novo local"
            onClick={handleToggleDrawer}
            type={ButtonType.ghost}
          />
        </div>
      </header>

      <CustomTable table={table} isLoading={isLoading} disableNavigation />

      <CustomDrawer
        title="Criar novo local"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
      >
        <Form>
          {showFields && (
            <>
              <DrawerRow singleItem>
                <Form.InputText
                  label="Novo local"
                  name="location"
                  placeholder="Escreva o nome do novo local aqui"
                />
              </DrawerRow>

              <DrawerRow singleItem>
                <Form.Select
                  label="Fazendas"
                  name="farms"
                  options={activeFarmsDroplist}
                  isMulti
                />
              </DrawerRow>
            </>
          )}

          {drawerStep === LocationDrawerSteps.ALERT && (
            <DrawerRow singleItem>
              <div
                className={templateStyles.default.servicesAlert}
                data-teste-id="servicesAlert"
              >
                <Icon name={IconNames['information']} />
                <Typography
                  text="Antes de continuar confira os locais cadastrados abaixo e garanta que este local já não está contemplado."
                  variant={TypographyVariant.p}
                />
              </div>
              <div className={templateStyles.default.servicesCard}>
                {farms?.map(farm => (
                  <Fragment key={farm.id}>
                    <Typography
                      text={farm.name}
                      variant={TypographyVariant.p}
                    />
                    <ul>
                      {locations
                        .filter(location => location.farm_id === farm.id)
                        .map(location => (
                          <li key={location.id}>{location.name}</li>
                        ))}
                    </ul>
                  </Fragment>
                ))}
              </div>
            </DrawerRow>
          )}

          {drawerStep === LocationDrawerSteps.REVISION && (
            <div
              className={classNames(
                templateStyles.default.servicesCard,
                templateStyles.default.servicesCardResume
              )}
            >
              <Typography
                text="Nome do novo local a ser criado:"
                variant={TypographyVariant.p}
              />
              <Typography
                text={formState.location}
                variant={TypographyVariant.h4}
              />
              <Typography text="Fazendas:" variant={TypographyVariant.p} />
              {formState.farms.map(farm => (
                <Typography
                  key={farm.value}
                  text={farm.label}
                  variant={TypographyVariant.h5}
                />
              ))}
            </div>
          )}

          <DrawerRow className={templateStyles.default.formActions} singleItem>
            <Button
              testId="backDrawerButton"
              onClick={handleDrawerBack}
              htmlType="button"
              type={ButtonType.ghost}
              label={
                drawerStep === LocationDrawerSteps.CREATE
                  ? 'Cancelar'
                  : 'Voltar'
              }
              size={ButtonSize.medium}
              icon={<Icon name={IconNames['chevron-back']} />}
            />

            <Button
              testId="advanceDrawerButton"
              type={ButtonType.primary}
              label={
                drawerStep === LocationDrawerSteps.REVISION
                  ? 'Criar local'
                  : 'Continuar'
              }
              size={ButtonSize.medium}
            />
          </DrawerRow>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!isEditModalOpen}
        handleCloseModal={handleEditModal}
        modalHeader="Editar local"
        primaryButtonLabel="SALVAR"
        primaryButtonAction={handleSubmit(handleEditFormSubmit)}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleEditModal}
      >
        <div className={styles.editForm}>
          <EditForm>
            <EditForm.InputText
              name="name"
              placeholder="Nome do local"
              label="Nome do local"
            />
            <EditForm.Select
              label="Fazenda"
              name="farm_id"
              options={activeFarmsDroplist}
              defaultValue={activeFarmsDroplist?.find(
                farm => farm.value === String(selectedLocation?.farm_id)
              )}
            />
          </EditForm>
        </div>
      </CustomModal>

      <CustomModal
        modalIsOpen={isDeleteModalOpen}
        handleCloseModal={handleDeleteModal}
        modalHeader={`Excluir local`}
        modalText={`Você tem certeza que deseja excluir o local ${selectedLocation?.name}?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModal}
      />
    </>
  )
}

export { LocationTabTemplate }
