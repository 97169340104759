import React, { useCallback, useState } from 'react'

import { useBreedingGroup } from 'app/core/hooks'
import { BreedingGroupFilterProps } from 'app/core/types/breeding'

import { BreedingGroupHistoryTemplate } from './template'

const BreedingGroupHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [filters, setFilters] = useState<BreedingGroupFilterProps>()
  const [page, setPage] = useState<number>(1)
  const [deleteBreedingGroupId, setDeleteBreedingGroupId] = useState<number>()

  const femaleToMaleProportion = 20

  const { groups, deleteGroup, isLoading } = useBreedingGroup({ filters, page })

  const handleToggleDrawer = (): void => {
    setIsDrawerOpen(!isDrawerOpen)
  }

  const handleFilterGroups = async (data: unknown): Promise<void> => {
    const dataCopy = data as BreedingGroupFilterProps

    setPage(1)
    setFilters(dataCopy)
    handleToggleDrawer()
  }

  const handleDeleteModalClose = (): void => {
    setDeleteBreedingGroupId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteBreedingGroupId) {
      await deleteGroup(deleteBreedingGroupId)

      handleDeleteModalClose()
    }
  }, [deleteBreedingGroupId, deleteGroup])

  return (
    <BreedingGroupHistoryTemplate
      groups={groups}
      currentPage={page}
      setCurrentPage={setPage}
      setFilters={setFilters}
      handleToggleDrawer={handleToggleDrawer}
      femaleToMaleProportion={femaleToMaleProportion}
      isDrawerOpen={isDrawerOpen}
      onSubmit={handleFilterGroups}
      isLoading={isLoading}
      deleteBreedingGroupId={deleteBreedingGroupId}
      setDeleteBreedingGroupId={setDeleteBreedingGroupId}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
    />
  )
}

export { BreedingGroupHistory }
