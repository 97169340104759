import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table'
import { useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { IconNames } from 'components/atoms'
import { ISelectButtonItem } from 'components/organisms'

import { useCollectiveMovements } from 'app/core/hooks/collective-movements'
import { NavigateList } from 'app/core/routes/routes'
import { CollectiveMovementsCreateProps } from 'app/core/types/collective-movements'

import { collectiveMovementsListColumns } from '../tables'
import { CollectiveMovementsFeedbackTemplate } from './template'

const CollectiveMovementsFeedback: React.FC = () => {
  const history = useHistory<NavigateList>()
  const [page, setPage] = useState<number>(1)

  const {
    animals,
    collectiveMovement,
    exportCollectiveMovement,
    removeStorageCollectiveMovement,
    isLoading,
    failedMovements,
    exportFailedMovements,
  } = useCollectiveMovements({
    page,
  })

  const handleFinishCollectiveMovement = async (): Promise<void> => {
    removeStorageCollectiveMovement()
    history.push(NavigateList.animalList)
  }

  const handleExport = async (): Promise<void> => {
    await exportCollectiveMovement()
  }

  const columns = useMemo<ColumnDef<CollectiveMovementsCreateProps>[]>(
    () =>
      collectiveMovementsListColumns({
        originFarm: collectiveMovement?.originFarm?.label,
        destinationFarm: collectiveMovement?.destinationFarm?.label,
        destinationSector: collectiveMovement?.destinationSector?.label,
      }),
    [collectiveMovement]
  )

  const table = useReactTable({
    data: (animals?.items as CollectiveMovementsCreateProps[]) || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  })

  const selectButtonItems: ISelectButtonItem[] = [
    ...(failedMovements.length > 0
      ? ([
          {
            name: 'Planilha de inconsistências',
            icon: IconNames['alert'],
            action: (): void => {
              exportFailedMovements()
            },
          },
        ] as ISelectButtonItem[])
      : []),
    ...(animals && animals?.items.length > 0
      ? ([
          {
            name: 'Exportar movimentações',
            icon: IconNames.download,
            action: handleExport,
          },
        ] as ISelectButtonItem[])
      : []),
  ]

  return (
    <CollectiveMovementsFeedbackTemplate
      animals={animals}
      collectiveMovement={collectiveMovement}
      table={table}
      isLoading={isLoading}
      page={page}
      setPage={setPage}
      handleFinishCollectiveMovement={handleFinishCollectiveMovement}
      headerSelectButtonItems={selectButtonItems}
    />
  )
}

export { CollectiveMovementsFeedback }
