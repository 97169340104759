import { AnimalCreateProps } from 'app/core/types/animal'
import { ABORTION_TYPES, BirthCreateProps } from 'app/core/types/birth'
import { Diagnostics } from 'app/core/types/breeding'
import { HandledAnimalProps } from 'app/core/types/handling'
import { ShutdownCreateProps } from 'app/core/types/shutdown'

const validateEmptyFields = <T extends Record<string, unknown>>(
  items: T[],
  requiredFields: string[]
): number[] | undefined => {
  const invalidAnimals = items.filter(obj => {
    const props = Object.keys(obj)

    const emptyFields = requiredFields.filter(requiredField => {
      const value = obj[requiredField]
      return !props.includes(requiredField) || !value
    })

    return emptyFields.length > 0
  })

  const invalidAnimalsIds = invalidAnimals.map(
    animals => animals.id
  ) as number[]
  return invalidAnimalsIds || undefined
}

const animalsEmptyFields = (
  animals: AnimalCreateProps[],
  requiredFields: string[]
): (number | undefined)[] => {
  const invalidAnimals = animals.filter(obj => {
    const props = Object.keys(obj)

    const isMixedBreed =
      obj.breed_first_percentage &&
      parseFloat(obj.breed_first_percentage) !== 100

    if (!obj.is_composite_breed) {
      requiredFields.push('breed_first_name', 'breed_first_percentage')
    }

    if (!obj.is_composite_breed && isMixedBreed) {
      requiredFields.push('breed_second_name', 'breed_second_percentage')
    }

    const emptyFields = requiredFields.filter(requiredField => {
      const value = obj[requiredField]
      return !props.includes(requiredField) || !value
    })

    return emptyFields.length > 0
  })

  const invalidAnimalsIds = invalidAnimals.map(animals => animals.id)
  return invalidAnimalsIds
}

const handledAnimalsEmptyFields = (
  handlings: HandledAnimalProps[],
  requiredFields: string[]
): (number | undefined)[] => {
  const invalidAnimals = handlings.filter(obj => {
    const props = Object.keys(obj)

    const products = obj['product'] as Record<string, string | null>
    const productsQuantities = Object.values(products)
    const hasInvalidProductQuantities =
      productsQuantities.length &&
      productsQuantities.some(quantity => !quantity)

    const emptyFields = requiredFields.filter(requiredField => {
      const value = obj[requiredField]
      return !props.includes(requiredField) || !value
    })

    return emptyFields.length > 0 || hasInvalidProductQuantities
  })

  const invalidAnimalsIds = invalidAnimals.map(handlings => handlings.id)
  return invalidAnimalsIds
}

const shutdownEmptyFields = (
  animals: ShutdownCreateProps[],
  requiredFields: string[]
): (number | undefined)[] => {
  const invalidAnimals = animals.filter(obj => {
    const props = Object.keys(obj)

    const emptyFields = requiredFields.filter(requiredField => {
      const value = obj[requiredField]
      return !props.includes(requiredField) || !value || value == 0
    })

    return emptyFields.length > 0
  })

  const invalidAnimalsIds = invalidAnimals.map(animals => animals.id)
  return invalidAnimalsIds
}

const birthAnimalsEmptyFields = (
  animals: BirthCreateProps[],
  requiredFields: string[]
): (number | undefined)[] => {
  const invalidAnimals = animals.filter(obj => {
    const props = Object.keys(obj)

    if (obj.type && ABORTION_TYPES.includes(obj.type)) {
      requiredFields = ['date', 'type']
    }

    const emptyFields = requiredFields.filter(requiredField => {
      const value = obj[requiredField]

      return !props.includes(requiredField) || !value
    })

    return emptyFields.length > 0
  })

  const invalidAnimalsIds = invalidAnimals.map(animal => animal.id)
  return invalidAnimalsIds
}

const diagnosticsAnimalsEmptyFields = (
  animals: Diagnostics,
  requiredFields: string[]
): (number | undefined)[] => {
  const invalidAnimals = animals.filter(obj => {
    const props = Object.keys(obj)

    const emptyFields = requiredFields.filter(requiredField => {
      if (!obj.status) {
        return false
      }

      const value = obj[requiredField]

      return !props.includes(requiredField) || !value
    })

    return emptyFields.length > 0
  })

  const invalidAnimalsIds = invalidAnimals.map(animal => animal.id)
  return invalidAnimalsIds
}

const clearEmptyFields = (
  values: Record<string, unknown>
): Record<string, unknown> => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (['', null, undefined].includes(value as string)) {
      return acc
    }
    return { ...acc, [key]: value }
  }, {})
}

export {
  animalsEmptyFields,
  birthAnimalsEmptyFields,
  clearEmptyFields,
  diagnosticsAnimalsEmptyFields,
  handledAnimalsEmptyFields,
  shutdownEmptyFields,
  validateEmptyFields,
}
