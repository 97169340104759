import { useState } from 'react'

import classNames from 'classnames'

import {
  Button,
  ButtonIconPosition,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'

import { isAdmin } from 'app/core/auth'

import styles from './styles.module.scss'

export interface ISelectButtonItem {
  name: string
  action: () => void
  icon?: IconNames
  onlyAdmin?: boolean
}

export interface ISelectButtonProps {
  items: ISelectButtonItem[]
  iconPosition?: ButtonIconPosition
  icon?: React.ReactElement | React.ReactNode
  label?: string
  className?: string
  loading?: boolean
}

const SelectButton = ({
  items,
  icon,
  iconPosition = ButtonIconPosition.right,
  label,
  className,
  loading,
}: ISelectButtonProps): JSX.Element => {
  const [tooltipVisible, setTooltipVisible] = useState(false)

  const handleButtonClick = (): void => {
    setTooltipVisible(!tooltipVisible)
  }

  return (
    <div className={classNames(styles.selectButton, className)}>
      <Button
        label={label}
        type={ButtonType.ghost}
        onClick={handleButtonClick}
        iconPosition={iconPosition}
        icon={icon}
        size={ButtonSize.small}
        loading={loading}
      />
      {tooltipVisible && (
        <ul className={styles.selectButtonItems}>
          {items
            .filter(item => (item.onlyAdmin ? isAdmin() : true))
            .map((item, i) => (
              <li
                key={i}
                onClick={(): void => {
                  handleButtonClick()
                  item.action()
                }}
              >
                <Icon name={item.icon} />
                {item.name}
              </li>
            ))}
        </ul>
      )}
    </div>
  )
}

export default SelectButton
