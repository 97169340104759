import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import * as yup from 'yup'

import { IRadioButtonOption } from 'components/atoms'

import { ServiceOrderProps } from 'app/core/types/service-order'
import { ServiceType } from 'app/core/types/system'
import { parseDateToISO } from 'app/core/utils'
import { Messages } from 'config/messages'

enum FormatTypes {
  currentDay = 'E',
  day = "d LLLL', 'E",
  week = 'd LLLL',
  month = 'LLLL',
}

const formatServiceOrder = (
  serviceOrder: Record<string, unknown>
): Partial<ServiceOrderProps> => {
  const {
    farm_id,
    bulletin_number,
    date,
    service_id,
    service_type,
    executor_id,
    location_id,
    classification_id,
    cost_center_id,
    duration,
    is_machinery_used,
    machinery_id,
    initial_mileage,
    final_mileage,
    odometer,
    observation,
  } = serviceOrder

  const formattedOrder: Partial<ServiceOrderProps> = {
    ...(farm_id !== undefined && { farm_id: Number(farm_id) }),
    ...(bulletin_number !== undefined && {
      bulletin_number: Number(bulletin_number),
    }),
    ...(date !== undefined && { date: parseDateToISO(date as string) }),
    ...(service_id !== undefined && { service_id: Number(service_id) }),
    ...(service_type !== undefined && {
      service_type: service_type as ServiceType,
    }),
    ...(executor_id !== undefined && { executor_id: Number(executor_id) }),
    ...(location_id !== undefined && { location_id: Number(location_id) }),
    ...(classification_id !== undefined && {
      classification_id: Number(classification_id),
    }),
    ...(cost_center_id !== undefined && {
      cost_center_id: Number(cost_center_id),
    }),
    ...(duration !== undefined && {
      duration: ((): number | undefined => {
        const [hours, minutes] = (duration as string).split(':').map(Number)
        return hours * 60 + minutes
      })(),
    }),
    ...(is_machinery_used !== undefined && {
      is_machinery_used: is_machinery_used === 'yes',
    }),
    ...(machinery_id !== undefined && { machinery_id: Number(machinery_id) }),
    ...(initial_mileage !== undefined && {
      initial_mileage: Number(initial_mileage),
    }),
    ...(final_mileage !== undefined && {
      final_mileage: Number(final_mileage),
    }),
    ...(odometer !== undefined && { odometer: Number(odometer) }),
    observation: observation as string,
  }

  return formattedOrder
}

const formatExecutionDates = (dates: Date[]): IRadioButtonOption[] =>
  dates
    .map(dateOption => {
      return {
        label: format(dateOption, FormatTypes.week, { locale: ptBR }),
        subLabel: format(dateOption, FormatTypes.currentDay, {
          locale: ptBR,
        }),
        value: dateOption.toISOString(),
      }
    })
    .reverse()

const validationSchema = yup.object({
  bulletin_number: yup
    .number()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  date: yup
    .string()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  executor_id: yup
    .object()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  farm_id: yup.object().required(Messages.YUP_REQUIRED_FIELD),
  location_id: yup
    .object()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  service_id: yup
    .object()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  is_machinery_used: yup
    .string()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  classification_id: yup
    .object()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  cost_center_id: yup
    .object()
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  observation: yup
    .string()
    .optional()
    .nullable()
    .typeError(Messages.YUP_REQUIRED_FIELD),
  duration: yup
    .string()
    .test({
      message: Messages.YUP_REQUIRED_FIELD,
      test: function (value: string | undefined) {
        return value !== '00:00'
      },
    })
    .required(Messages.YUP_REQUIRED_FIELD)
    .typeError(Messages.YUP_REQUIRED_FIELD),
  machinery_id: yup
    .object()
    .optional()
    .nullable()
    .when('is_machinery_used', {
      is: 'yes',
      then: yup
        .object()
        .required(Messages.YUP_REQUIRED_FIELD)
        .typeError(Messages.YUP_REQUIRED_FIELD),
    }),
  initial_mileage: yup
    .number()
    .optional()
    .nullable()
    .when('is_machinery_used', {
      is: 'yes',
      then: yup
        .number()
        .required(Messages.YUP_REQUIRED_FIELD)
        .typeError(Messages.YUP_REQUIRED_FIELD),
    }),
  final_mileage: yup
    .number()
    .optional()
    .nullable()
    .when('is_machinery_used', {
      is: 'yes',
      then: yup
        .number()
        .required(Messages.YUP_REQUIRED_FIELD)
        .typeError(Messages.YUP_REQUIRED_FIELD),
    }),
})

export { formatExecutionDates, formatServiceOrder, validationSchema }
