import React, { Dispatch, SetStateAction } from 'react'
import { useHistory } from 'react-router-dom'

import {
  Button,
  ButtonSize,
  ButtonType,
  Icon,
  IconNames,
} from 'components/atoms'
import {
  CustomDrawer,
  CustomModal,
  DataCounter,
  DrawerButtonContainer,
  DrawerCol,
  DrawerRow,
  ExpandCard,
  withLoading,
} from 'components/molecules'
import { useForm } from 'components/organisms'
import { IFormProps } from 'components/organisms/form/types'
import { BoxedLayout } from 'components/templates'

import { isAdmin } from 'app/core/auth'
import { NavigateList } from 'app/core/routes/routes'
import {
  BreedingGroupFilterProps,
  BreedingGroupReadResponseData,
} from 'app/core/types/breeding'
import { breedingGroupIdFormat } from 'app/core/utils'

import styles from './styles.module.scss'

type BreedingGroupHistoryProps = {
  groups?: BreedingGroupReadResponseData
  currentPage?: number
  setCurrentPage: Dispatch<SetStateAction<number>>
  setFilters: Dispatch<SetStateAction<BreedingGroupFilterProps | undefined>>
  femaleToMaleProportion: number
  isDrawerOpen: boolean
  handleToggleDrawer: () => void
  onSubmit: IFormProps['onSubmit']
  isLoading: boolean
  handleDeleteModalClose: () => void
  handleDeleteModalConfirm: () => Promise<void>
  deleteBreedingGroupId: number | undefined
  setDeleteBreedingGroupId: React.Dispatch<
    React.SetStateAction<number | undefined>
  >
}

const BreedingGroupHistoryTemplate: React.FC<BreedingGroupHistoryProps> = ({
  groups,
  currentPage,
  setCurrentPage,
  setFilters,
  femaleToMaleProportion,
  isDrawerOpen,
  handleToggleDrawer,
  onSubmit,
  isLoading,
  handleDeleteModalClose,
  handleDeleteModalConfirm,
  deleteBreedingGroupId,
  setDeleteBreedingGroupId,
}) => {
  const history = useHistory<NavigateList>()

  const animalProportionHint = (males: number): string =>
    `A proporção entre fêmeas e machos é de ${femaleToMaleProportion}:1.\nÉ necessário adicionar um total de ${
      femaleToMaleProportion * males
    } fêmeas ou remover machos para corrigir.`

  const { Form, formState, reset } = useForm({ onSubmit })

  const activeFilters = Object.entries(formState.touchedFields).length

  const resetFilters = (): void => {
    reset()
    setFilters(undefined)
  }

  return (
    <>
      <BoxedLayout
        title="Grupos de Repasse"
        headerLargeButtonIcon={<Icon name={IconNames['add-circle']} />}
        headerLargeButtonLabel="Criar"
        headerLargeButtonAction={(): void =>
          history.push(NavigateList.breedingGroupCreate)
        }
        currentPage={currentPage}
        totalPages={groups?.pages}
        setCurrentPage={setCurrentPage}
      >
        {withLoading(
          <>
            <DataCounter
              counter={groups?.total}
              filterButtonAction={handleToggleDrawer}
              filterButtonActiveFilters={activeFilters}
            />

            <div className={styles.groupsContainer}>
              {groups?.items.map((group, i) => (
                <ExpandCard
                  id={breedingGroupIdFormat(group.id)}
                  expandable={false}
                  title={group.name}
                  initDate={group.init_date}
                  endDate={group.final_date}
                  animalsMale={group.males}
                  animalsFemale={group.females}
                  animalsProportion={
                    group.females / group.males === femaleToMaleProportion
                  }
                  animalsProportionHint={animalProportionHint(group.males)}
                  breedingStation={group.breeding_station_name}
                  isActive={group.is_active}
                  navigation={(): void =>
                    history.push(
                      `${NavigateList.breedingGroupDetails}${group.id}`
                    )
                  }
                  key={`group-${i}`}
                  {...(isAdmin() && {
                    secondaryButtonAction: (): void =>
                      setDeleteBreedingGroupId(group.id),
                    secondaryButtonLabel: 'Excluir',
                    secondaryButtonIcon: IconNames.close,
                  })}
                />
              ))}
            </div>
          </>
        )(isLoading)}
      </BoxedLayout>

      <CustomDrawer
        title="Filtrar"
        isOpen={isDrawerOpen}
        onClose={handleToggleDrawer}
        reset={resetFilters}
      >
        <Form>
          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nº de Brinco Eletrônico"
                name="electronic_eartag"
                placeholder="Pesquisar nº de brinco eletrônico"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Nascimento"
                name="birth_number"
                placeholder="Pesquisar nº de nascimento"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Nº de Plantel"
                name="stock_number"
                placeholder="Inserir número"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerRow>
            <DrawerCol>
              <Form.InputText
                label="Nome do Animal"
                name="animal_name"
                placeholder="Pesquisar nome do animal"
              />
            </DrawerCol>
            <DrawerCol>
              <Form.InputText
                label="Período de Nascimento"
                name="init_date"
                htmlType="date"
              />

              <Form.InputText
                emptyLabelSpacing
                name="final_date"
                htmlType="date"
              />
            </DrawerCol>
            <Form.InputText
              label="ID Grupo de Repasse"
              name="breeding_group_id"
              placeholder="Pesquisar nº ID do Grupo de Repasse"
              htmlType="number"
              min={1}
            />
            <DrawerCol>
              <Form.InputText
                label="Nome do Grupo de Repasse"
                name="breeding_group_name"
                placeholder="Pesquisar nome do Grupo de Repasse"
              />
            </DrawerCol>
          </DrawerRow>

          <DrawerButtonContainer>
            <Button
              label="Aplicar"
              type={ButtonType.primary}
              size={ButtonSize.large}
            />
          </DrawerButtonContainer>
        </Form>
      </CustomDrawer>

      <CustomModal
        modalIsOpen={!!deleteBreedingGroupId}
        handleCloseModal={handleDeleteModalClose}
        modalHeader={`Excluir Grupo de Repasse`}
        modalText={`Você tem certeza que deseja excluir este grupo de repasse?`}
        primaryButtonLabel="EXCLUIR"
        primaryButtonAction={handleDeleteModalConfirm}
        primaryButtonType={ButtonType.destructive}
        secondaryButtonLabel="CANCELAR"
        secondaryButtonAction={handleDeleteModalClose}
      />
    </>
  )
}

export { BreedingGroupHistoryTemplate }
