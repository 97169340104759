import { flexRender, RowData, Table } from '@tanstack/react-table'
import React, { Fragment } from 'react'
import Skeleton from 'react-loading-skeleton'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { FilterTable } from 'components/molecules'

import { navigateToAnimal } from 'app/core/routes/routes'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

export type CustomTableProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  table: Table<any>
  hasPagination?: boolean
  disableNavigation?: boolean
  disableLastCellNavigation?: boolean
  disableHeader?: boolean
  /**
   * Disable navigation of specific cells by their column ids
   */
  disableCellsNavigationByIndex?: number[]
  isEditable?: boolean
  className?: string
  isHeaderSticky?: boolean
  isLoading?: boolean
  noDataMessage?: string
  sortable?: boolean
}

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void
  }

  // eslint-disable-next-line @typescript-eslint/naming-convention, @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    canFilter?: boolean
    colSpan?: number
    filterVariant?: 'checkbox'
    mainHeader?: boolean
  }
}

const TableHeader: React.FC<CustomTableProps> = ({
  table,
  isHeaderSticky,
  sortable,
}) => (
  <thead>
    {table.getHeaderGroups().map(headerGroup => (
      <tr key={headerGroup.id}>
        {headerGroup.headers.map(header => {
          const isEmptyColumn = header.column.columnDef.header === ''
          const canFilter = header.column.columnDef.meta?.canFilter

          return (
            <th
              key={header.id}
              className={classNames(
                isHeaderSticky && styles.sticky,
                header.column.columnDef.meta?.mainHeader && styles.mainHeader,
                sortable && styles.sortable,
                canFilter && styles.filter
              )}
              colSpan={header.column.columnDef.meta?.colSpan}
            >
              <span
                {...(sortable && {
                  onClick: header.column.getToggleSortingHandler(),
                })}
              >
                {canFilter && header.column.getIsFiltered() && (
                  <svg
                    className={styles.iconFilterActive}
                    xmlns="http://www.w3.org/2000/svg"
                    width={19}
                    height={18}
                    fill="none"
                  >
                    <path d="M16.25 6.088H2.75a.73.73 0 0 1-.53-.232.818.818 0 0 1-.22-.562c0-.21.079-.412.22-.561a.73.73 0 0 1 .53-.233h13.5a.73.73 0 0 1 .53.233c.141.149.22.35.22.561 0 .21-.079.413-.22.562a.73.73 0 0 1-.53.232Zm-2.5 3.706h-8.5a.73.73 0 0 1-.53-.232A.818.818 0 0 1 4.5 9c0-.21.079-.413.22-.562a.73.73 0 0 1 .53-.232h8.5a.73.73 0 0 1 .53.232c.141.15.22.351.22.562 0 .21-.079.413-.22.562a.73.73 0 0 1-.53.232Zm-3 3.706h-2.5a.73.73 0 0 1-.53-.233.818.818 0 0 1-.22-.561c0-.21.079-.413.22-.562a.73.73 0 0 1 .53-.232h2.5a.73.73 0 0 1 .53.232c.141.15.22.351.22.562 0 .21-.079.412-.22.561a.73.73 0 0 1-.53.233Z" />
                  </svg>
                )}

                {header.isPlaceholder
                  ? null
                  : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}

                {sortable && !isEmptyColumn && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={18}
                    height={18}
                    fill="none"
                    className={classNames(
                      header.column.getCanSort() && styles.iconSortable,
                      header.column.getIsSorted() && styles.iconSortableActive,
                      header.column.getIsSorted() === 'asc' &&
                        styles.iconSortableActiveUp
                    )}
                  >
                    <path
                      fillRule="evenodd"
                      d="M9 2.672c.466 0 .844.378.844.844v8.931l3.622-3.622a.844.844 0 1 1 1.193 1.194L9.597 15.08a.844.844 0 0 1-1.194 0l-5.062-5.063a.844.844 0 1 1 1.193-1.193l3.622 3.622V3.516c0-.466.378-.844.844-.844Z"
                      clipRule="evenodd"
                    />
                  </svg>
                )}
              </span>

              {canFilter && !isEmptyColumn && (
                <FilterTable
                  column={header.column}
                  columnFilters={table.getState().columnFilters}
                  setColumnFilters={table.setColumnFilters}
                />
              )}
            </th>
          )
        })}
      </tr>
    ))}
  </thead>
)

const TableEditableBody: React.FC<CustomTableProps> = ({ table }) => {
  return (
    <tbody>
      {table.getRowModel().rows.map(row => {
        return (
          <tr key={row.id}>
            {row.getVisibleCells().map(cell => {
              return (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              )
            })}
          </tr>
        )
      })}
    </tbody>
  )
}

const TableBody: React.FC<CustomTableProps> = ({
  table,
  disableNavigation,
  disableLastCellNavigation,
  disableCellsNavigationByIndex,
  isLoading,
}) => {
  const navigationStyle = disableNavigation ? '' : styles.navigationRow
  const loadingRowsQuantity = 10

  const loadingRows = (): JSX.Element[] => {
    return Array(loadingRowsQuantity)
      .fill({})
      .map((_, i) => (
        <tr key={`row-${i}`} className={styles.loading}>
          {table.getHeaderGroups().map(headerGroup => (
            <React.Fragment key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <td key={header.id}>
                  <Skeleton height={30} />
                </td>
              ))}
            </React.Fragment>
          ))}
        </tr>
      ))
  }

  return (
    <tbody>
      {isLoading
        ? loadingRows()
        : table.getRowModel().rows.map(row => (
            <Fragment key={row.index}>
              <tr
                key={row.index}
                id={`id-${row.getValue('id')}`}
                className={classNames(
                  navigationStyle,
                  row.original.error_message ? styles.errorRow : '',
                  row.original.new ? styles.newRow : ''
                )}
              >
                {row.getVisibleCells().map((cell, i) => {
                  const isLastCellDisabled =
                    disableLastCellNavigation &&
                    i + 1 === row.getVisibleCells().length

                  const isCustomCellDisabled =
                    disableCellsNavigationByIndex &&
                    disableCellsNavigationByIndex.includes(i + 1)

                  const skipNavigation =
                    disableNavigation ||
                    isLastCellDisabled ||
                    isCustomCellDisabled
                  return (
                    <td
                      key={cell.id}
                      onClick={
                        skipNavigation
                          ? undefined
                          : (): void =>
                              navigateToAnimal(
                                row.original.animal?.id ||
                                  row.original.animal_id ||
                                  row.getValue('id')
                              )
                      }
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  )
                })}
              </tr>
              {row.original.error_message && (
                <tr className={styles.errorMessage}>
                  <td colSpan={row.getVisibleCells().length}>
                    {row.original.error_message}
                  </td>
                </tr>
              )}
            </Fragment>
          ))}
    </tbody>
  )
}

const TablePagination: React.FC<CustomTableProps> = ({
  table,
  hasPagination,
}) => {
  const currentPage = table.getState().pagination.pageIndex

  if (!hasPagination) {
    return null
  }

  return (
    <div className={styles.pagination}>
      <button
        onClick={(): void => table.previousPage()}
        disabled={!table.getCanPreviousPage()}
      >
        <Icon name={IconNames['chevron-back']} size={15} />
      </button>
      {table.getPageCount() !== -1 &&
        [...Array(table.getPageCount())].map((_, i) => {
          const page = i + 1

          return (
            <li key={page}>
              <button
                onClick={(): void => table.setPageIndex(i)}
                className={currentPage === i ? styles.selected : ''}
              >
                {page}
              </button>
            </li>
          )
        })}
      <button
        onClick={(): void => table.nextPage()}
        disabled={!table.getCanNextPage()}
      >
        <Icon name={IconNames['chevron-forward']} size={15} />
      </button>
    </div>
  )
}

const CustomTable: React.FC<CustomTableProps> = ({
  table,
  isEditable,
  className,
  disableNavigation,
  disableLastCellNavigation,
  disableCellsNavigationByIndex,
  disableHeader,
  hasPagination,
  isHeaderSticky,
  isLoading,
  noDataMessage = Messages.EMPTY_DATA,
  sortable,
}) => {
  return (
    <>
      <table
        className={classNames(
          styles.table,
          disableNavigation && styles.tableDisableNavigation,
          className
        )}
      >
        {!disableHeader && (
          <TableHeader
            table={table}
            isHeaderSticky={isHeaderSticky}
            sortable={sortable}
          />
        )}

        {isEditable ? (
          <TableEditableBody table={table} />
        ) : (
          <TableBody
            table={table}
            disableNavigation={disableNavigation}
            disableLastCellNavigation={disableLastCellNavigation}
            disableCellsNavigationByIndex={disableCellsNavigationByIndex}
            isLoading={isLoading}
            hasPagination={hasPagination}
          />
        )}
      </table>

      {!isLoading && table.getRowModel().rows.length === 0 && (
        <div className={styles.tableEmpty}>
          <Typography text={noDataMessage} variant={TypographyVariant.p} />
        </div>
      )}

      <TablePagination table={table} hasPagination={hasPagination} />
    </>
  )
}

export { CustomTable }
