import { ColumnFiltersState, Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  CustomTable,
  ServiceOrderFilters,
  ServiceOrderPagination,
} from 'components/organisms'
import { OSLayout } from 'components/templates'

import { ServiceOrderPageInfo } from 'app/core/types/hooks'
import {
  ListServiceOrderProps,
  ServiceOrderInfoProps,
} from 'app/core/types/service-order'
import { Messages } from 'config/messages'

import styles from './styles.module.scss'

type ServiceOrdersReviewTemplateProps = {
  activeFilters: ListServiceOrderProps
  columnFilters: ColumnFiltersState
  table: Table<ServiceOrderInfoProps>
  serviceOrders: ServiceOrderInfoProps[]
  serviceOrderPageInfo: ServiceOrderPageInfo
  setPage: Dispatch<SetStateAction<number>>
  isLoading: boolean
}

const ServiceOrdersReviewTemplate: React.FC<ServiceOrdersReviewTemplateProps> =
  ({
    activeFilters,
    columnFilters,
    table,
    serviceOrders,
    serviceOrderPageInfo,
    setPage,
    isLoading,
  }) => {
    const hasServiceTypeFiltered = activeFilters.service_type === null
    const hasFilters =
      activeFilters.contains_name ||
      activeFilters.service_type ||
      hasServiceTypeFiltered ||
      columnFilters.length > 0
    const currentPageItems = serviceOrders?.length

    return (
      <OSLayout largerContent>
        <ServiceOrderFilters.Root>
          <div>
            <ServiceOrderFilters.Date />
            <ServiceOrderFilters.Count total={serviceOrderPageInfo.total} />
          </div>
          <div className={styles.findAndCheckbox}>
            <ServiceOrderFilters.Text />
            <ServiceOrderFilters.ServiceType />
          </div>
        </ServiceOrderFilters.Root>

        <section className={styles.review}>
          <CustomTable
            table={table}
            isLoading={isLoading}
            noDataMessage={
              hasFilters
                ? Messages.SERVICE_ORDER_NOT_FOUND_FILTERED
                : Messages.SERVICE_ORDER_NOT_FOUND
            }
            disableNavigation
            sortable
          />
        </section>

        <ServiceOrderPagination
          currentPage={serviceOrderPageInfo.currentPage}
          currentPageItems={currentPageItems}
          totalItems={serviceOrderPageInfo.total}
          totalPages={serviceOrderPageInfo.totalPages}
          setPage={setPage}
        />
      </OSLayout>
    )
  }

export { ServiceOrdersReviewTemplate }
