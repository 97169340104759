import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react'

import { SelectedFiles } from 'use-file-picker'

import { getReadAllAnimals, getReadAnimal } from 'app/core/services'
import {
  getReadAllInseminations,
  getReadAnimalsByInsemination,
  getReadInsemination,
  patchUpdateInseminationAnimal,
  postAddInseminationAnimal,
  postCreateInsemination,
  postRemoveInseminationAnimal,
} from 'app/core/services/hormonal/inseminations'
import {
  AnimalFilterProps,
  AnimalRequestData,
  AnimalSexLabel,
} from 'app/core/types/animal'
import {
  InseminationAnimalHook,
  InseminationAnimalHookProps,
  InseminationHook,
  InseminationHookProps,
} from 'app/core/types/hooks'
import {
  InseminationAnimalProps,
  InseminationAnimalResponseData,
  InseminationCreateFormatData,
  InseminationCreateRequestData,
  InseminationProps,
  InseminationReadResponseData,
  UpdateAnimalInseminationData,
} from 'app/core/types/hormonal'
import { StorageKeys } from 'app/core/types/storage'
import {
  DEFAULT_ITEMS_PER_PAGE_LIST,
  FILTER_ANIMALS_SIZE,
} from 'app/core/types/system'
import {
  addToast,
  dateForFileName,
  dateTimeXslxFormat,
  dateToday,
  downloadFile,
  generateXlsxTemplate,
  getPlural,
  getValueOrNull,
  handleHttpError,
  paginateItems,
} from 'app/core/utils'
import { Messages } from 'config/messages'

import { xlsxToAnimals } from './helpers'

const useInsemination = ({
  inseminationId,
  filters,
  page,
  size,
}: InseminationHookProps): InseminationHook => {
  const [inseminations, setInseminations] =
    useState<InseminationReadResponseData>()
  const [insemination, setInsemination] = useState<InseminationProps>()
  const [isLoading, setIsLoading] = useState(false)

  const readAllInseminations = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllInseminations(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )

      if (data.total === 0) {
        addToast({
          message: Messages.INSEMINATION_NOT_FOUND,
          type: 'warning',
        })
      }

      setInseminations(data)
      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      handleHttpError(e)
    }
  }, [filters, page, size])

  const readInsemination = useCallback(async (): Promise<void> => {
    if (inseminationId) {
      try {
        const data = await getReadInsemination(inseminationId)
        setInsemination(data)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [inseminationId])

  const addInsemination = useCallback(
    async (
      request: InseminationCreateFormatData,
      setInseminationId: Dispatch<SetStateAction<number | undefined>>,
      setIsModalOpen: Dispatch<SetStateAction<boolean>>
    ): Promise<void> => {
      if (request) {
        const formatRequest = {
          ...request,
          ...(request.iatfschedule_id && {
            iatfschedule_id: parseInt(request.iatfschedule_id.value),
          }),
          ...(request.breeding_station_id && {
            breeding_station_id: parseInt(request.breeding_station_id.value),
          }),
          type: request.type.value,
        } as InseminationCreateRequestData

        try {
          const response = await postCreateInsemination(formatRequest)
          setInseminationId(response.id)
          setIsModalOpen(true)
        } catch (e) {
          handleHttpError(e)
        }
      }
    },
    []
  )

  useEffect(() => {
    readAllInseminations()
    readInsemination()
  }, [readAllInseminations, readInsemination])

  return {
    inseminations,
    insemination,
    addInsemination,
    isLoading,
    readAllInseminations,
  }
}

const useInseminationAnimal = ({
  inseminationId,
  page,
}: InseminationAnimalHookProps): InseminationAnimalHook => {
  const savedAnimalInseminations = JSON.parse(
    localStorage.getItem(StorageKeys.inseminations_review) as string
  ) as InseminationAnimalProps[]

  const savedFailedInseminations = JSON.parse(
    localStorage.getItem(StorageKeys.inseminations_review_failed) as string
  )

  const [animals, setAnimals] = useState<InseminationAnimalProps[]>(
    () => savedAnimalInseminations || []
  )
  const [failedInseminations, setFailedInseminations] = useState<
    Partial<InseminationAnimalProps>[]
  >(() => savedFailedInseminations || [])
  const [paginatedAnimals, setPaginatedAnimals] =
    useState<InseminationAnimalResponseData>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)
  const [filterIsActive, setFilterIsActive] = useState<boolean>(false)

  const readAnimals = useCallback(
    async (filters?: Record<string, unknown>): Promise<void> => {
      if (inseminationId && !savedAnimalInseminations) {
        try {
          setIsLoading(true)

          const data = await getReadAnimalsByInsemination(
            inseminationId,
            page || 1,
            FILTER_ANIMALS_SIZE,
            filters
          )

          setAnimals(data.items)
          setIsLoading(false)
        } catch (e) {
          setIsLoading(false)
          handleHttpError(e)
        }
      }
    },
    /* eslint-disable react-hooks/exhaustive-deps */
    [inseminationId, savedAnimalInseminations]
  )

  const searchAnimals = useCallback(
    async (
      filters: AnimalFilterProps,
      breedingStationId: number,
      inseminationDate?: string
    ): Promise<void> => {
      if (inseminationId) {
        try {
          setIsLoading(true)

          const animals = await getReadAllAnimals(
            {
              ...filters,
              sex: AnimalSexLabel.female,
              breeding_station_id: breedingStationId,
              is_active: 'true',
            },
            page,
            FILTER_ANIMALS_SIZE
          )

          if (animals.items.length === 0) {
            setIsLoading(false)

            addToast({
              message: Messages.HORMONAL_ANIMAL_NOT_FOUND,
            })

            return
          }

          const newAnimals = animals.items.map(animal => ({
            animal_id: animal.id,
            electronic_eartag: animal.electronic_eartag,
            stock_number: animal.stock_number,
            insemination_date: inseminationDate || dateToday,
          })) as InseminationAnimalProps[]

          setAnimals(prevState => [...prevState, ...newAnimals])
          setIsLoading(false)
        } catch (e) {
          setIsLoading(false)
          handleHttpError(e)
        }
      }
    },
    [inseminationId, page]
  )

  const createInsemination = useCallback(async (): Promise<void> => {
    if (inseminationId) {
      try {
        const response = await postAddInseminationAnimal(
          inseminationId,
          animals
        )

        const failedInseminations = [] as Partial<InseminationAnimalProps>[]
        let successfulInseminations = animals

        if (response.errors.length) {
          response.errors.forEach(error => {
            const animalId = Number(Object.keys(error)[0])
            const errorMessage = error[animalId]
            const failedInsemination = animals.find(
              insemination => insemination.animal_id === animalId
            )

            if (failedInsemination) {
              failedInseminations.push({
                stock_number: failedInsemination.stock_number,
                electronic_eartag: failedInsemination.electronic_eartag,
                insemination_date: failedInsemination.insemination_date,
                heat_type: failedInsemination.heat_type,
                ...(failedInsemination.semen_implanted && {
                  semen_implanted_stock_number:
                    failedInsemination.semen_implanted_stock_number,
                }),
                ...(failedInsemination.breeding_animal_id && {
                  breeding_animal_stock_number:
                    failedInsemination.breeding_animal_stock_number,
                }),
                error_message: errorMessage,
              })
            }

            successfulInseminations = [
              ...successfulInseminations.filter(
                insemination => insemination.animal_id !== animalId
              ),
            ]
          })

          setFailedInseminations(failedInseminations)
          setAnimals(successfulInseminations)

          localStorage.setItem(
            StorageKeys.inseminations_review_failed,
            JSON.stringify(failedInseminations)
          )
          localStorage.setItem(
            StorageKeys.inseminations_review,
            JSON.stringify(successfulInseminations)
          )

          addToast({
            message: `Não foi possível criar ${getPlural(
              'registro',
              failedInseminations.length,
              true
            )}. Verifique a planilha de inconsistências e tente novamente.`,
          })
        }

        if (response.data.length > 0) {
          addToast({
            message: `${getPlural(
              'Registro',
              successfulInseminations.length,
              true
            )} ${getPlural(
              'criado',
              successfulInseminations.length
            )} com sucesso.`,
            type: 'success',
          })
        }
      } catch (e) {
        handleHttpError(e, false)
      }
    }
  }, [animals, inseminationId])

  const updateStorage = useCallback(
    (items: InseminationAnimalProps[]): void => {
      localStorage.setItem(
        StorageKeys.inseminations_review,
        JSON.stringify(items)
      )
    },
    []
  )

  const updateTableRow = useCallback(
    (
      animalId: number,
      inseminationId: number | undefined,
      values: Partial<InseminationAnimalProps>
    ): void => {
      const updatedAnimals = animals.map(animal => {
        const foundAnimalId = animal.animal_id === animalId
        const foundInseminationId =
          animal.id === inseminationId && typeof inseminationId !== 'undefined'

        if (foundAnimalId || foundInseminationId) {
          return { ...animal, ...values }
        }
        return animal
      })

      setAnimals(updatedAnimals)
      updateStorage(updatedAnimals)
    },
    [animals, setAnimals, updateStorage]
  )

  const updateAnimal = useCallback(
    async ({
      inseminatedAnimalId,
      inseminationId,
      request,
    }: UpdateAnimalInseminationData): Promise<void> => {
      if (inseminatedAnimalId && inseminationId) {
        try {
          await patchUpdateInseminationAnimal(inseminationId, request)

          readAnimals()

          addToast({
            message: Messages.DATA_UPDATE,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e)
        }
      }

      if (!inseminationId) {
        updateTableRow(inseminatedAnimalId, inseminationId, request)
      }
    },
    [updateTableRow, readAnimals]
  )

  const importInseminations = async (
    file: SelectedFiles,
    breedingStationId?: number
  ): Promise<void> => {
    try {
      setIsLoading(true)
      const animals = await xlsxToAnimals(
        file.filesContent[0].content,
        breedingStationId
      )

      const sortItemsWithError = [...animals].sort((a, b) => {
        if (a.error_message && !b.error_message) return -1
        if (!a.error_message && b.error_message) return 1
        return 0
      })

      setAnimals(prevState => [...prevState, ...sortItemsWithError])

      addToast({
        message: Messages.INSEMINATION_IMPORT_SUCCESSFUL,
        type: 'success',
      })

      setIsLoading(false)
    } catch (e) {
      setIsLoading(false)
      addToast({ message: Messages.ERROR_MESSAGE })
    }
  }

  const filterAnimals = useCallback(
    async (filters?: AnimalRequestData): Promise<void> => {
      readAnimals(filters)
      setFilterIsActive(!!Object.keys(filters ?? {}).length)
    },
    [readAnimals]
  )

  useEffect(() => {
    readAnimals()
  }, [readAnimals])

  const handlePagination = useCallback(() => {
    if (animals) {
      const paginatedAnimals = paginateItems(animals, page)
      setPaginatedAnimals(paginatedAnimals)
    }
  }, [animals, page])

  const removeAnimal = useCallback(
    (index: number): void => {
      if (animals) {
        const updatedAnimals = animals.filter(
          (_, itemIndex) => index !== itemIndex
        )
        setAnimals(updatedAnimals)
        updateStorage(updatedAnimals)
      }
    },
    [animals, updateStorage]
  )

  const removeInsemination = useCallback(
    async (animalInseminationId: number) => {
      if (inseminationId && animalInseminationId) {
        try {
          const response = await postRemoveInseminationAnimal(
            Number(inseminationId),
            animalInseminationId
          )

          const removeFailed = response.find(error =>
            Object.keys(error).includes(String(animalInseminationId))
          )

          if (removeFailed) {
            addToast({ message: removeFailed[animalInseminationId] })
            return
          }

          readAnimals()

          addToast({
            message: Messages.INSEMINATION_DELETED_SUCCESS,
            type: 'success',
          })
        } catch (e) {
          handleHttpError(e, false)
        }
      }
    },
    [inseminationId, readAnimals]
  )

  const createInseminationStorage = (): void => {
    localStorage.setItem(
      StorageKeys.inseminations_review,
      JSON.stringify(animals)
    )
  }

  const clearInseminationStorage = (): void => {
    localStorage.removeItem(StorageKeys.inseminations_review)
    localStorage.removeItem(StorageKeys.inseminations_review_failed)
  }

  const exportFailedInseminations = (): void => {
    const templateFile = generateXlsxTemplate([
      [
        'Nº de Plantel',
        'Nº de Brinco eletrônico',
        'Data de inseminação',
        'Tipo de Cio',
        'Sêmen implantado/Animal reprodutor',
        'Inconsistência',
      ],
      ...failedInseminations.map(obj => Object.values(obj)),
    ])

    downloadFile({
      data: templateFile,
      fileName: `inseminacoes-${dateForFileName()}`,
    })
  }

  const exportInseminations = async (): Promise<void> => {
    if (animals) {
      try {
        setIsLoadingExport(true)

        addToast({
          message: Messages.GENERATING_EXPORT_FILE,
          type: 'info',
        })

        const formattedInseminations = await Promise.all(
          animals.map(
            async ({
              electronic_eartag,
              stock_number,
              insemination_date,
              heat_type,
              semen_implanted,
              breeding_animal_id,
              responsible_inseminator,
            }) => {
              const animalIdToSearch = semen_implanted
                ? semen_implanted
                : breeding_animal_id

              if (!animalIdToSearch) {
                return [
                  getValueOrNull(electronic_eartag, 'string'),
                  stock_number,
                  insemination_date
                    ? dateTimeXslxFormat(insemination_date)
                    : null,
                  heat_type,
                  '',
                  responsible_inseminator,
                ]
              }

              const animalStockNumber = await getReadAnimal(animalIdToSearch)

              return [
                getValueOrNull(electronic_eartag, 'string'),
                stock_number,
                insemination_date
                  ? dateTimeXslxFormat(insemination_date)
                  : null,
                heat_type,
                animalStockNumber.header.stock_number,
                responsible_inseminator,
              ]
            }
          )
        )

        const templateFile = generateXlsxTemplate([
          [
            'Nº de Brinco eletrônico',
            'Nº de Plantel',
            'Data da inseminação',
            'Tipo de cio',
            'Sêmen implantado/Animal reprodutor',
            'Inseminador responsável',
          ],
          ...formattedInseminations,
        ])

        downloadFile({
          data: templateFile,
          fileName: `inseminacoes-${dateForFileName()}`,
        })

        addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
      } catch (e) {
        handleHttpError(e, false)
      } finally {
        setIsLoadingExport(false)
      }
    }
  }

  useEffect(() => {
    handlePagination()
  }, [handlePagination])

  return {
    animals: paginatedAnimals,
    setAnimals,
    createInsemination,
    createInseminationStorage,
    clearInseminationStorage,
    readAnimals,
    searchAnimals,
    removeAnimal,
    removeInsemination,
    updateAnimal,
    isLoading,
    isLoadingExport,
    failedInseminations,
    exportFailedInseminations,
    importInseminations,
    filterAnimals,
    filterIsActive,
    exportInseminations,
  }
}

export { useInsemination, useInseminationAnimal }
