import React, { useCallback, useEffect, useState } from 'react'

import {
  GetDiagnosticsFilters,
  deleteGroupDiagnostic,
  getDiagnostics as getDiagnosticsService,
} from 'app/core/services'
import { DiagnosticReadResponseData } from 'app/core/types/breeding'
import { StorageKeys } from 'app/core/types/storage'
import { DEFAULT_ITEMS_PER_PAGE_LIST } from 'app/core/types/system'
import { addToast, handleHttpError } from 'app/core/utils'
import { Messages } from 'config/messages'

import { DiagnosticsHistoryTemplate } from './template'

const DiagnosticsHistory: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)
  const [groups, setGroups] = useState<DiagnosticReadResponseData>()
  const [page, setPage] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [deleteGroupDiagnosticId, setDeleteGroupDiagnosticId] =
    useState<number>()

  const getDiagnostics = useCallback(
    async (filters: GetDiagnosticsFilters) => {
      try {
        setIsLoading(true)

        const diagnostics = await getDiagnosticsService(
          filters,
          page,
          DEFAULT_ITEMS_PER_PAGE_LIST
        )

        setGroups(diagnostics)
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        handleHttpError(e, false)
      }
    },
    [page]
  )

  useEffect(() => {
    getDiagnostics({})
  }, [getDiagnostics])

  const handleToggleDrawer = (): void => setIsDrawerOpen(prev => !prev)

  const handleDeleteModalClose = (): void => {
    setDeleteGroupDiagnosticId(undefined)
  }

  const handleDeleteModalConfirm = useCallback(async () => {
    if (deleteGroupDiagnosticId) {
      try {
        await deleteGroupDiagnostic(Number(deleteGroupDiagnosticId))

        getDiagnostics({})

        addToast({
          message: Messages.BREEDING_DIAGNOSTICS_GROUP_DELETED_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e, false)
      }

      handleDeleteModalClose()
    }
  }, [deleteGroupDiagnosticId, getDiagnostics])

  const handleFilterGroups = useCallback(
    async (data: unknown): Promise<void> => {
      await getDiagnostics(data as GetDiagnosticsFilters)
      setPage(1)
      handleToggleDrawer()
    },
    [getDiagnostics]
  )

  const removeStorageDiagnostics = useCallback((): void => {
    localStorage.removeItem(StorageKeys.diagnostics_review)
    localStorage.removeItem(StorageKeys.diagnostics_review_info)
    localStorage.removeItem(StorageKeys.diagnostics_review_failed)
  }, [])

  useEffect(() => {
    removeStorageDiagnostics()
  }, [removeStorageDiagnostics])

  return (
    <DiagnosticsHistoryTemplate
      groups={groups}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteModalConfirm={handleDeleteModalConfirm}
      handleToggleDrawer={handleToggleDrawer}
      isDrawerOpen={isDrawerOpen}
      onSubmit={handleFilterGroups}
      currentPage={page}
      setCurrentPage={setPage}
      isLoading={isLoading}
      deleteGroupDiagnosticId={deleteGroupDiagnosticId}
      setDeleteGroupDiagnosticId={setDeleteGroupDiagnosticId}
    />
  )
}

export { DiagnosticsHistory }
