import { SelectedFiles } from 'use-file-picker'
import * as XLSX from 'xlsx'

import { dateTimeFormat, dateTimeISOFormat } from '../date'
import { onlyAlphanumericString } from '../literals'

const generateXlsxTemplate = (data: unknown[][]): Blob => {
  const wb = XLSX.utils.book_new()
  const ws = XLSX.utils.aoa_to_sheet(data)

  for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
    const rowData = data[rowIndex]
    for (let colIndex = 0; colIndex < rowData.length; colIndex++) {
      const cellValue = rowData[colIndex]
      if (cellValue instanceof Date) {
        const cell = XLSX.utils.encode_cell({ r: rowIndex, c: colIndex })
        const dateCellStyle = { t: 'd', z: 'dd/mm/yyyy' }
        ws[cell] = Object.assign({}, ws[cell], { s: dateCellStyle })
      }
    }
  }

  XLSX.utils.book_append_sheet(wb, ws, 'Planilha')

  const buffer = XLSX.write(wb, {
    bookType: 'xlsx',
    type: 'array',
  })

  const blob = new Blob([buffer], { type: 'fileType' })

  return blob
}

const getValueOrNull = <T extends Date | string | undefined | number | null>(
  value: T,
  convertType?: 'number' | 'string'
): T | string | number | null => {
  const hasValue = value !== null && value !== undefined

  if (hasValue && convertType) {
    return convertType === 'number' ? Number(value) : String(value)
  }

  return hasValue ? value : null
}

const getFormattedDateValueOrNull = (date: Date | unknown): string | null => {
  try {
    if (!(date instanceof Date)) return null

    const stringDate = dateTimeISOFormat(date.toString())
    return stringDate !== '' ? dateTimeFormat(stringDate) : null
  } catch (e) {
    return null
  }
}

const arrayToCsv = (data: Record<string, unknown>[], delimiter = ','): Blob => {
  const headers = Object.keys(data[0])
  const arrayToCsv = [headers.join(delimiter)]

  data.forEach(animal => {
    const values = headers.map(header => {
      const value = animal[header]
      return value !== null && value !== undefined ? String(value) : ''
    })
    arrayToCsv.push(values.join(delimiter))
  })

  const csvData = arrayToCsv.join('\n')
  const utfBom = new Uint8Array([0xef, 0xbb, 0xbf]) // This line is responsible to handle special characters in csv

  const blob = new Blob([utfBom, csvData], { type: 'text/csv' })

  return blob
}

type XlsxBlob = {
  data: Blob
  name: string
}

const XLSX_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'

const parseSelectedFileToBlob = (file: SelectedFiles): XlsxBlob => {
  const fileContent = file.filesContent[0]

  const fileBlob = new Blob([fileContent.content], {
    type: XLSX_TYPE,
  })

  return {
    data: fileBlob,
    name: fileContent.name,
  }
}

const parseNumberFromSheet = (number: string | number): number => {
  if (typeof number === 'number') return number
  const sanitizedNumber = onlyAlphanumericString(number)
  return Number(sanitizedNumber)
}

export {
  arrayToCsv,
  generateXlsxTemplate,
  getFormattedDateValueOrNull,
  getValueOrNull,
  parseNumberFromSheet,
  parseSelectedFileToBlob,
  XLSX_TYPE,
}
