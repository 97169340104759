import {
  HandlingCreateRequestData,
  HandlingEntity,
  HandlingReadResponseData,
  HandlingResponseData,
} from 'app/core/types/handling'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/handlings/'

const getHandlings = async (
  page?: number,
  filters?: Record<string, unknown>
): Promise<HandlingReadResponseData> => {
  const response = await http.get(baseUrl, {
    params: {
      ...removeEmptyFilters(filters),
      page,
    },
  })

  return response.data
}

const getHandling = async (
  handlingId: number,
  filters: Record<string, unknown>
): Promise<HandlingEntity> => {
  const response = await http.get(`${baseUrl}${handlingId}/`, {
    params: {
      ...removeEmptyFilters(filters),
    },
  })

  return response.data
}

const postAddHandling = async ({
  date,
  type,
  farm_id,
  handlings,
}: HandlingCreateRequestData): Promise<HandlingResponseData> => {
  const response = await http.post(`${baseUrl}`, {
    date,
    type,
    farm_id,
    handlings,
  })

  return response.data
}

export { getHandlings, getHandling, postAddHandling }
