import React, {
  Dispatch,
  LegacyRef,
  SetStateAction,
  useEffect,
  useRef,
} from 'react'

import classNames from 'classnames'

import {
  Icon,
  IconNames,
  Typography,
  TypographyVariant,
} from 'components/atoms'

import styles from './styles.module.scss'

export interface IDialogItem {
  itemName: string
  itemAction: () => void
  itemIcon?: IconNames
}

export interface IDialogProps {
  items: IDialogItem[]
  posX: number
  posY: number
  isOpen: boolean
  setIsOpen: Dispatch<SetStateAction<boolean>>
}

const Dialog: React.FC<IDialogProps> = ({
  items,
  posX,
  posY,
  isOpen,
  setIsOpen,
}) => {
  const useOutsideClick = (
    callback: () => void
  ): LegacyRef<HTMLDialogElement> => {
    const ref = useRef<HTMLDialogElement | null>(null)

    useEffect(() => {
      const handleClick = (event: MouseEvent): void => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          callback()
        }
      }

      document.addEventListener('click', handleClick)

      return (): void => {
        document.removeEventListener('click', handleClick)
      }
    }, [ref, callback])

    return ref
  }

  const handleClickOutside = (): void => {
    if (isOpen) {
      setIsOpen(false)
    }
  }

  const ref = useOutsideClick(handleClickOutside)

  useEffect(() => {
    const handleScroll = (): void => {
      if (isOpen) {
        setIsOpen(false)
      }
    }

    document.addEventListener('scroll', handleScroll)

    return (): void => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [isOpen, setIsOpen])

  const calculateDialogPosition = (): React.CSSProperties => {
    const scrollX = window.scrollX || window.pageXOffset
    const scrollY = window.scrollY || window.pageYOffset

    return {
      left: posX + scrollX,
      top: posY + scrollY,
    }
  }

  return (
    <>
      {isOpen && (
        <dialog
          ref={ref}
          style={calculateDialogPosition()}
          className={classNames(styles.dialogContainer)}
        >
          {items.map(item => (
            <span
              key={item.itemName}
              className={styles.dialogItem}
              onClick={item.itemAction}
              role="button"
            >
              {item.itemIcon && (
                <Icon
                  name={item.itemIcon}
                  size="16"
                  className={styles.dialogItemIcon}
                />
              )}
              <Typography
                variant={TypographyVariant.label}
                text={item.itemName}
                className={styles.dialogItemText}
              />
            </span>
          ))}
        </dialog>
      )}
    </>
  )
}

export { Dialog }
