import { useCallback, useEffect, useState } from 'react'

import { getReadAllAnimals } from 'app/core/services'
import { postCreateCollectiveMovement } from 'app/core/services/collective-movement'
import { getReadAllEvaluations } from 'app/core/services/evaluation'
import {
  getExportMatingRecommendations,
  getReadAllMatingRecommendations,
  patchEditMatingRecommendation,
  postCreateMatingRecommendation,
} from 'app/core/services/mating-recommendations'
import { AnimalFilterProps, AnimalListProps } from 'app/core/types/animal'
import { CollectiveMovementCreateRequestData } from 'app/core/types/collective-movements'
import {
  AnimalEvaluations,
  EvaluationFilterProps,
} from 'app/core/types/evaluation'
import {
  MatingRecommendationHook,
  MatingRecommendationHookProps,
} from 'app/core/types/hooks'
import {
  MatingRecommendationCreateRequestData,
  MatingRecommendationEditRequestData,
  MatingRecommendationFilterProps,
  MatingRecommendationProps,
  MatingRecommendationReadResponseData,
} from 'app/core/types/mating-recommendation'
import { DEFAULT_ITEMS_PER_PAGE_LIST } from 'app/core/types/system'
import {
  addToast,
  arrayToCsv,
  dateForFileName,
  downloadFile,
  getFormattedDateValueOrNull,
  getValueOrNull,
  handleHttpError,
} from 'app/core/utils'
import { Messages } from 'config/messages'

const useMatingRecommendations = ({
  animalId,
  filters,
  page,
  size,
}: MatingRecommendationHookProps): MatingRecommendationHook => {
  const [recommendations, setRecommendations] =
    useState<MatingRecommendationReadResponseData>()
  const [animalRecommendations, setAnimalRecommendations] = useState<
    MatingRecommendationProps[]
  >([])
  const [animalCalfs, setAnimalCalfs] = useState<AnimalListProps[]>()
  const [isLoading, setIsLoading] = useState(false)
  const [isLoadingCalfs, setIsLoadingCalfs] = useState(false)
  const [isLoadingExport, setIsLoadingExport] = useState(false)

  const readAllMatingRecommendations = useCallback(async (): Promise<void> => {
    setIsLoading(true)

    try {
      const data = await getReadAllMatingRecommendations(
        filters,
        page,
        size || DEFAULT_ITEMS_PER_PAGE_LIST
      )
      setRecommendations(data)
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoading(false)
    }
  }, [filters, page, size])

  const getAnimalMatingRecommendations = useCallback(async () => {
    if (animalId) {
      try {
        const filters = {
          animal_id: Number(animalId),
        } as MatingRecommendationFilterProps

        const data = await getReadAllMatingRecommendations(filters)

        setAnimalRecommendations(data.items)
      } catch (e) {
        handleHttpError(e)
      }
    }
  }, [animalId])

  const searchAnimalCalfs = useCallback(
    async (animalId: number): Promise<AnimalListProps[]> => {
      const filters = {
        mother_id: Number(animalId),
        load_father_name: 'true',
      } as AnimalFilterProps

      const animals = await getReadAllAnimals(filters)

      const calfsWithEvaluations = await Promise.all(
        animals.items.map(async calf => {
          const filters = {
            animal_id: calf.id,
          } as EvaluationFilterProps

          const calfEvaluations = await getReadAllEvaluations(filters)
          calf.evaluations = calfEvaluations.items

          return calf
        })
      )

      const sortedCalfs = calfsWithEvaluations.sort((animalA, animalB) => {
        const birthDateA = new Date(animalA.birth_date)
        const birthDateB = new Date(animalB.birth_date)

        if (birthDateA > birthDateB) {
          return -1
        } else if (birthDateA < birthDateB) {
          return 1
        } else {
          return 0
        }
      })

      return sortedCalfs
    },
    []
  )

  const getAnimalCalfs = useCallback(async () => {
    if (animalId) {
      setIsLoadingCalfs(true)

      try {
        const animalCalfs = await searchAnimalCalfs(Number(animalId))

        setAnimalCalfs(animalCalfs)
      } catch (e) {
        handleHttpError(e)
      } finally {
        setIsLoadingCalfs(false)
      }
    }
  }, [animalId, searchAnimalCalfs])

  const createRecommendation = useCallback(
    async (request: MatingRecommendationCreateRequestData) => {
      try {
        await postCreateMatingRecommendation(request)

        getAnimalMatingRecommendations()

        addToast({
          message: Messages.MATING_RECOMMENDATION_CREATED_SUCCESSFUL,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [getAnimalMatingRecommendations]
  )

  const editRecommendation = useCallback(
    async (
      recommendationId: number,
      request: MatingRecommendationEditRequestData
    ) => {
      try {
        await patchEditMatingRecommendation(recommendationId, request)

        getAnimalMatingRecommendations()

        addToast({
          message: Messages.MATING_RECOMMENDATION_EDIT_SUCCESSFUL,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    [getAnimalMatingRecommendations]
  )

  const exportMatingRecommendations = async (): Promise<void> => {
    try {
      setIsLoadingExport(true)

      addToast({
        message: Messages.GENERATING_EXPORT_FILE,
        type: 'info',
      })

      const data = await getExportMatingRecommendations()

      const recommendations = data.map(recommendation => ({
        'Data de nascimento': getFormattedDateValueOrNull(
          new Date(recommendation.last_recommendation_date)
        ),
        'Nº de plantel': getValueOrNull(recommendation.stock_number),
        'Nº de nascimento': getValueOrNull(recommendation.birth_number),
        Status: recommendation.status ? 'Ativo' : 'Inativo',

        'Data de nascimento da última cria': recommendation.calf_birth_date
          ? getFormattedDateValueOrNull(
              new Date(recommendation.calf_birth_date)
            )
          : null,
        'Nº de plantel da última cria': getValueOrNull(
          recommendation.calf_stock_number
        ),
        'Nº de nascimento da última cria': getValueOrNull(
          recommendation.calf_birth_number
        ),

        'Última nota': recommendation.last_evaluation_score
          ? (AnimalEvaluations as Record<number, string>)[
              recommendation.last_evaluation_score
            ]
          : null,
        'Data da última avaliação': recommendation.last_evaluation_date
          ? getFormattedDateValueOrNull(
              new Date(recommendation.last_evaluation_date)
            )
          : null,

        'Nome do último animal recomendado': getValueOrNull(
          recommendation.male_name
        ),
        'Nº de plantel do último animal recomendado': getValueOrNull(
          recommendation.male_stock_number
        ),
        'Nº de nascimento do último animal recomendado': getValueOrNull(
          recommendation.male_birth_number
        ),
        'Data da última recomendação': getFormattedDateValueOrNull(
          new Date(recommendation.last_recommendation_date)
        ),
      }))

      const blob = arrayToCsv(recommendations, ';')

      downloadFile({
        data: blob,
        fileName: `recomendacoes-${dateForFileName()}`,
        extension: 'csv',
      })

      addToast({ message: Messages.ANIMAL_EXPORTED_SUCCESS, type: 'success' })
    } catch (e) {
      handleHttpError(e)
    } finally {
      setIsLoadingExport(false)
    }
  }

  const sendAnimalToDisposal = useCallback(
    async (request: CollectiveMovementCreateRequestData): Promise<void> => {
      try {
        await postCreateCollectiveMovement(request)

        addToast({
          message: Messages.EVALUATION_ANIMAL_DISPOSAL_SUCCESS,
          type: 'success',
        })
      } catch (e) {
        handleHttpError(e)
      }
    },
    []
  )

  useEffect(() => {
    readAllMatingRecommendations()
  }, [readAllMatingRecommendations])

  useEffect(() => {
    if (animalId) {
      getAnimalMatingRecommendations()
      getAnimalCalfs()
    }
  }, [animalId, getAnimalMatingRecommendations, getAnimalCalfs])

  return {
    recommendations,
    animalRecommendations,
    animalCalfs,
    getAnimalCalfs,
    createRecommendation,
    editRecommendation,
    exportMatingRecommendations,
    isLoading,
    isLoadingCalfs,
    isLoadingExport,
    sendAnimalToDisposal,
  }
}

export { useMatingRecommendations }
