import { Table } from '@tanstack/react-table'
import { Dispatch, SetStateAction } from 'react'

import {
  ButtonIconPosition,
  Chip,
  Divider,
  Typography,
  TypographyVariant,
} from 'components/atoms'
import { BoxedTable } from 'components/organisms'
import { BoxedLayout } from 'components/templates'

import {
  CollectiveMomentsAnimalsResponseData,
  CollectiveMovementProps,
  CollectiveMovementsCreateProps,
} from 'app/core/types/collective-movements'
import { Messages } from 'config/messages'

import styles from '../create/styles.module.scss'

type CollectiveMovementsReviewTemplateProps = {
  animals: CollectiveMomentsAnimalsResponseData | undefined
  collectiveMovement: CollectiveMovementProps | undefined
  table: Table<CollectiveMovementsCreateProps>
  isLoading: boolean
  page: number
  setPage: Dispatch<SetStateAction<number>>
  handleCreateCollectiveMovement: () => void
}

const CollectiveMovementsReviewTemplate: React.FC<CollectiveMovementsReviewTemplateProps> =
  ({
    animals,
    isLoading,
    table,
    collectiveMovement,
    page,
    setPage,
    handleCreateCollectiveMovement,
  }) => {
    return (
      <BoxedLayout
        backButton
        title="Revisar Movimentação coletiva"
        date={new Date().toISOString()}
        headerLargeButtonAction={handleCreateCollectiveMovement}
        headerLargeButtonLabel="Salvar"
        headerLargeButtonIconPosition={ButtonIconPosition.right}
      >
        <div className={styles.filterFarm}>
          <div>
            <Typography text="Origem" variant={TypographyVariant.h5} />
            <Divider className={styles.filterFarmDivider} />

            <div className={styles.selectedItem}>
              <Typography
                text="Fazenda"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip
                label={collectiveMovement?.originFarm?.label}
                selected={false}
              />
            </div>

            <div className={styles.selectedItem}>
              <Typography
                text="Setor"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip
                label={collectiveMovement?.originSector?.label}
                selected={false}
              />
            </div>
          </div>

          <div>
            <Typography text="Destino" variant={TypographyVariant.h5} />
            <Divider className={styles.filterFarmDivider} />

            <div className={styles.selectedItem}>
              <Typography
                text="Fazenda"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip label={collectiveMovement?.destinationFarm?.label} />
            </div>

            <div className={styles.selectedItem}>
              <Typography
                text="Setor"
                variant={TypographyVariant.caption3}
                altColor
              />
              <Chip label={collectiveMovement?.destinationSector?.label} />
            </div>
          </div>
        </div>

        {animals && (
          <>
            <BoxedTable
              data={animals?.items || []}
              table={table}
              quantityLabel="Entradas"
              title="Animais"
              noDataMessage={Messages.ANIMAL_NOT_ADDED}
              currentPage={page}
              totalPages={animals?.pages}
              totalItems={animals?.total}
              setPage={setPage}
              isLoading={isLoading}
              disableNavigation
            />
          </>
        )}
      </BoxedLayout>
    )
  }

export { CollectiveMovementsReviewTemplate }
