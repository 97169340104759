/* eslint-disable @typescript-eslint/naming-convention */
import { AnimalFilterProps } from 'app/core/types/animal'
import {
  DiagnosticAnimal,
  DiagnosticCreateData,
  DiagnosticCreateRequestData,
  DiagnosticProps,
  DiagnosticReadResponseData,
  DiagnosticResponseData,
} from 'app/core/types/breeding'
import { removeEmptyFilters } from 'app/core/utils'
import { http } from 'interfaces/http'

const baseUrl = '/reproductive_diagnostics/'

const postCreateDiagnostic = async ({
  date,
  responsible,
}: DiagnosticCreateRequestData): Promise<DiagnosticProps> => {
  const response = await http.post(`${baseUrl}group/`, {
    date,
    responsible,
    diagnostics: [],
  })
  return response.data
}

export type GetDiagnosticsFilters = {
  electronic_eartag?: string
  birth_number?: string
  stock_number?: string
  name?: string
  status?: string
  sector_id?: string
  init_date?: string
  final_date?: string
}

const getDiagnostics = async (
  filters: GetDiagnosticsFilters,
  page?: number,
  size?: number
): Promise<DiagnosticReadResponseData> => {
  const response = await http.get(baseUrl, {
    paramsSerializer: { indexes: null },
    params: { ...removeEmptyFilters(filters), page, size },
  })
  return response.data
}

const getDiagnosticsDetails = async (
  id: number,
  filters?: Partial<AnimalFilterProps>
): Promise<DiagnosticProps> => {
  const response = await http.get(`${baseUrl}${id}/`, {
    params: { ...removeEmptyFilters(filters) },
  })

  return response.data
}

const postAddDiagnostic = async (
  groupId: number,
  payload: DiagnosticCreateData[]
): Promise<DiagnosticResponseData> => {
  const response = await http.post(
    `${baseUrl}${groupId}/add_diagnostics/`,
    payload
  )

  return response.data
}

type UpdateDiagnosticInputs = {
  groupId: number
  diagnosticId: number
  status: boolean
  diagnostic_date: string
  gestation_days: number
}

const patchUpdateDiagnostic = async ({
  groupId,
  diagnosticId,
  ...payload
}: UpdateDiagnosticInputs): Promise<DiagnosticAnimal> => {
  const response = await http.patch(
    `${baseUrl}${groupId}/${diagnosticId}/update/`,
    payload
  )
  return response.data
}

const deleteDiagnostic = async (
  groupId: number,
  diagnosticId: number
): Promise<void> => {
  const response = await http.delete(`${baseUrl}${groupId}/${diagnosticId}/`)

  return response.data
}

const deleteGroupDiagnostic = async (groupId: number): Promise<void> => {
  const response = await http.delete(`${baseUrl}${groupId}/`)

  return response.data
}

type DiagnosticTypeResponse = {
  type: string
  gestation_days: number
  birth_forecast: string
  insemination_date: string
}

const getDiagnosticType = async (
  animalId: number,
  diagnosticDate: string
): Promise<DiagnosticTypeResponse> => {
  const response = await http.get(`${baseUrl}type/`, {
    params: {
      animal_id: animalId,
      diagnostic_date: diagnosticDate,
    },
  })
  return response.data
}

export {
  deleteDiagnostic,
  deleteGroupDiagnostic,
  getDiagnosticType,
  getDiagnostics,
  getDiagnosticsDetails,
  patchUpdateDiagnostic,
  postAddDiagnostic,
  postCreateDiagnostic,
}
